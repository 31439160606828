import { Stack, useColorModeValue } from "@chakra-ui/react";
import type { FlexProps } from "@chakra-ui/react";
import type { FunctionComponent } from "react";
import React from "react";
import { TextOverflowTooltip } from "screens/landing/components/TextOverflowTooltip";

interface Props {
  title: string;
  className?: string;
  subSection?: boolean;
  flexDirection?: FlexProps["direction"];
  align?: FlexProps["align"];
  [key: string]: unknown;
}

export const BlockSectionHeader: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  title,
  className,
  children,
  subSection,
  flexDirection = "row",
  align = "space-between",
  ...styles
}) => {
  const headerColor = useColorModeValue("gray.200", "gray.700");
  const subSectionHeaderColor = useColorModeValue("transparent", "gray.800");
  const textColor = useColorModeValue("primary.darkGray", "gray.400");

  return (
    <Stack
      {...styles}
      className={className}
      bg={subSection ? subSectionHeaderColor : headerColor}
      py=".5rem"
      px={subSection ? "0" : ".5rem"}
      width="100%"
      mb={subSection ? "0" : ".5rem"}
      justifyContent={align}
      flexDirection={flexDirection}
      align={align}>
      <TextOverflowTooltip
        color={textColor}
        fontSize={subSection ? "sm" : "md"}
        label={title}
        style={{
          fontWeight: 600,
          minWidth: "10rem",
          maxWidth: "100%",
        }}
      />
      {children}
    </Stack>
  );
};
