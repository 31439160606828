import React, { useMemo, useEffect, createContext, useContext, useRef, useState } from "react";
import { useLocation } from "react-router-dom";

export enum QueryParamFilters {
  status = "status",
  query = "query",
  exchange = "exchange",
  symbol = "symbol",
  type = "type",
}

const TickersFiltersContext = createContext({
  status: "" as string,
  setStatus: (_: string) => {
    return;
  },
  type: "" as string,
  setType: (_: string) => {
    return;
  },
  searchQuery: "" as string,
  searchSymbol: "" as string,
  exchange: "" as string,
  setExchange: (_: string) => {
    return;
  },
});

export const useTickersFiltersContext = () => {
  return useContext(TickersFiltersContext);
};

export const TickersFiltersContextProvider = ({ children }) => {
  const location = useLocation();
  const mounted = useRef(false);
  const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);

  const statusParam = searchParams.get(QueryParamFilters.status);
  const searchQueryParam = searchParams.get(QueryParamFilters.query);
  const exchangeParam = searchParams.get(QueryParamFilters.exchange);
  const typeParam = searchParams.get(QueryParamFilters.type);
  const symbolParam = searchParams.get(QueryParamFilters.symbol);

  const [status, setStatus] = useState<string>(statusParam ?? "");
  const [exchange, setExchange] = useState<string>(exchangeParam ?? "");
  const [type, setType] = useState<string>(typeParam ?? "");

  useEffect(() => {
    mounted.current = true;

    const paramStatus = searchParams.get(QueryParamFilters.status);
    const paramExchange = searchParams.get(QueryParamFilters.exchange);
    const paramType = searchParams.get(QueryParamFilters.type);

    if (mounted.current) {
      setStatus(paramStatus ?? "");
      setExchange(paramExchange ?? "");
      setType(paramType ?? "");
    }

    return () => {
      mounted.current = false;
    };
  }, [searchParams]);

  return (
    <TickersFiltersContext.Provider
      value={{
        type,
        setType,
        searchSymbol: symbolParam ?? "",
        status,
        setStatus,
        searchQuery: searchQueryParam ?? "",
        exchange,
        setExchange,
      }}>
      {children}
    </TickersFiltersContext.Provider>
  );
};
