import { Box, Stack, useColorModeValue } from "@chakra-ui/react";
import React from "react";
import type { WorkflowTaskStatus } from "types/workflows/workflow";
import { getStatusColor } from "screens/common/components/WorkflowSummary";
import { WorkflowStatusIcon } from "screens/common/components/WorkflowStatusIcon";

type MockMilestonesStepperProps = {
  workflowStatuses?: WorkflowTaskStatus[];
  isProjectView?: boolean;
};

const MockMilestone = ({ status, isProjectView }: { status: WorkflowTaskStatus; isProjectView?: boolean }) => {
  const iconColor = useColorModeValue("200", "800");

  return (
    <Box
      p="3px"
      borderRadius="full"
      backgroundColor={`${getStatusColor(status)}.${iconColor}`}
      border="none"
      height={isProjectView ? "2rem" : "unset"}
      className="ch-workflow-step"
      textAlign="left">
      <Stack direction="row" justifyContent="left" alignItems="left">
        <WorkflowStatusIcon status={status} />
      </Stack>
    </Box>
  );
};

export const MockMilestonesStepper: React.FC<MockMilestonesStepperProps> = ({ workflowStatuses, isProjectView = false }) => {
  const defaultStatuses: WorkflowTaskStatus[] = Array(5).fill("not_started");
  const statuses = workflowStatuses || defaultStatuses;

  return (
    <Stack direction="row" spacing={2} align="center">
      {statuses.map((status, index) => (
        <MockMilestone key={index} status={status} isProjectView={isProjectView} />
      ))}
    </Stack>
  );
};
