import type { FunctionComponent } from "react";
import React, { useContext } from "react";
import { Stack, Input, useBreakpointValue, useColorModeValue } from "@chakra-ui/react";
import { useButtonProps } from "hooks";
import { ContentFilterContext } from "../body/ContentFilterContext";
import { ContentCanvasClearFilterButton } from "./ContentCanvasClearFilterButton";

interface Props {
  placeholderText?: string;
}

export const ContentCanvasFilterInput: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  placeholderText = "Filter results...",
}) => {
  const { searchText, setSearchText } = useContext(ContentFilterContext);
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });
  const buttonProps = useButtonProps(isMobile ? "xs" : "sm", "primary");
  const inputColor = useColorModeValue("primary.darkGray", "gray.200");

  return (
    <Stack direction="row" width="100%">
      <Input
        {...buttonProps}
        color={inputColor}
        value={searchText}
        placeholder={placeholderText}
        onChange={(e) => setSearchText(e.target.value)}
      />
      <ContentCanvasClearFilterButton />
    </Stack>
  );
};
