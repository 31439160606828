import { Box, Button, Stack, Text, useColorModeValue } from "@chakra-ui/react";
import type { FunctionComponent } from "react";
import { useContext } from "react";
import { useCallback } from "react";
import { useEffect } from "react";
import React, { useState } from "react";

import MarkdownEditor from "./MarkdownEditor";
import { Note } from "./Note";
import { sendMessage } from "state/websocket/operations";
import { useButtonProps, useConversation, useProjectParams } from "hooks";
import { useDispatch, useSelector } from "react-redux";
import type { RootState } from "state/rootReducer";
import { TypingIndicator } from "screens/thread/components/cells/components";
import { useAddToCharliContext } from "screens/panels/addToCharli/AddToCharliWizard/AddToCharliProvider";
import capitalize from "lodash/capitalize";
import { ConversationContext } from "screens/thread/ConversationContext";

interface Props {
  notes: string[] | undefined;
  isEditEnabled?: boolean;
  isEditing?: boolean;
  conversationId?: string;
  searchPhrase?: string;
}

export const Notes: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  notes = [],
  isEditEnabled = false,
  isEditing = false,
  conversationId,
  searchPhrase,
}) => {
  const { projectId, contentId } = useProjectParams();
  const { conversationId: contextConversationId } = useContext(ConversationContext);
  const noteConversationId = conversationId || contextConversationId;
  const isConnected = useSelector((state: RootState) => state.websocket.isConnected);
  const commonButtonProps = useButtonProps("sm", "primary");
  const secondaryButtonStyle = useButtonProps("sm", "secondary");
  const dispatch = useDispatch();
  const [isCopied, setIsCopied] = useState(false);
  const textColor = useColorModeValue("primary.darkGray", "gray.400");
  const bgColor = useColorModeValue("white", "gray.800");
  const { notesInputState, setNotesInputState, noteText, setNoteText } = useAddToCharliContext();
  const notesState = useConversation(noteConversationId, ["edit_notes"]);
  const isNoteEmptyOrAlreadyExists =
    (noteText && noteText.trim() === "<p></p>") ||
    (noteText && noteText.trim() === "") ||
    (noteText && notes.map((note) => note.toLowerCase().trim() === noteText.toLowerCase().trim()).includes(true)) ||
    false;

  const handleUpdate = () => {
    setNotesInputState("updating");
    sendNoteMessage(noteText);
  };

  const handleDelete = () => {
    setNotesInputState("deleting");
    setNoteText("");
    sendNoteMessage(undefined);
  };

  const handleCancelNote = () => {
    if (notes.length > 0 && noteText) {
      setNotesInputState("viewing");
      setNoteText(noteText);
    } else {
      setNotesInputState("editing");
      setNoteText("");
    }
  };

  useEffect(() => {
    if (
      (notesInputState === "updating" || notesInputState === "deleting") &&
      (notesState.conversationState === "complete" || notesState.conversationState === "cancel" || notesState.conversationState === "error")
    ) {
      setTimeout(() => {
        setNotesInputState("viewing");
        setNoteText("");
      }, 700);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notesState.conversationState]);

  const notesCopied = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1500);
  };

  const getNotes = useCallback(
    (formatted?: boolean) => {
      if (formatted) {
        return notes[0]
          .replace(/<[li>]+>/g, "- ")
          .replace(/<[^>]+>/g, "")
          .replace(/<\/?p[^>]*>/g, "");
      } else if (noteText) {
        return noteText;
      } else {
        return notes.map((note) => note).join("</br>");
      }
    },
    [noteText, notes]
  );

  const sendNoteMessage = (note?: string) => {
    if (!noteConversationId) return;
    const entities: {
      entity: string;
      value: string;
    }[] = [];
    if (note) entities.push({ entity: "note", value: note });
    entities.push({ entity: "metadata_id", value: contentId || projectId || "" });
    dispatch(
      sendMessage({
        conversationId: noteConversationId,
        intent: "/edit_notes",
        entities: entities,
      })
    );
  };

  return (
    <Stack mt="0!important" bgColor={bgColor}>
      {(notesInputState === "viewing" || notesInputState === "updating" || !isEditEnabled) && notes.length > 0 && (
        <Box>
          <Note
            note={getNotes()}
            onClick={() => {
              setNotesInputState("editing");
            }}
            isEditEnabled={isEditEnabled}
            searchPhrase={searchPhrase}
          />
          <>
            {isEditEnabled && (
              <Stack direction="row" justifyContent="space-between" width="100%" pt=".5rem">
                <Button
                  className="ch-delete-note-button"
                  isDisabled={notes && notes.length === 0}
                  {...secondaryButtonStyle}
                  aria-label="Delete Note"
                  onClick={() => {
                    handleDelete();
                  }}>
                  Delete Note
                </Button>
                <Button
                  isDisabled={notes && notes.length === 0}
                  {...secondaryButtonStyle}
                  aria-label="Copy Notes"
                  onClick={() => {
                    navigator.clipboard.writeText(`Notes \r\n` + getNotes(true) + "\r\n");
                    notesCopied();
                  }}>
                  {isCopied ? "Copied to Clipboard" : "Copy Notes"}
                </Button>
              </Stack>
            )}
          </>
        </Box>
      )}
      {isEditEnabled && (notesInputState === "editing" || isEditing) && (
        <Box width="100%" flex={1} id="note-input" position="relative">
          <MarkdownEditor noteText={getNotes()} setNoteText={setNoteText} />
          {isEditEnabled && (
            <Stack direction="row" justifyContent="space-between" pt="1rem">
              <Button
                className="ch-note-cancel-button"
                {...secondaryButtonStyle}
                aria-label="cancel"
                onClick={() => {
                  handleCancelNote();
                }}>
                Cancel
              </Button>
              <Button
                className="ch-note-save-button"
                {...commonButtonProps}
                disabled={!isConnected || isNoteEmptyOrAlreadyExists}
                aria-label="update"
                onClick={() => {
                  handleUpdate();
                }}>
                Save Note
              </Button>
            </Stack>
          )}
        </Box>
      )}
      {(notesInputState === "updating" || notesInputState === "deleting" || notesInputState === "adding") && (
        <Stack direction="row" spacing="1rem">
          <Text fontSize="xs" fontWeight="normal" color={textColor}>
            {`${capitalize(notesInputState)} note`}
          </Text>
          <TypingIndicator size="small" />
        </Stack>
      )}
    </Stack>
  );
};
