import React, { useEffect, useMemo, useState } from "react";
import type { FunctionComponent } from "react";
import { CustomStat } from "screens/landing/tabs/collections/chartComponents/CustomStat";
import { useCollectionCancelWorkflowId, useMainCollectionWorkflowId } from "hooks";
import { Text, Stack, Icon, Tooltip, useColorModeValue } from "@chakra-ui/react";
import { TfiTimer } from "react-icons/tfi";
import { IoCalendarClearOutline } from "react-icons/io5";
import { useWorkflowKey, useWorkflowStatus } from "hooks/useWorkflows";
import { calculateTimeDifference } from "./utils";

interface Props {
  collectionId: string;
  size?: "xxs" | "xs" | "sm" | "md";
  hideIcon?: boolean;
  showTimeDetails?: boolean;
  onClick?: () => void;
  hasOneTimeSchedule?: boolean;
  isPlaceholderTile?: boolean;
}

export const CollectionStatProgress: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  collectionId,
  size = "md",
  hideIcon,
  showTimeDetails,
  onClick,
  hasOneTimeSchedule,
  isPlaceholderTile,
}) => {
  const workflowId = useMainCollectionWorkflowId(collectionId);
  const canceledWorkflowId = useCollectionCancelWorkflowId(collectionId);
  const creationDate = useWorkflowKey(workflowId, "creationDate");
  const completionDate = useWorkflowKey(workflowId, "completionDate");
  const startDate = useWorkflowKey(workflowId, "startDate");
  const workflowStatus = useWorkflowStatus(workflowId);
  const fontColor = useColorModeValue("charli.mediumGray", "gray.500");

  const currentWorkflowCreatedDate = useWorkflowKey(workflowId, "creationDate");
  const canceledWorkflowDate = useWorkflowKey(canceledWorkflowId, "completionDate");

  const [workflowDuration, setWorkflowDuration] = useState<string>("00:00");

  const workflowStatusLabel = useMemo(() => {
    if (isPlaceholderTile) {
      return "NOT STARTED";
    } else if (hasOneTimeSchedule) {
      return "SCHEDULED";
    } else if (!workflowStatus) {
      return "-";
    } else if (workflowStatus === "failed_checkstop") {
      return "Paused".toUpperCase();
    } else {
      return workflowStatus.replace(/_/g, " ").toUpperCase();
    }
  }, [hasOneTimeSchedule, isPlaceholderTile, workflowStatus]);

  const startOrCreationDate = useMemo(() => startDate || creationDate, [startDate, creationDate]);

  useEffect(() => {
    if (!startOrCreationDate) return;

    if (workflowStatus !== "in_progress") {
      const endDate = completionDate ? new Date(completionDate) : canceledWorkflowDate ? new Date(canceledWorkflowDate) : new Date();
      setWorkflowDuration(calculateTimeDifference(new Date(startOrCreationDate), endDate));
      return;
    }

    const initDate = (() => {
      if (new Date(startOrCreationDate).getTime() > new Date().getTime()) {
        return new Date();
      } else {
        return new Date(startOrCreationDate);
      }
    })();

    const interval = setInterval(() => {
      setWorkflowDuration(calculateTimeDifference(initDate, new Date()));
    }, 1000);

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [completionDate, canceledWorkflowDate, startOrCreationDate, workflowStatus]);

  return (
    <Stack color={fontColor} spacing="3px" className="ch-project-workflow-status-indicator">
      <CustomStat
        {...(onClick && { onClick })}
        label={workflowStatusLabel}
        value={hasOneTimeSchedule ? "scheduled" : workflowStatus}
        size={size}
        hideIcon={hideIcon}
      />
      {showTimeDetails && (
        <Stack fontWeight={"light"} fontSize="11px" width="100%" align="center" spacing="0px">
          <Stack direction="row" spacing="3px" align="center">
            <Icon as={TfiTimer} /> <Text>{workflowDuration}</Text>
          </Stack>
          <Tooltip
            label={`Project completed${currentWorkflowCreatedDate ? ` at ${new Date(currentWorkflowCreatedDate).toLocaleString()}` : "."}`}
            aria-label="Full date and time"
            maxWidth={"11rem"}
            placement="top">
            <Stack direction="row" spacing="3px" align="center">
              <Icon as={IoCalendarClearOutline} />
              <Text>
                {isPlaceholderTile || !currentWorkflowCreatedDate
                  ? new Date().toLocaleDateString()
                  : new Date(currentWorkflowCreatedDate).toLocaleDateString()}
              </Text>
            </Stack>
          </Tooltip>
        </Stack>
      )}
    </Stack>
  );
};
