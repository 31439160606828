import { Box, Flex, Image, useColorModeValue, useToast } from "@chakra-ui/react";
import type { FunctionComponent } from "react";
import { useCallback, useEffect, useMemo } from "react";
import { useState } from "react";
import React from "react";
import { Landing } from "screens/landing";
import { SettingsProvider } from "screens/panels/settings/SettingsProvider";
import { ChatIntegrationCodeListener } from "../listeners/ChatIntegrationCodeListener";
import { FloatingRouterOutlet } from "../components/FloatingRouterOutlet";
import { NotificationListener } from "../listeners/NotificationListener";
import { ShareInviteCodeListener } from "../listeners/ShareInviteCodeListener";
import { WebsocketNotifier } from "./WebsocketNotifier";
import { SettingsLinkListener } from "../listeners/SettingsLinkListener";
import { ConversationDialog } from "screens/thread/ConversationDialog";
import { AddToCharliProvider } from "screens/panels/addToCharli/AddToCharliWizard/AddToCharliProvider";
import { WorkflowContextProvider } from "screens/thread/WorkflowContextProvider";
import { WorkflowPayloadPanel } from "screens/thread/components/WorkflowPayloadPanel";
import { ConversationContextProvider } from "screens/thread/ConversationContext";
import { ConversationDialogButton } from "screens/thread/ConversationDialogButton";
import { SearchParamListener } from "../listeners/SearchParamListener";
import useKonami from "use-konami";
import clippy from "screens/common/static/misc/clippy1.gif";
import { useParams } from "react-router-dom";
import { WorkflowPanelUser } from "screens/thread/components/WorkflowPanelUser";
import { HighlightsPanel } from "screens/panels/highlights/HighlightsPanel";
import { SummarizePanel } from "screens/panels/summarize/SummarizePanel";
import { StepsPanel } from "screens/common/components/WorkflowSummary/v2/StepsPanel";
import {
  useCollectionKey,
  useCollections,
  useConfigMap,
  useFeatureFlags,
  useGetViewConfig,
  useProjectParams,
  useRefreshCollections,
  useRefreshPortfolioProjects,
  useUserPreference,
} from "hooks";
import { ProjectReportPanel } from "screens/panels/researchReport/ProjectReportPanel";
import { updateTypedUserPreference } from "state/userPreference/operations";
import { useDispatch } from "react-redux";
import { CollectionsStocksProvider } from "screens/landing/tabs/collections/CollectionsStocksProvider";
import { NewPortfolioModal } from "screens/landing/components/popoverComponent/NewPortfolioModal";
import { SubscriptionModal } from "screens/landing/components/popoverComponent/SubscriptionModal";
import { CollectionsFilterContextProvider } from "screens/landing/tabs/collections/CollectionsFilterContext";
import type { CollectionWithAdditionalProps } from "types/collection";
import { parseISO } from "date-fns";

interface Props {
  screen?: string;
}

const sortFunction = (a: CollectionWithAdditionalProps, b: CollectionWithAdditionalProps) =>
  parseISO(b.metadata.createdTime).getTime() - parseISO(a.metadata.createdTime).getTime();

export const AppContainer: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = () => {
  const { workflowId } = useParams();
  const { workflow_summary_2: hasWorkflowSummaryV2 } = useFeatureFlags();
  const dispatch = useDispatch();
  const toast = useToast();
  const primaryTextColor = useColorModeValue("gray.700", "gray.300");
  const bgColor = useColorModeValue("#cbe0d0", "gray.800");
  const bgColorDisconnect = useColorModeValue("#f9e4dc", "gray.800");
  const borderColor = useColorModeValue("gray.300", "gray.900");
  const collections = useCollections();
  const refreshCollections = useRefreshCollections();
  const refreshPortfolioProjects = useRefreshPortfolioProjects();
  const { projectFilter, isPortfolios } = useProjectParams();
  const portfolioMetadataIds = useCollectionKey(projectFilter, "metadataIds");
  const configMap = useConfigMap();
  const collectionType = useGetViewConfig("collectionType", projectFilter, configMap);

  useEffect(() => {
    refreshCollections();
    refreshPortfolioProjects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const collectionsToRender = useMemo(() => {
    // sort all collections by created time
    const sortedCollections = collections.sort(sortFunction);
    // if we are in a portfolio view, filter collections with metadataIds from portfolio
    if (isPortfolios && portfolioMetadataIds) {
      return sortedCollections.filter((item) => item.id && portfolioMetadataIds.includes(item.id));
    }
    // if we are in a regular view, filter collections by collectionType
    return sortedCollections.filter((item) => item.collectionType === collectionType);
  }, [collections, isPortfolios, portfolioMetadataIds, collectionType]);

  const [showClippy, setShowClippy] = useState(false);
  useKonami({
    onUnlock: () => setShowClippy(true),
    sequence: ["ArrowUp", "c", "ArrowDown"],
  });

  const injectDebugEntityPreference = useUserPreference("ui_inject_debug_entity");
  const injectTestEntityPreference = useUserPreference("ui_inject_test_entity");

  const onToggleUserPreference = useCallback(
    async (preferenceKey: any) => {
      const isDebugPreference = preferenceKey === "ui_inject_debug_entity";
      const isToggleOff = isDebugPreference ? injectDebugEntityPreference : (injectTestEntityPreference as boolean);

      toast({
        render: ({ onClose }) => (
          <Box
            mb=".5rem"
            boxShadow={"lg"}
            cursor={"pointer"}
            borderColor={borderColor}
            onClick={onClose}
            fontSize="xs"
            py="5px"
            px="10px"
            color={primaryTextColor}
            backgroundColor={isToggleOff ? bgColorDisconnect : bgColor}
            borderRadius="full">
            {`${isDebugPreference ? "Debug" : "Test"} mode is now ${isToggleOff ? "off" : "on"}`}
          </Box>
        ),
        duration: 4000,
        isClosable: true,
        position: "bottom",
        containerStyle: {
          minWidth: "unset",
        },
      });

      dispatch(updateTypedUserPreference({ preferenceKey: preferenceKey, value: !isToggleOff }));
    },
    [bgColor, bgColorDisconnect, borderColor, dispatch, injectDebugEntityPreference, injectTestEntityPreference, primaryTextColor, toast]
  );

  useKonami({
    onUnlock: (event: KeyboardEvent) => {
      onToggleUserPreference("ui_inject_debug_entity");
    },
    sequence: ["ArrowUp", "d", "ArrowDown"],
  });

  useKonami({
    onUnlock: (event: KeyboardEvent) => {
      onToggleUserPreference("ui_inject_test_entity");
    },
    sequence: ["ArrowUp", "t", "ArrowDown"],
  });

  return (
    <Flex id="app-container" direction="column" marginX="auto" height={"calc(var(--vh, 1vh) * 100)"} alignContent="stretch">
      <Box position={"absolute"} bottom="5rem" right="0" zIndex={"100000"}>
        <Image src={clippy} display={showClippy ? "block" : "none"} width="70%" onClick={() => setShowClippy(false)} />
      </Box>
      <SettingsProvider>
        <ConversationContextProvider>
          <WorkflowContextProvider>
            <AddToCharliProvider>
              <CollectionsStocksProvider>
                <CollectionsFilterContextProvider collections={collectionsToRender}>
                  <Box display="flex" flexGrow={1} flexDirection="row" overflow="hidden">
                    <Landing />
                  </Box>
                  <SearchParamListener />
                  <WebsocketNotifier />
                  <ShareInviteCodeListener />
                  <ChatIntegrationCodeListener />
                  <NotificationListener />
                  <SettingsLinkListener />
                  <ConversationDialogButton />
                  <FloatingRouterOutlet>
                    <ConversationDialog />
                  </FloatingRouterOutlet>
                  <ProjectReportPanel />
                  <SummarizePanel />
                  <HighlightsPanel />
                  <SubscriptionModal />
                  <NewPortfolioModal />
                  {!workflowId && (
                    <>
                      <WorkflowPanelUser />
                      {hasWorkflowSummaryV2 ? <StepsPanel /> : <WorkflowPayloadPanel />}
                    </>
                  )}
                </CollectionsFilterContextProvider>
              </CollectionsStocksProvider>
            </AddToCharliProvider>
          </WorkflowContextProvider>
        </ConversationContextProvider>
      </SettingsProvider>
    </Flex>
  );
};
