import React, { useContext, useEffect, useState } from "react";
import { Box, Center, Stack, useBreakpointValue, useColorModeValue, useDisclosure, Text } from "@chakra-ui/react";
import { useContents, useConversation, useHasConversation, useInitialSyncCompleted, useSidebarNavigation, useUserPreference } from "hooks";
import { LoadingGate } from "screens/common/components";
import type { GroupByOption, GroupBySortDirection, SortDirection, SortOption } from "types/SortingAndGroupingPreferences";
import { ContentCanvasFilterOptions, ContentFilterContextProvider, ContentRouter } from "..";
import { ContentSortingGroupingContext } from "./body/ContentSortingGroupingContext";
import { TabTitle } from "screens/common/components/TabTitle";
import { ConversationContext } from "screens/thread/ConversationContext";
import { SettingsProviderContext } from "screens/panels/settings/SettingsProvider";
import { ContentCanvasFilterMenu } from "./header/ContentCanvasFilterMenu";
import { useAddToCharliContext } from "screens/panels/addToCharli/AddToCharliWizard/AddToCharliProvider";
import { ContentViewPanel } from "../contentView/ContentViewPanel";
import { SharePanel } from "screens/panels/share/SharePanel";
import { NotesPanel } from "../common/notes/NotesPanel";
import { AddToCollectionPanel } from "screens/panels/addToCharli/AddToCharliWizard/AddToCollectionPanel";
import { VerifiedAIPanel } from "../contentView/VerifiedAIPanel";
import { useParams } from "react-router-dom";
import { DEFAULT_SIDEBAR_WIDTH, LandingTitle } from "screens/landing/components";
import { useDispatch, useSelector } from "react-redux";
import { downloadConversationById } from "state/conversation/operations";
import type { RootState } from "state/rootReducer";

export const ContentCanvas = () => {
  const { conversationId, contentId } = useParams() as { conversationId: string; contentId: string | undefined };
  const { setConversationId } = useContext(ConversationContext);
  const conversation = useConversation(conversationId);
  const { isOpen: isFilterOpen, onToggle: onFilterToggle, onOpen: onFilterOpen, onClose: onFilterClose } = useDisclosure();
  const [sortName] = useState<SortOption>("Date");
  const [sortDirection] = useState<SortDirection>("DESC");
  const [groupByName] = useState<GroupByOption>("");
  const [groupByDirection] = useState<GroupBySortDirection>("ASC");
  const { setHeaderText } = useContext(SettingsProviderContext);
  const { onContentViewOpen, onContentViewClose, isAddToCollectionModalOpen, onAddToCollectionModalClose, isContentViewOpen } =
    useAddToCharliContext();
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });
  const textColor = useColorModeValue("gray.700", "gray.100");
  const hasInitialSyncCompleted = useInitialSyncCompleted();
  const canvasItems = useContents(conversation.metadataIds ?? [], { refreshFromNetwork: true });
  const initialRequest = conversation?.initialText?.replace("Find :", "Projects and resources that contain") || "Search Results";
  const { currentSidebarType } = useSidebarNavigation();
  const sidebarWidth = (useUserPreference("ui_sidebar_width") as number) || (DEFAULT_SIDEBAR_WIDTH as number);
  const dispatch = useDispatch();
  const hasConversation = useHasConversation(conversationId);
  const isContentLoading = useSelector((state: RootState) => state.content.isLoading);

  useEffect(() => {
    if (conversationId && !hasConversation) {
      dispatch(downloadConversationById({ conversationId: conversationId }));
    }
  }, [conversationId, hasConversation, dispatch]);

  useEffect(() => {
    contentId ? setConversationId(contentId) : setConversationId(conversationId);
    setHeaderText("Search Results");
  }, [contentId, initialRequest, conversationId, setConversationId, setHeaderText]);

  useEffect(() => {
    if (contentId) {
      onContentViewOpen();
    } else {
      onContentViewClose();
    }
  }, [contentId, onContentViewClose, onContentViewOpen]);

  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    if (conversation.metadataIds === undefined || isContentLoading) {
      setShowLoader(true);
    } else {
      if (conversation.metadataIds.length > 0 && (canvasItems ?? []).length === 0) {
        setShowLoader(true);
      } else {
        setShowLoader(false);
      }
    }
  }, [canvasItems, conversation.metadataIds, isContentLoading]);

  return (
    <ContentFilterContextProvider contentItems={canvasItems}>
      <TabTitle title={`Charli > ${initialRequest}`} />
      <ContentSortingGroupingContext.Provider value={{ sortName, sortDirection, groupByName, groupByDirection }}>
        <Box width="100%" height="100%" className="canvas-view search-view" px={isMobile ? ".5rem" : "1rem"}>
          <Box mt="1rem">
            <LandingTitle
              maxWidth={`calc(100vw - ${currentSidebarType === "hidden" ? 0 : sidebarWidth}px)`}
              underline
              text={initialRequest}
              titleMaxWidth="30rem"
              color="primary.default">
              {!isMobile && (
                <Stack direction="row" spacing="1rem" pb="1rem" width="100%" justifyContent="flex-end">
                  <ContentCanvasFilterMenu
                    isShowingResources
                    onFilterToggle={onFilterToggle}
                    onOpen={onFilterOpen}
                    isOpen={isFilterOpen}
                    onClose={onFilterClose}
                    placeholderText="Filter search results..."
                  />
                </Stack>
              )}
            </LandingTitle>
            <Box className="ch-project-results-panel">
              <LoadingGate isLoading={!hasInitialSyncCompleted || showLoader} height="80vh">
                <Box width="100%" height="100%" className="canvas-modal">
                  {canvasItems ? (
                    <Stack height="100%">
                      {isFilterOpen && <ContentCanvasFilterOptions />}
                      <ContentRouter filterOutProjectOutput={false} />
                    </Stack>
                  ) : (
                    <Center height="100%">
                      <Stack align="center" spacing="1rem" justifyContent="center" mt="3rem">
                        <Text color={textColor}>
                          {isContentLoading
                            ? "Loading content..."
                            : conversation.metadataIds?.length
                            ? "Loading resources..."
                            : "I couldn't find any projects or resources related to your search."}
                        </Text>
                      </Stack>
                    </Center>
                  )}
                </Box>
              </LoadingGate>
            </Box>
          </Box>
          <ContentViewPanel />
          {isContentViewOpen && (
            <>
              <SharePanel />
              <NotesPanel />
              <AddToCollectionPanel isOpen={isAddToCollectionModalOpen} onClose={onAddToCollectionModalClose} />
              <VerifiedAIPanel />
            </>
          )}
        </Box>
      </ContentSortingGroupingContext.Provider>
    </ContentFilterContextProvider>
  );
};
