import {
  Center,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  Stack,
  Td,
  Text,
  Tr,
  useBreakpointValue,
  useColorModeValue,
  Box,
} from "@chakra-ui/react";
import React from "react";
import { useContext } from "react";
import type { ContentDetails } from "types/content/ContentDetails";
import { getDateFromContent, getExtension, getParentCellType } from "screens/content/common/utils";
import { formatDate } from "screens/common/modal/formatters";
import { ContentImageWrapper } from "screens/content/common/ContentImage/ContentImageWrapper";
import { useConversation, useExternalIntegrations } from "hooks";
import { ConversationContext } from "screens/thread/ConversationContext";
import ECHighlighter from "react-ec-highlighter";
import { ContentFilterContext } from "screens/content";
import { FileTypeBadge } from "screens/common/components";
import { IoEllipsisVertical } from "react-icons/io5";
import { ContentCellFooterMenu } from "../cell/ContentCellFooterMenu";
import { HiOutlineExternalLink } from "react-icons/hi";
import { SmallActionButton } from "../cell/SmallActionButton";
import { TextOverflowTooltip } from "screens/landing/components/TextOverflowTooltip";
import { useLocation } from "react-router-dom";
import { TagInput } from "screens/common/components/Tags/TagInput";

interface Props {
  contentData: ContentDetails;
  onClick?: () => void;
}

export const ContentCellTableRow = React.forwardRef<HTMLInputElement, Props>(({ contentData, onClick }, ref) => {
  const secondaryTextColor = useColorModeValue("primary.darkGray", "gray.400");
  const bgColor = useColorModeValue("gray.200", "gray.700");
  const { conversationId } = useContext(ConversationContext);
  const { conversationState } = useConversation(conversationId);
  const { searchText } = useContext(ContentFilterContext);
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });
  const { getIntegration } = useExternalIntegrations(undefined, undefined, undefined, contentData?.sourceIntegrationUrn);
  const { pathname } = useLocation();
  const isSearchRoute = pathname.includes("/search/") || pathname.includes("/research/");

  let decodedName: string;
  try {
    decodedName = decodeURIComponent(contentData.name || "");
  } catch (error) {
    decodedName = "";
  }

  const hasTags = contentData.manualTags.length > 0;

  return (
    <Tr
      className={`canvas-item table-view-row extension-${contentData.extension ? contentData.extension : "unknown"} ${
        hasTags ? "has-tags" : ""
      }`}
      cursor="pointer"
      onClick={(event) => {
        if (onClick) onClick();
        event.stopPropagation();
      }}>
      {!isMobile && (
        <Td p=".5rem">
          <ContentImageWrapper
            metadataId={contentData.id}
            thumbnailWidth="50px"
            thumbnailHeight="50px"
            fit="cover"
            thumbnailFit="cover"
            thumbnailPosition="top"
            thumbnailBorderRadius="5px"
            bgColor={bgColor}
          />
        </Td>
      )}
      <Td p=".5rem">
        {searchText && searchText.length > 0 && decodedName && contentData.name ? (
          <ECHighlighter searchPhrase={searchText} text={decodedName} />
        ) : (
          <TextOverflowTooltip className="ch-item-name" label={contentData.name ? decodedName : ""} />
        )}
      </Td>
      {!isMobile && !isSearchRoute && (
        <Td p=".5rem">
          <TextOverflowTooltip label={getIntegration?.name || ""} />
        </Td>
      )}
      {!isMobile && (
        <Td p=".5rem">
          <Center>
            <FileTypeBadge
              width="1.3rem"
              height="1.6rem"
              hideExtensionLabel
              fileTypeName={contentData.collectionType}
              extension={getExtension(contentData)}
            />
          </Center>
        </Td>
      )}
      {!isMobile && (
        <Td p=".5rem">
          <Text
            className="ch-source-content-item-category"
            fontWeight="light"
            fontSize={isMobile ? "xs" : "sm"}
            textColor={secondaryTextColor}>
            {contentData.category
              ? contentData.category
              : contentData.type
              ? contentData.type === "collection"
                ? "Project"
                : contentData.type
              : ""}
          </Text>
        </Td>
      )}
      {!isMobile && isSearchRoute && (
        <Td p=".5rem">
          <TagInput
            size="xs"
            tags={contentData.autoTags || []}
            tagType="auto"
            hideInput
            allowUpdate={false}
            allowDelete={false}
            allowFilter
            maxTagsToShow={3}
            searchPhrase={searchText}
          />
        </Td>
      )}
      {!isMobile && (
        <Td p=".5rem">
          <Text fontWeight="light" fontSize={isMobile ? "xs" : "sm"} textColor={secondaryTextColor}>
            {formatDate(getDateFromContent(contentData), `${isMobile ? "d-MM-yy" : "d MMM yyyy"}`)}
          </Text>
        </Td>
      )}
      <Td p=".5rem">
        <Stack direction="row" width="100%" justifyContent={"flex-end"}>
          {contentData.urls?.url && (
            <Center>
              <SmallActionButton
                iconTypeName={HiOutlineExternalLink}
                onClick={() => {
                  contentData.urls?.url && window.open(contentData.urls.url, "_blank");
                }}
                tooltip={"Open content in new tab"}
              />
            </Center>
          )}
          <Box>
            <Menu>
              <MenuButton
                _hover={{}}
                _active={{}}
                as={IconButton}
                aria-label="Options"
                icon={<Icon as={IoEllipsisVertical} _active={{}} _hover={{}} />}
                variant="ghost"
                onClick={(event) => event.stopPropagation()}
              />
              <MenuList minWidth={"unset"}>
                <ContentCellFooterMenu
                  fileName={contentData.name}
                  itemId={contentData.mediaId}
                  downloadLink={contentData.urls?.downloadUrl}
                  parentCellType={getParentCellType(contentData)}
                  allowDelete={conversationState !== "in_progress"}
                  integrationUrn={contentData.integrationUrn}
                />
              </MenuList>
            </Menu>
          </Box>
        </Stack>
      </Td>
    </Tr>
  );
});
