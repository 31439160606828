import { Record, String, Number, Array } from "runtypes";
import { Entity } from "./Entity";
import { Frequency } from "./Frequency";
import type { Static } from "runtypes";
import { DesiredTime } from "./DesiredTime";

export const WorkflowSchedule = Record({
  id: String,
  userId: String,
  frequency: Frequency,
  day: Number.nullable(),
  desiredTime: DesiredTime.nullable(),
  desiredExecutionDate: String.nullable(),
  intent: String,
  entities: Array(Entity),
  creationDate: String,
  creationByUserId: String,
  lastUpdated: String,
  lastUpdatedByUserId: String,
});

export type WorkflowSchedule = Static<typeof WorkflowSchedule>;
