import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import uniq from "lodash/uniq";
import assign from "lodash/assign";
import type { ContentDetails } from "types/content/ContentDetails";
import { downloadCollectionMetadata } from "./operations";

export type CollectionMetadataChecks = {
  companyLogoId: string;
  landingThumbnailId?: string;
  stockEquityDataId: string;
  companyDetailsId: string;
};

interface CollectionMetadataState {
  contentsIdsByCollection: Record<string, string[]>;
  contentsByIds: Record<string, ContentDetails>;
  contentsChecksByCollection: Record<string, CollectionMetadataChecks>;
  dismissedContentsIds: Record<string, boolean>;
}

const initialState: CollectionMetadataState = {
  contentsIdsByCollection: {},
  contentsByIds: {},
  contentsChecksByCollection: {},
  dismissedContentsIds: {},
};

export const { actions, reducer } = createSlice({
  name: "collectionMetadata",
  initialState,
  reducers: {
    flush: () => {
      return initialState;
    },
    updateCollectionMetadata: (state, action: PayloadAction<ContentDetails>) => {
      state.contentsByIds[action.payload.id] = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(downloadCollectionMetadata.fulfilled, (state, action) => {
      const collectionId = action.meta.arg.collectionId;
      assign(state.contentsByIds, action.payload.contents);
      const newContentIds = Object.keys(action.payload.contents);

      if (!(collectionId in state.contentsChecksByCollection)) {
        state.contentsChecksByCollection[collectionId] = {
          companyLogoId: "",
          stockEquityDataId: "",
          companyDetailsId: "",
        };
      }

      Object.entries(action.payload.checks).forEach(([key, id]) => {
        if (id) {
          state.contentsChecksByCollection[collectionId][key] = id;
        }
      });

      if (action.meta.arg.collectionId in state.contentsIdsByCollection) {
        const currentContentIds = state.contentsIdsByCollection[action.meta.arg.collectionId];
        state.contentsIdsByCollection[action.meta.arg.collectionId] = uniq([...currentContentIds, ...newContentIds]);
      } else {
        state.contentsIdsByCollection[action.meta.arg.collectionId] = newContentIds;
      }

      if (state.dismissedContentsIds && action.payload.dismissedIds.length > 0) {
        Object.assign(
          state.dismissedContentsIds,
          action.payload.dismissedIds.reduce((acc, id) => ({ ...acc, [id]: true }), {})
        );
      }
    });
  },
});

export default reducer;
