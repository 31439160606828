import React, { useMemo, useRef } from "react";
import { Stack, TabList, Tab, TabPanel, TabPanels, Tabs, Box, useColorModeValue, Tooltip, useBreakpointValue } from "@chakra-ui/react";
import { MarkdownViewer } from "screens/markdown/MarkdownViewer";
import { useCustomScrollbar } from "hooks/useCustomScrollbar";
import { ContentPreview } from "screens/content/contentView/previewSection/ContentPreview";
import type { ContentDetails } from "types/content/ContentDetails";
import { useCollectionKey, useContents, useFilterAndGroupReports, useManualTags } from "hooks";

interface ProjectAnalysisPopoverContentProps {
  projectId?: string;
}

export const ProjectAnalysisPopoverContent: React.FC<ProjectAnalysisPopoverContentProps> = ({ projectId }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });
  const { scrollbarStyle } = useCustomScrollbar(containerRef, { width: "2px", barTransparency: 0.2 });
  const collectionMetadataIds = useCollectionKey(projectId, "metadataIds");
  const investmentScoreAnalytical = useCollectionKey(projectId, "investmentScoreAnalytical");
  const investmentScoreSentiment = useCollectionKey(projectId, "investmentScoreSentiment");
  const explanations = useMemo(
    () => ({
      analytical: investmentScoreAnalytical?.explanation,
      sentiment: investmentScoreSentiment?.explanation,
    }),
    [investmentScoreAnalytical, investmentScoreSentiment]
  );
  const tagsFilter = useManualTags();
  const contentData = useContents(collectionMetadataIds ?? [], { refreshFromNetwork: true, filterByTags: tagsFilter });
  const contentDataFiltered = useFilterAndGroupReports(contentData);
  const firstContentDetailFromFilter = useMemo(() => {
    const firstGroup = Object.values(contentDataFiltered)[0];
    return firstGroup?.[0] as ContentDetails | undefined;
  }, [contentDataFiltered]);

  const popoverBgColor = useColorModeValue("white", "gray.700");
  const tabPanelBgColor = useColorModeValue("gray.700", "gray.900");
  const tabPanelTextFade = useColorModeValue("45,55,72", "23,25,35");

  const hasBothExplanations = Boolean(explanations.analytical?.length && explanations.sentiment?.length);

  return (
    <Stack direction={"row"} pt=".5rem" spacing="0">
      {(explanations.analytical?.length || explanations.sentiment?.length) && (
        <Box width={isMobile ? "100%" : "27rem"} minHeight={"22rem"}>
          <Tabs size="sm">
            <TabList color={"gray.300"} className="ch-quadrant-tab">
              {Object.keys(explanations ?? {}).map(
                (key, idx) =>
                  explanations?.[key] && (
                    <Tab _selected={{ color: "primary.default", fontWeight: "semibold", fontsize: "md" }} key={idx}>
                      {key.toUpperCase()}
                    </Tab>
                  )
              )}
            </TabList>
            <TabPanels height={"20rem"} overflow={"auto"} css={scrollbarStyle}>
              {Object.entries(explanations).map(
                ([key, explanation], idx) =>
                  explanation && (
                    <TabPanel key={idx} className="ch-quadrant-tab-panel">
                      <MarkdownViewer backgroundColor={tabPanelBgColor} content={explanation} fontSize="sm" />
                      <Box
                        zIndex={3}
                        position="absolute"
                        bottom="0"
                        left="0"
                        right="0"
                        height="30px"
                        background={`linear-gradient(to bottom, rgba(${tabPanelTextFade},34%), rgba(${tabPanelTextFade},1))`}
                        pointerEvents="none"
                      />
                    </TabPanel>
                  )
              )}
            </TabPanels>
          </Tabs>
        </Box>
      )}
      {firstContentDetailFromFilter && (
        <Box>
          <Tooltip placement="top" offset={[0, -100]} label="Download Report" aria-label="Download Report">
            <Box width={"16rem"} pt={hasBothExplanations ? "2rem" : "1rem"} px="1rem" pb={hasBothExplanations ? "0" : "1rem"}>
              <ContentPreview
                backgroundColor={popoverBgColor}
                itemContent={firstContentDetailFromFilter}
                thumbnailHeight={"300"}
                thumbnailWidth={"230"}
              />
            </Box>
          </Tooltip>
        </Box>
      )}
    </Stack>
  );
};
