import type { Middleware } from "redux";
import { getBillingPlan } from "state/billing/operations";
import { downloadCommands } from "state/command/operations";
import { downloadProjectConfigurations } from "state/config/operations";
import { downloadTags } from "state/conversation/operations";
import { downloadAllCopies } from "state/copies/operations";
import { downloadEntitlements } from "state/entitlements/operations";
import { downloadFinancialMarketData } from "state/financialMarket/operations";
import { downloadFlags } from "state/flags/operations";
import { downloadIntegrations } from "state/integrations/operations";
import { getMemberOrganizations } from "state/memberOrganizations/operations";
import { downloadNewsInformation } from "state/newsInformation/operations";
import { downloadNotifications } from "state/notification/operations";
import type { RootState } from "state/rootReducer";
import { fetchSuggestedQuestionData } from "state/suggestedQuestions/operations";
import { downloadCurrentUserFeatureUsage } from "state/usage/operations";
import { fetchUserMarketingPreference, fetchUserPreferencesByKeys } from "state/userPreference/operations";
import { downloadWorkflowsAfterLogin } from "state/workflow/operations";
import { requestToDownloadWorkflowsThatMayHaveChanged } from "state/workflow/utils";
import { downloadWorkflowsSchedules } from "state/workflowSchedules/operations";

export const projectConfigMiddleware: Middleware = (store) => (next) => (action) => {
  next(action);

  const rootState = store.getState() as RootState;

  if (action.type === downloadProjectConfigurations.fulfilled.type) {
    store.dispatch(downloadEntitlements() as never);
    store.dispatch(downloadAllCopies() as never);

    store.dispatch(downloadTags() as never);
    store.dispatch(downloadCommands() as never);
    store.dispatch(downloadFlags() as never);
    store.dispatch(downloadIntegrations() as never);
    store.dispatch(downloadCurrentUserFeatureUsage() as never);
    store.dispatch(downloadWorkflowsSchedules() as never);

    store.dispatch(fetchUserPreferencesByKeys([]) as never);
    store.dispatch(fetchSuggestedQuestionData() as never);
    store.dispatch(downloadNewsInformation() as never);
    store.dispatch(downloadFinancialMarketData() as never);

    store.dispatch(fetchUserMarketingPreference() as never);
    store.dispatch(downloadNotifications() as never);
    store.dispatch(getBillingPlan() as never);
    requestToDownloadWorkflowsThatMayHaveChanged(rootState.workflow.workflowsById, rootState.workflow.isLoadingWorkflowMap, store.dispatch);
    store.dispatch(getMemberOrganizations() as never);
    store.dispatch(downloadWorkflowsAfterLogin({ status: ["complete"] }) as never);
    store.dispatch(downloadWorkflowsAfterLogin({ status: ["in_progress", "queued", "paused"] }) as never);
    store.dispatch(downloadWorkflowsAfterLogin({ status: ["clarification_needed"] }) as never);
    store.dispatch(downloadWorkflowsAfterLogin({ status: ["failed"] }) as never);
  }
};
