import { Box, Grid, GridItem, Stack, Text, useBreakpointValue, useColorModeValue } from "@chakra-ui/react";
import { useProjectParams, useTileProps } from "hooks";
import React, { memo, useMemo, useContext, useCallback } from "react";
import { ComposedChart, Bar, Line, XAxis, YAxis, CartesianGrid, ResponsiveContainer, Tooltip } from "recharts";
import { ProjectHeaderImage } from "screens/landing/tabs/project/projectLandingTileLayouts/components/ProjectHeaderImage";
import { CollectionsFilterContext } from "../../CollectionsFilterContext";
import { useNavigate } from "react-router-dom";

export interface StockMarketChartData {
  projectId?: string;
  ticker: string;
  currentPrice: number;
  rangeValueA?: number;
  rangeValueB?: number;
}

interface StockMarketChartProps {
  data: StockMarketChartData[];
}

interface TooltipProps {
  active?: boolean;
  payload?: Array<{
    value: number;
    payload: {
      projectId?: string;
      name: string;
      currentPrice: number;
      fairValue: [number | undefined, number | undefined];
    };
  }>;
}

// Memoized tooltip component
const CustomTooltip = memo(({ active, payload }: TooltipProps) => {
  const commonTileProps = useTileProps();
  const borderColor = useColorModeValue("gray.400", "gray.300");
  const tooltipBgColor = useColorModeValue("gray.700", "gray.900");
  const labelColor = useColorModeValue("gray.300", "gray.400");

  if (!active || !payload?.length) return null;

  const rangeValueA = payload[0].payload.fairValue[1];
  const rangeValueB = payload[0].payload.fairValue[0];

  return (
    <Stack {...commonTileProps} boxShadow={"lg"} p="0" borderColor={borderColor}>
      <Box px="1rem" pt=".5rem" minH={"2rem"}>
        <ProjectHeaderImage maxHeight="1.5rem" width="8rem" collectionId={payload[0].payload.projectId} logoOnly />
      </Box>
      <Box p="1rem" bgColor={tooltipBgColor} color={"gray.100"}>
        <Grid templateColumns="auto 1fr" gap={2} alignItems="center">
          <GridItem>
            <Text fontSize="sm" color={labelColor}>
              Ticker:
            </Text>
          </GridItem>
          <GridItem>
            <Text fontSize="sm">{payload[0].payload.name}</Text>
          </GridItem>
          {!isNaN(Number(rangeValueB)) && (
            <>
              <GridItem>
                <Text fontSize="sm" color={labelColor}>
                  Fair Value High:
                </Text>
              </GridItem>
              <GridItem>
                <Text fontSize="sm">${rangeValueB}</Text>
              </GridItem>
            </>
          )}
          {!isNaN(Number(payload[1].value)) && (
            <>
              <GridItem>
                <Text fontSize="sm" color={labelColor}>
                  Current Price:
                </Text>
              </GridItem>
              <GridItem>
                <Text fontSize="sm">${payload[1].value}</Text>
              </GridItem>
            </>
          )}
          {!isNaN(Number(rangeValueA)) && (
            <>
              <GridItem>
                <Text fontSize="sm" color={labelColor}>
                  Fair Value Low:
                </Text>
              </GridItem>
              <GridItem>
                <Text fontSize="sm">${rangeValueA}</Text>
              </GridItem>
            </>
          )}
        </Grid>
      </Box>
    </Stack>
  );
});

CustomTooltip.displayName = "CustomTooltip";

const StockMarketChart: React.FC<StockMarketChartProps> = memo(({ data }) => {
  const isMobile = useBreakpointValue({ base: true, md: false, lg: false }, { fallback: "md", ssr: false });
  const { selectedProjectId, setSelectedProjectId } = useContext(CollectionsFilterContext);
  const { projectFilter, parentRoute } = useProjectParams();
  const navigate = useNavigate();

  const handleNavigate = useCallback(
    (projectId: string) => {
      navigate(`/${parentRoute}/${projectFilter}/${projectId}`);
    },
    [navigate, parentRoute, projectFilter]
  );

  // Memoize data transformation
  const transformedData = useMemo(
    () =>
      data.map((item) => ({
        projectId: item.projectId,
        name: item.ticker,
        currentPrice: item.currentPrice,
        fairValue: [item.rangeValueB, item.rangeValueA] as [number | undefined, number | undefined],
        fairValueBase: item.rangeValueA,
      })),
    [data]
  );

  const getOpacity = (projectId?: string) => {
    return !selectedProjectId || projectId === selectedProjectId ? 1 : 0.3;
  };

  const renderDot = (props: any) => {
    const { cx, cy, payload } = props;
    const isSelected = payload.projectId === selectedProjectId;
    const opacity = getOpacity(payload.projectId);

    return (
      <circle
        onClick={() => {
          if (payload.projectId) {
            handleNavigate(payload.projectId);
          }
        }}
        cx={cx}
        cy={cy}
        r={isSelected ? 8 : 4}
        fill="#ff4d4f"
        opacity={opacity}
        style={{ cursor: "pointer" }}
      />
    );
  };

  const renderActiveDot = (props: any) => {
    const { cx, cy, payload } = props;

    return (
      <circle
        onClick={() => {
          if (payload.projectId) {
            handleNavigate(payload.projectId);
          }
        }}
        cx={cx}
        cy={cy}
        r={8}
        fill="#ff4d4f"
        opacity={1}
        style={{ cursor: "pointer" }}
      />
    );
  };

  // Memoize chart settings
  const chartSettings = useMemo(
    () => ({
      margin: { top: 0, right: 0, left: -35, bottom: 0 },
      barSettings: {
        fill: "#90caf9",
        fillOpacity: 0.3,
        barSize: 40,
      },
      lineSettings: {
        stroke: "#ff4d4f",
        strokeWidth: 1,
      },
      gridSettings: {
        strokeDasharray: "3 3",
        vertical: true,
        horizontal: false,
      },
      axisSettings: {
        fontSize: "10px",
      },
    }),
    []
  );

  const handleMouseOver = (data: any) => {
    if (data && data.activePayload && data.activePayload[0]) {
      const projectId = data.activePayload[0].payload.projectId;
      setSelectedProjectId(projectId);
    }
  };

  const handleMouseOut = () => {
    setSelectedProjectId(undefined);
  };

  return (
    <Box height="19rem" width={isMobile ? "4/3" : "31rem"}>
      <ResponsiveContainer>
        <ComposedChart data={transformedData} margin={chartSettings.margin} onMouseMove={handleMouseOver} onMouseLeave={handleMouseOut}>
          <CartesianGrid {...chartSettings.gridSettings} />
          <XAxis dataKey="name" fontSize={chartSettings.axisSettings.fontSize} />
          <YAxis fontSize={chartSettings.axisSettings.fontSize} />
          <Tooltip content={<CustomTooltip />} />
          <Bar isAnimationActive={false} dataKey="fairValue" {...chartSettings.barSettings} />
          <Line
            isAnimationActive={false}
            type="monotone"
            dataKey="currentPrice"
            {...chartSettings.lineSettings}
            dot={renderDot}
            activeDot={renderActiveDot}
            opacity={0.3}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </Box>
  );
});

StockMarketChart.displayName = "StockMarketChart";

export default StockMarketChart;
