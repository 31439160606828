import { Button, Tooltip, useBreakpointValue } from "@chakra-ui/react";
import { useButtonProps, useUserProfile, useUserSubscriptionDetails, useUserSubscriptionDetailsDaysTillNextBilling } from "hooks";
import React from "react";
import { useConversationContext } from "screens/thread/ConversationContext";
import { track } from "api/analytics";
import { USER_UPGRADE_PLAN_CLICKED } from "api/analytics/events";
import { useFeatureUsage } from "hooks/useFeatureUsage";
import { Intent } from "types/intent";
import { useLocation, useNavigate } from "react-router-dom";
import { useAddToCharliContext } from "screens/panels/addToCharli/AddToCharliWizard/AddToCharliProvider";

export interface Feature {
  name: string;
  trial: boolean | string;
  business: boolean | string;
}

export const UpgradePlanButton = ({
  buttonSize,
  label = "Upgrade",
  tooltip,
  style,
  isDisabled,
  onClick,
}: {
  buttonSize: string;
  label?: string;
  tooltip?: string;
  style?: any;
  isDisabled?: boolean;
  onClick?: () => void;
}) => {
  const buttonStyle = useButtonProps(buttonSize, "subscribe");
  const { isUpgrading } = useConversationContext();
  const { onSettingsClose } = useAddToCharliContext();
  const { email, fullName } = useUserProfile();
  const subscriptionInfo = useUserSubscriptionDetails();
  const daysTillPlanExpiry = useUserSubscriptionDetailsDaysTillNextBilling();
  const answerUsage = useFeatureUsage(Intent.generateAnswer);
  const projectUsage = useFeatureUsage(Intent.createDueDiligenceProject);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const isMobile = useBreakpointValue({ base: true, md: false }) || false;

  const handleOnClick = () => {
    if (isDisabled || isUpgrading) {
      return;
    }

    track(USER_UPGRADE_PLAN_CLICKED, {
      pageViewed: pathname,
      userName: fullName,
      userEmail: email,
      answersUsed: answerUsage?.used,
      dueDiligenceUsed: projectUsage?.used,
      currentPlan: subscriptionInfo.plan,
      planStatus: subscriptionInfo.status,
      nextBillingDate: subscriptionInfo.nextBillingDate,
      subscriptionId: subscriptionInfo.subscriptionId,
      daysTillPlanExpiry: daysTillPlanExpiry.daysTillNextBilling,
    });

    if (onClick) {
      onClick();
    } else {
      navigate("/pricing");
      onSettingsClose();
    }
  };

  return (
    <Tooltip label={isMobile ? "" : tooltip} aria-label={tooltip} maxWidth="13rem">
      <Button isDisabled={isDisabled || isUpgrading} {...buttonStyle} {...style} onClick={handleOnClick}>
        {label}
      </Button>
    </Tooltip>
  );
};
