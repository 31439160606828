import {
  Box,
  Button,
  Circle,
  Icon,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import React, { useCallback, useMemo, useState } from "react";
import { useAppDispatch, useButtonProps, useCombinedEntitlementIds, useEntitlements } from "hooks";
import { useEntitlementConfigs, useIsEntitlementUpdating } from "hooks/useEntitlementConfigs";
import { AiOutlineDelete, AiOutlineDownload } from "react-icons/ai";
import { EntitlementConfigModal } from "./modals/EntitlementConfigModal";
import { EntitlementConfigUpsertModal } from "./modals/EntitlementConfigUpsertModal";
import { downloadEntitlementConfigs, removeEntitlementConfig } from "state/entitlements/operations";
import { ConfirmDeleteModal } from "screens/common/components/ConfirmDeleteModal";
import { EntitlementDetails } from "../configuredWorkflows/components/EntitlementDetails";
import { useFilterInput } from "hooks/useFilterInput";
import type { EntitlementConfig } from "api/entitlementConfigs/models/Entitlement";
import { getEnvironment } from "screens/common/app";
import { Select } from "chakra-react-select";
import { useBillingPlans } from "hooks/useBillingPlans";

const getPlanData = (entitlement: EntitlementConfig) => {
  const enabledPlans = Object.entries(entitlement.plans)
    .filter(([_, config]) => config.enabled)
    .map(([plan]) => plan);
  return enabledPlans.length > 0 ? enabledPlans.join(" : ") : "-";
};

const getOrgData = (entitlement: EntitlementConfig) => {
  const organizationsToDisplay = entitlement.organizations ? entitlement.organizations : [];
  return organizationsToDisplay.length > 0 ? organizationsToDisplay.map((org) => org.name).join(" : ") : "-";
};

const getUsersData = (entitlement: EntitlementConfig) => {
  const usersToDisplay = entitlement.users ? entitlement.users : [];
  return usersToDisplay.length > 0 ? usersToDisplay.map((user) => user.email).join(" : ") : "-";
};

const exportEntitlements = (entitlements: EntitlementConfig[]) => {
  const sanitizeField = (field: string) => field?.replace(/,/g, " ");

  const csvContent = entitlements
    .map((e) => {
      const baseFields = `${e.code},${e.name ? sanitizeField(e.name) : "-"},${
        e.description ? sanitizeField(e.description) : "-"
      },${"-"}, ${getPlanData(e)},${getOrgData(e)},${getUsersData(e)}`;
      return `${baseFields}`;
    })
    .join("\n");

  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  const date = new Date();
  const formattedDate = `${date.getFullYear()}${("0" + (date.getMonth() + 1)).slice(-2)}${("0" + date.getDate()).slice(-2)}`;

  link.setAttribute("download", `${getEnvironment().label}-entitlements-${formattedDate}.csv`);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const Entitlements = () => {
  const dispatch = useAppDispatch();
  const toast = useToast();
  const allWebAppEntitlements = useCombinedEntitlementIds() || [];
  const entitlementIdsMap = allWebAppEntitlements.reduce((acc, entitlementId) => {
    acc[entitlementId] = true;
    return acc;
  }, {});

  const compareAllWebAppEntitlementsWithEntitlements = () => {
    const entitlementsMap = entitlements.reduce((acc, entitlement) => {
      acc[entitlement.code] = true;
      return acc;
    }, {});

    return allWebAppEntitlements.reduce((acc, entitlementId) => {
      acc[entitlementId] = entitlementsMap[entitlementId] === true;
      return acc;
    }, {});
  };

  const [isOpen, setIsOpen] = useState<"detail" | "upsert" | "delete" | undefined>();
  const [selectedCode, setSelectedCode] = useState<string | null>(null);
  const [billingPlansFilter, setBillingPlansFilter] = useState<{ label: string; value: string }[]>([]);

  const { manage_entitlements: hasManageEntitlements } = useEntitlements();
  const entitlements = useEntitlementConfigs();
  const isLoading = useIsEntitlementUpdating();

  const billingPlans = useBillingPlans();

  const { filteredList: filteredListByQuery, renderFilterInputComponent } = useFilterInput<EntitlementConfig>(
    ["name", "code"],
    entitlements
  );

  const filteredList = useMemo(() => {
    if (!billingPlansFilter.length) {
      return filteredListByQuery;
    }

    return filteredListByQuery.filter((entitlement) => {
      return billingPlansFilter.some((plan) => entitlement.plans[plan.value]?.enabled);
    });
  }, [filteredListByQuery, billingPlansFilter]);

  const onClose = useCallback(() => {
    setIsOpen(undefined);
    setSelectedCode(null);
    dispatch(downloadEntitlementConfigs());
  }, [dispatch]);
  const onDelete = useCallback(async () => {
    if (!selectedCode) {
      return;
    }

    const response = await dispatch(removeEntitlementConfig(selectedCode));

    if (!response.payload) {
      const {
        error: { message },
      } = response as { error: { message: string } };

      toast({
        title: "Entitlement configuration",
        description: message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    await dispatch(downloadEntitlementConfigs());

    toast({
      title: "Entitlement configuration",
      description: `Action completed`,
      status: "success",
      duration: 5000,
      isClosable: true,
    });

    onClose();
  }, [selectedCode, dispatch, onClose, toast]);

  const onOpenUpsert = useCallback((code?: string) => {
    if (code) setSelectedCode(code);
    setIsOpen("upsert");
  }, []);

  // styles
  const commonButtonProps = useButtonProps("sm", "primary");
  const buttonColor = useColorModeValue("gray.500", "gray.300");
  const buttonHoverColor = useColorModeValue("gray.600", "gray.400");

  const renderEntitlements = useCallback(() => {
    return (filteredList || []).map((entitlement, index) => {
      return (
        <Tr
          role={"button"}
          onClick={(event: { stopPropagation: () => void }) => {
            event.stopPropagation();
            setSelectedCode(entitlement.code);
            setIsOpen("upsert");
          }}
          key={index}>
          <Td px="0" py=".5rem">
            <Stack direction="row">
              <Tooltip label={entitlementIdsMap[entitlement.code] === true ? "Used in webapp" : "Not used in webapp"} placement="top">
                <Circle mt="7px" size="7px" bg={entitlementIdsMap[entitlement.code] === true ? "green.500" : "orange.300"} />
              </Tooltip>
              <Text maxWidth={"17rem"}>{entitlement.code}</Text>
            </Stack>
          </Td>
          <Td px="0" py=".5rem">
            {entitlement.name}
          </Td>
          <Td px="0" py=".5rem">
            {entitlement.description || "-"}
          </Td>
          <EntitlementDetails entitlement={entitlement} />
          <Td px="0" py=".5rem">
            <Tooltip aria-label="" label="Delete entitlement" placement="top" hasArrow>
              <Box>
                <Icon
                  cursor="pointer"
                  as={AiOutlineDelete}
                  color={buttonColor}
                  boxSize="1rem"
                  _hover={{ color: buttonHoverColor }}
                  onClick={async (event: { stopPropagation: () => void }) => {
                    event.stopPropagation();

                    setSelectedCode(entitlement.code);
                    setIsOpen("delete");
                  }}
                />
              </Box>
            </Tooltip>
          </Td>
        </Tr>
      );
    });
  }, [buttonColor, buttonHoverColor, entitlementIdsMap, filteredList]);

  const renderMissingEntitlements = () => {
    const noEntitlementDefined = Object.keys(compareAllWebAppEntitlementsWithEntitlements()).filter((key) => {
      return compareAllWebAppEntitlementsWithEntitlements()[key] === false;
    });

    if (noEntitlementDefined.length === 0) {
      return null;
    }

    return noEntitlementDefined.map((entitlementId, index) => {
      return (
        <Tr
          role={"button"}
          onClick={(event: { stopPropagation: () => void }) => {
            event.stopPropagation();
            setSelectedCode(entitlementId);
            setIsOpen("upsert");
          }}
          key={index}>
          <Td px="0" py=".5rem">
            <Stack direction="row">
              <Tooltip label="Used in webapp but missing" placement="top">
                <Circle mt="7px" size="7px" bg="red.500" />
              </Tooltip>
              <Text maxWidth={"17rem"}>{entitlementId}</Text>
            </Stack>
          </Td>
          <Td px="0" py=".5rem"></Td>
          <Td px="0" py=".5rem">
            -- Update Required --
          </Td>
          <Td px="0" py=".5rem"></Td>
          <Td px="0" py=".5rem"></Td>
          <Td px="0" py=".5rem"></Td>
          <Td px="0" py=".5rem"></Td>
        </Tr>
      );
    });
  };

  const billingPlansSelectOptions = useMemo(() => {
    return billingPlans.map((plan) => ({ label: plan.name, value: plan.code }));
  }, [billingPlans]);

  return (
    <Box mb="2rem!important" width={"100%"}>
      <Stack alignItems={"center"} direction="row" justifyContent="space-around" pb=".5rem" spacing="0.5rem">
        {renderFilterInputComponent("Filter by entitlement name")}
        <Box minWidth={400}>
          <Select
            className="ch-multi-select"
            useBasicStyles
            size="sm"
            selectedOptionStyle="check"
            isLoading={isLoading}
            options={billingPlansSelectOptions}
            onChange={(newValues) => {
              setBillingPlansFilter(newValues as { label: string; value: string }[]);
            }}
            isMulti
            placeholder={`Filter by billing plans...`}
            value={billingPlansFilter}
          />
        </Box>

        <Button minWidth="12rem" isDisabled={!hasManageEntitlements} {...commonButtonProps} onClick={() => onOpenUpsert()}>
          Create new entitlement
        </Button>
      </Stack>
      <Box overflow="auto">
        <Table variant="simple" fontSize={"sm"}>
          <Thead>
            <Tr>
              <Th px="0" py=".5rem">
                Code
              </Th>
              <Th px="0" py=".5rem">
                Name
              </Th>
              <Th px="0" py=".5rem">
                Description
              </Th>
              <Th px="0" py=".5rem">
                Plans
              </Th>
              <Th px="0" py=".5rem">
                Orgs
              </Th>
              <Th px="0" py=".5rem">
                Users
              </Th>
              <Th px="0" py=".5rem">
                <Tooltip aria-label="" label="Export entitlements" placement="top" hasArrow>
                  <Box>
                    <Icon
                      cursor="pointer"
                      as={AiOutlineDownload}
                      color={buttonColor}
                      boxSize="1rem"
                      _hover={{ color: buttonHoverColor }}
                      onClick={(event) => {
                        event.stopPropagation();
                        exportEntitlements(entitlements);
                      }}
                    />
                  </Box>
                </Tooltip>
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {renderMissingEntitlements()}
            {renderEntitlements()}
          </Tbody>
        </Table>
      </Box>

      {isOpen === "detail" && <EntitlementConfigModal code={selectedCode} isOpen onClose={onClose} />}
      {isOpen === "upsert" && <EntitlementConfigUpsertModal code={selectedCode} isOpen onClose={onClose} />}
      {isOpen === "delete" && selectedCode && (
        <ConfirmDeleteModal
          isOpen
          onClose={onClose}
          onConfirm={onDelete}
          title="Confirm Delete"
          body={selectedCode}
          isLoading={isLoading}
        />
      )}
    </Box>
  );
};
