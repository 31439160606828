import { Box, Center, Text, useColorModeValue } from "@chakra-ui/react";
import React, { useMemo } from "react";
import { ProjectAnalysisPopover } from "../ProjectAnalysisPopover";

interface ScaleBarProps {
  analyticalValue: number;
  sentimentValue: number;
  projectId?: string;
}

const ScaleBar: React.FC<ScaleBarProps> = ({ analyticalValue, sentimentValue, projectId }) => {
  const clampValue = (value: number): number => Math.min(Math.max(value, 0), 100);
  const colorMode = useColorModeValue("400", "900");
  const indicatorColor = useColorModeValue("gray.600", "gray.400");

  const positions = useMemo(
    () => ({
      analytical: clampValue(analyticalValue),
      sentiment: clampValue(sentimentValue),
    }),
    [analyticalValue, sentimentValue]
  );

  const Indicator = ({ position, label, isTop }: { position: number; label: string; isTop: boolean }) => (
    <Box position="absolute" left={`${position}%`} transform="translateX(-50%)" {...(isTop ? { top: "-25px" } : { bottom: "-25px" })}>
      {isTop ? (
        <>
          <Text color={indicatorColor} fontSize="12px" textAlign="center">
            {label}
          </Text>
          <Center>
            <Box
              width="0"
              height="0"
              borderLeft="12px solid transparent"
              borderRight="12px solid transparent"
              borderTop="16px solid"
              borderTopColor={indicatorColor}
            />
          </Center>
        </>
      ) : (
        <>
          <Center>
            <Box
              width="0"
              height="0"
              borderLeft="12px solid transparent"
              borderRight="12px solid transparent"
              borderBottom="16px solid"
              borderBottomColor={indicatorColor}
            />
          </Center>
          <Text color={indicatorColor} fontSize="12px" textAlign="center">
            {label}
          </Text>
        </>
      )}
    </Box>
  );

  return (
    <Box position="relative" width="100%">
      <Box
        height="2rem"
        bgGradient={`linear(to-r, red.${colorMode}, yellow.${colorMode}, green.${colorMode})`}
        position="relative"
        borderRadius="md"
        cursor="pointer">
        {analyticalValue > 0 && (
          <ProjectAnalysisPopover projectId={projectId}>
            <Box position="absolute" width="100%" height="100%" top="0" bottom="-25px">
              <Indicator position={positions.analytical} label="Analytical" isTop={false} />
            </Box>
          </ProjectAnalysisPopover>
        )}
        {sentimentValue > 0 && (
          <ProjectAnalysisPopover projectId={projectId}>
            <Box position="absolute" width="100%" height="100%" top="0" bottom="-25px">
              <Indicator position={positions.sentiment} label="Sentiment" isTop />
            </Box>
          </ProjectAnalysisPopover>
        )}
      </Box>
    </Box>
  );
};

export default ScaleBar;
