import { Box, useColorModeValue } from "@chakra-ui/react";
import type { FunctionComponent } from "react";
import { startTransition, useRef } from "react";
import React, { useEffect, useMemo } from "react";
import DOMPurify from "dompurify";
import marked from "marked";
import { useCustomScrollbar } from "hooks/useCustomScrollbar";

interface Props {
  markupText?: string;
  className?: string;
  maxHeight?: string;
  searchText?: string;
}

export const MarkupTextViewer: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  markupText,
  className,
  maxHeight = "55rem",
  searchText,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { scrollbarStyle } = useCustomScrollbar(containerRef, { width: "2px" });
  const textColor = useColorModeValue("primary.darkGray", "gray.400");

  const escapedMarkupText = useMemo(() => {
    if (!markupText) return "";
    // Escape tildes by adding a backslash before them
    return markupText.replace(/~/g, "\\~");
  }, [markupText]);

  const parsedMarkdown = useMemo(() => marked(escapedMarkupText), [escapedMarkupText]);
  const [highlightedHtml, setHighlightedHtml] = React.useState<string>("");

  useEffect(() => {
    const purifiedHtml = DOMPurify.sanitize(parsedMarkdown);

    if (!searchText) {
      setHighlightedHtml(purifiedHtml.replace(/<br>/g, "<p>"));
    } else {
      startTransition(() => {
        const regex = new RegExp(searchText, "gi");
        setHighlightedHtml(purifiedHtml.replace(/<br>/g, "<p>").replace(regex, (match) => `<mark>${match}</mark>`));
      });
    }
  }, [parsedMarkdown, searchText]);

  return (
    <Box
      ref={containerRef}
      maxHeight={maxHeight}
      className={className}
      overflowY="auto"
      color={textColor}
      dangerouslySetInnerHTML={{ __html: highlightedHtml }}
      css={{
        fontSize: "0.85rem",
        blockquote: {
          background: "#f9f9f9",
          marginTop: "1.25rem !important",
          marginBottom: "1.25rem !important",
        },
        ol: {
          margin: "0 0 0 1.5rem",
          padding: "0",
        },
        ul: {
          margin: "0 0 0 1rem",
          padding: "0",
        },
        p: {
          textAlign: "left",
          display: "block",
        },
        "blockquote p": {
          display: "inline",
          color: "#555555",
          fontStyle: "italic",
        },
        mark: {
          backgroundColor: "yellow",
          color: "inherit",
        },
        ...scrollbarStyle,
      }}
    />
  );
};
