import { extendTheme } from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";

const breakpoints = {
  sm: "30em",
  md: "48em",
  lg: "1200px",
  xl: "1900px",
  "2xl": "2200px",
};

const defaultTheme = extendTheme({
  breakpoints: breakpoints,
  components: {
    Tooltip: {
      baseStyle: (props) => ({
        bg: mode("gray.600", "gray.700")(props),
        color: mode("gray.100", "gray.100")(props),
      }),
    },
  },
  styles: {
    global: (props) => ({
      button: {
        _focus: {
          boxShadow: "0 0 0 1px rgba(0, 0, 0, 0) !important",
        },
      },
      input: {
        borderRadius: "5px!important",
        _focus: {
          boxShadow: "none!important",
          borderColor: mode("#CBD5E0 !important", "#4A5568 !important")(props),
        },
      },
      switch: {
        _focus: {
          boxShadow: "none",
        },
      },
    }),
  },
  fonts: {
    print: `canada-type-gibson, "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
    heading: `-apple-system, BlinkMacSystemFont, "Montserrat", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
    body: `-apple-system, BlinkMacSystemFont, "League Spartan", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Roboto", "Barlow"`,
    mono: `SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace`,
    logo: `"Barlow", sans-serif`,
  },

  fontSizes: {
    xs: "0.75rem",
    sm: "0.85rem",
    md: "0.9rem",
    lg: "1rem",
    xl: "1.125rem",
    "2xl": "1.5rem",
    "3xl": "1.875rem",
    "4xl": "2.25rem",
    "5xl": "3rem",
    "6xl": "3.75rem",
    "7xl": "4.5rem",
    "8xl": "6rem",
    "9xl": "8rem",
  },
  colors: {
    progress_theme: {
      50: "#D98F2F",
      100: "#D98F2F",
      200: "#D98F2F",
      300: "#D98F2F",
      400: "#D98F2F",
      500: "#D98F2F",
      600: "#D98F2F",
      700: "#D98F2F",
      800: "#D98F2F",
      900: "#D98F2F",
    },
    white: "#FFFFFF",
    gray: {
      50: "#F3F2F8",
    },
    charli: {
      primaryBlue: "#009AD8",
      lightBlue: "#E1F0F7",
      darkBlue: "#2D3748",
      primaryGreen: "#81C34C",
      lightGreen: "#00A46B",
      greenDark: "#007E7D",
      yellow: "#FDB515",
      lightGray: "#667E87",
      mediumGray: "#61666A",
      darkGray: "#D8DCDB",
    },
    primary: {
      default: "#009AD8",
      hover: "#2a83a7",
      active: "#F58A24",
      disabled: "#FFF6EB",
      orange: "#F58A24",
      blue: "#0650BA",
      darkGray: "#465055",
      lightGray: "#9D9D9D",
    },
    secondary: {
      default: "#007bad",
      hover: "#0096d3",
      active: "#2D3748",
      disabled: "#E2E8F0",
      teal: "#2EC4A7",
      purple: "#7B77F3",
      lightOrange: "#FFA43B",
      lightBlue: "#21C0EE",
      darkPurple: "#0F2F6E",
      yellow: "#F8C041",
      pink: "#F45C75",
      green: "#90EE90",
      lightGray: "#CCD4DB",
    },
    tertiary: {
      default: "#2EC4A7",
      hover: "#2ea58e",
      active: "#e8dfe3",
      disabled: "#C39AA3",
    },
    primaryButton: {
      background: "transparent",
      borderColor: "#009AD8",
      color: "#49628d", // gray.700
      hover: "#1A202C", // gray.800
      selectedColor: "#149bd1",
      selectedHover: "#0779a7",
      hoverColor: "white",
      colorDarkMode: "#718096", // gray.500
      hoverDarkMode: "#A0AEC0", // gray.400
      selectedColorDarkMode: "#009AD8",
      selectedHoverDarkMode: "#06acef",
      error: "#FF5252",
      warning: "#ff9407",
    },
    secondaryButton: {
      background: "transparent",
      color: "#718096", // gray.500
      hover: "#009AD8", // gray.600
      selectedColor: "#009AD8",
      selectedHover: "#0779a7",
      colorDarkMode: "#718096", // gray.500
      hoverDarkMode: "#A0AEC0", // gray.400
      selectedColorDarkMode: "#009AD8",
      selectedHoverDarkMode: "#06acef",
    },
    tertiaryButton: {
      background: "#F58A24",
      borderColor: "#F58A24",
      hover: "#C05621",
      color: "#FFFFFF",
    },
    tags: {
      auto: "#d4eef3",
      category: "#f0e2ff",
      manual: "#E2E8F0",
      autoDarkMode: "#F58A24",
      categoryDarkMode: "#F58A24",
      manualDarkMode: "#009AD8",
      color: "#000000",
      colorDarkMode: "#FFFFFF",
    },
    shades: {
      teal: "#E9F9F6",
      purple: "#F2F1FF",
      lightOrange: "#FFF6EB",
      lightBlue: "#E5F8FF",
      darkPurple: "#E7EAF0",
      yellow: "#FFF9EA",
      pink: "#FFEFF1",
    },
    shadesHover: {
      teal: "#E2F8F4",
      purple: "#E6E4FC",
      lightOrange: "#FFF6EB",
      lightBlue: "#E5F8FF",
      darkPurple: "#D8E0EF",
      yellow: "#FFF9EA",
      pink: "#FFEFF1",
    },
    chatButton: {
      background: "#4A5568",
      hoverBgColor: "#3e5479",
    },
  },
});

const burgundyTheme = extendTheme({
  ...defaultTheme,
  fonts: {
    print: `canada-type-gibson, "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
    heading: `-apple-system, BlinkMacSystemFont, "Montserrat", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
    body: `-apple-system, BlinkMacSystemFont, "League Spartan", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
    mono: `SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace`,
    logo: `"Barlow", sans-serif`,
  },
  colors: {
    primary: {
      default: "#95193f",
      hover: "#780529",
      active: "#7A1F34",
      disabled: "#C39AA3",
      orange: "#7A1F34",
    },
    secondary: {
      default: "#6d6d6d",
      hover: "#e8dfe3",
      active: "#e8dfe3",
      disabled: "#C39AA3",
    },
    tertiary: {
      default: "#95193f",
      hover: "#dd205b",
      active: "#e8dfe3",
      disabled: "#C39AA3",
    },
    primaryButton: {
      background: "#FFFFFF",
      borderColor: "#e2e8f0",
      hover: "#e8dfe3",
      color: "#95193f",
    },
    secondaryButton: {
      background: "#FFFFFF",
      borderColor: "#95193f",
      hover: "#e8dfe3",
      color: "#95193f",
    },
    tertiaryButton: {
      background: "#95193f",
      borderColor: "#FFFFFF",
      hover: "#e8dfe3",
      color: "#FFFFFF",
    },
    chatButton: {
      background: "#95193f",
    },
  },
});

export { defaultTheme, burgundyTheme };
