import { Literal, Union } from "runtypes";
import type { Static } from "runtypes";

// "night" | "early-morning" | "morning" | "late-morning" | "afternoon" | "late-afternoon" | "evening" | "late-evening";

export const DesiredTime = Union(
  Literal("night"),
  Literal("early-morning"),
  Literal("morning"),
  Literal("late-morning"),
  Literal("afternoon"),
  Literal("late-afternoon"),
  Literal("evening"),
  Literal("late-evening")
);

export type DesiredTime = Static<typeof DesiredTime>;
