import type { Collection } from "types/collection";

export interface QuadrantDataPoint {
  projectId: string;
  projectName: string;
  x: number;
  y: number;
  color: string;
}

export const generateQuadrantData = (groupedCollections: Collection[]): QuadrantDataPoint[] => {
  if (!groupedCollections) return [];

  return groupedCollections
    .map((collection) => {
      const { investmentScoreAnalytical, investmentScoreSentiment, name, id } = collection || {};

      const analyticalValue = investmentScoreAnalytical?.value ?? "";
      const sentimentValue = investmentScoreSentiment?.value ?? "";

      const regexInvestmentScoreAnalytical = analyticalValue.match(/\d+(\.\d+)?/g);
      const regexInvestmentScoreSentiment = sentimentValue.match(/\d+(\.\d+)?/g);

      const x = Number(regexInvestmentScoreAnalytical?.[0] ?? 0);
      const y = Number(regexInvestmentScoreSentiment?.[0] ?? 0);

      if (x === 0 || y === 0) return null;

      return {
        projectId: id,
        projectName: name,
        x: Math.min(Math.max(x, 0), 100),
        y: Math.min(Math.max(y, 0), 100),
        color: "gray.500",
      };
    })
    .filter((item): item is NonNullable<typeof item> => item !== null);
};
