import React, { useContext, useMemo } from "react";
import { Icon, Tooltip, IconButton } from "@chakra-ui/react";
import { useButtonProps } from "hooks";
import { VscClearAll } from "react-icons/vsc";
import { CollectionsFilterContext } from "./CollectionsFilterContext";
import { useConversationContext } from "screens/thread/ConversationContext";

export const CollectionsFilterClearFilterButton = () => {
  const { searchText, setSearchText, selectedQuadrant, setSelectedQuadrant, selectedRating, setSelectedRating } =
    useContext(CollectionsFilterContext);
  const primaryButtonProps = useButtonProps("sm", "primary");
  const { setIsAnotherInputFocused } = useConversationContext();

  const isContentFiltered = useMemo(() => {
    return searchText !== "" || selectedQuadrant !== undefined || selectedRating !== undefined;
  }, [searchText, selectedQuadrant, selectedRating]);

  return (
    <Tooltip label={isContentFiltered ? "Clear Filter" : ""}>
      <IconButton
        isDisabled={!isContentFiltered}
        {...primaryButtonProps}
        className="ch-clear-project-filter-btn"
        onClick={() => {
          setIsAnotherInputFocused(false);
          setSelectedQuadrant(undefined);
          setSelectedRating(undefined);
          setSearchText("");
        }}
        aria-label="Clear Filters"
        icon={<Icon as={VscClearAll} fontSize=".9rem" />}></IconButton>
    </Tooltip>
  );
};
