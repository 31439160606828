import { Tooltip, useColorModeValue, Center, IconButton } from "@chakra-ui/react";
import { useProjectParams } from "hooks";
import type { FunctionComponent } from "react";
import React from "react";
import { AiOutlineFileText } from "react-icons/ai";
import { useLocation, useNavigate } from "react-router-dom";
import { useAddToCharliContext } from "screens/panels/addToCharli/AddToCharliWizard/AddToCharliProvider";

interface Props {
  collectionId: string;
  isDisabled?: boolean;
  size?: string;
}

export const ProjectReportPanelButton: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  collectionId,
  isDisabled,
  size = ".9rem",
}) => {
  const tileBorderColor = useColorModeValue("gray.500", "gray.400");
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { onProjectReportPanelOpen } = useAddToCharliContext();
  const route = pathname.split("/").slice(1, 3).join("/");
  const { projectId } = useProjectParams();

  return (
    <Tooltip
      aria-label="project report"
      label={isDisabled ? "No Reports" : "Open Reports"}
      placement="top"
      className="ch-open-report-panel">
      <Center>
        <IconButton
          minWidth={"unset"}
          className="ch-open-report-panel-icon"
          icon={<AiOutlineFileText />}
          aria-label="Open Reports"
          variant="ghost"
          color={tileBorderColor}
          size="sm"
          fontSize={size}
          isDisabled={isDisabled}
          _hover={{
            color: "primary.default",
            bg: "transparent",
          }}
          onClick={(event) => {
            event.stopPropagation();
            navigate(`/${route}/${collectionId || projectId}/panel`);
            onProjectReportPanelOpen();
          }}
        />
      </Center>
    </Tooltip>
  );
};
