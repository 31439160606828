import { Box, useBreakpointValue } from "@chakra-ui/react";
import React, { useContext } from "react";
import { ConversationContext } from "./ConversationContext";
import { CharliLogoCircle } from "./CharliLogoCircle";
import { useUserPreference } from "hooks";

export const ConversationDialogButton = () => {
  const { isConversationOpen, onConversationClose, onConversationOpen, setConversationId, setCollectionId } =
    useContext(ConversationContext);
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });
  const hasTestEntityPreferenceEnabled = useUserPreference("ui_inject_test_entity") as boolean;

  const onHandleClose = () => {
    if (isConversationOpen) {
      setCollectionId("");
      setConversationId("");
      onConversationClose();
    } else {
      onConversationOpen();
    }
  };

  return (
    <>
      {hasTestEntityPreferenceEnabled && (
        <Box
          className="ch-command-line-container"
          position="fixed"
          bottom={isMobile ? "2rem" : "1rem"}
          right="1rem"
          zIndex="10001"
          cursor="pointer">
          <CharliLogoCircle onClick={onHandleClose} className="ch-quick-actions-command-line-button" />
        </Box>
      )}
    </>
  );
};
