import React, { useEffect } from "react";
import { useConversationContext } from "screens/thread/ConversationContext";
import { CommonModal } from "./CommonModal";
import { Wizard } from "react-use-wizard";
import { SubscriptionUpgradeConfirm } from "screens/panels/settings/tabs/subscription/SubscriptionUpgradeConfirm";
import { useDispatch } from "react-redux";
import { generateSecurityToken } from "state/subscription/operations";

export const SubscriptionModal = () => {
  const { isSubscriptionModalOpen, onSubscriptionModalClose } = useConversationContext();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(generateSecurityToken());
  }, [dispatch]);

  return (
    <CommonModal
      borderColor="#4799d4"
      minWidth={["90%", "65rem", "45rem"]}
      isOpen={isSubscriptionModalOpen}
      onClose={onSubscriptionModalClose}
      showCloseButton
      modalBody={
        <Wizard>
          <SubscriptionUpgradeConfirm upgradePlan={"professional"} />
        </Wizard>
      }
    />
  );
};
