import React from "react";
import { Stack, Text, Box, useColorModeValue, Tooltip, useColorMode } from "@chakra-ui/react";
import { useButtonProps } from "hooks";
import { getStatusColor, getStatusIcon } from "screens/common/components/WorkflowSummary";

interface Props {
  label: string | number;
  value?: string | number;
  tooltip?: string;
  size?: "xxs" | "xs" | "sm" | "md";
  hideIcon?: boolean;
  className?: string;
  onClick?: () => void;
}

export const CustomStat: React.FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  label,
  value,
  tooltip,
  size,
  hideIcon,
  className = "ch-workflow-step",
  onClick,
}) => {
  const secondaryButtonProps = useButtonProps("sm", "secondary");
  const stepperBgColor = useColorModeValue("gray.50", "gray.700");
  const iconColor = useColorModeValue("100", "800");
  const fontColor = useColorModeValue("charli.mediumGray", "gray.500");
  const { colorMode } = useColorMode();

  return (
    <Tooltip label={tooltip} aria-label="Workflow Step" placement="top">
      <Box
        {...secondaryButtonProps}
        {...(onClick && {
          cursor: "pointer",
          onClick: (evt) => {
            evt.stopPropagation();
            onClick();
          },
        })}
        color={fontColor}
        pt={size === "sm" ? "3px" : size === "md" ? "5px" : "1px"}
        pb={size === "sm" ? "3px" : size === "md" ? "4px" : "1px"}
        pr={size !== "xxs" ? "8px" : "1px"}
        pl={hideIcon ? "8px" : size === "sm" ? "3px" : size === "md" ? "5px" : "1px"}
        fontWeight="semibold"
        borderRadius={"full"}
        backgroundColor={`${getStatusColor(value as string)}.${iconColor}` || stepperBgColor}
        border="none"
        className={className}
        textAlign="left">
        <Stack direction="row" spacing="0" justifyContent={hideIcon ? "center" : "left"} alignItems={hideIcon ? "center" : "left"}>
          {!hideIcon && value && getStatusIcon(value as string, colorMode)}
          <Text
            className="ch-stat-label"
            width="100%"
            mt="1px"
            px={hideIcon ? "0" : "4px"}
            isTruncated
            fontSize={size === "md" ? "md" : "10px"}
            maxWidth="6rem"
            marginInlineStart={"0!important"}>
            {label}
          </Text>
        </Stack>
      </Box>
    </Tooltip>
  );
};
