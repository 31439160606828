import React, { useEffect, useState, useCallback, useMemo } from "react";
import type { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Stack,
  AccordionIcon,
  useBreakpointValue,
  Flex,
  Tooltip,
  IconButton,
  useColorModeValue,
} from "@chakra-ui/react";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import type { DropResult } from "@hello-pangea/dnd";
import type { IconType } from "react-icons";
import { SidebarButton } from "./SidebarButton";
import { useConversationContext } from "screens/thread/ConversationContext";
import { useCollections, filterCollectionsByType, useUserPreference, useProjectParams } from "hooks";
import type { Collection } from "types/collection";
import { updateTypedUserPreference } from "state/userPreference/operations";
import { AddIcon } from "@chakra-ui/icons";

interface Props {
  text?: string;
  selectedProjectCategory?: string;
  icon?: IconType;
  className?: string;
}

export const SidebarCategory: FunctionComponent<React.PropsWithChildren<Props>> = React.memo(
  ({ text, selectedProjectCategory, icon, className }) => {
    const { projectId } = useProjectParams();
    const { onPortfolioModalOpen, setSelectedProjectCategory } = useConversationContext();
    const collections = useCollections();
    const navigate = useNavigate();
    const portfolioMenuOrder = useUserPreference("ui_sidebar_portfolio_order") as string[];
    const dispatch = useDispatch();
    const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });
    const addButtonFontColor = useColorModeValue("gray.50", "gray.900");

    const filteredCollections = useMemo(
      () => filterCollectionsByType(collections, selectedProjectCategory || ""),
      [collections, selectedProjectCategory]
    );

    const [orderedCollections, setOrderedCollections] = useState<Collection[]>([]);

    useEffect(() => {
      const storedOrder = portfolioMenuOrder || [];
      const newItems = filteredCollections.filter((collection) => !storedOrder.includes(collection.id));
      const orderedItems = [...storedOrder, ...newItems.map((item) => item.id)];

      const reorderedCollections = orderedItems
        .map((id) => filteredCollections.find((collection) => collection.id === id))
        .filter((collection): collection is Collection => collection !== undefined);

      setOrderedCollections(reorderedCollections);
    }, [filteredCollections, portfolioMenuOrder]);

    const onClickHandler = useCallback(() => {
      setSelectedProjectCategory(selectedProjectCategory);
      onPortfolioModalOpen();
    }, [setSelectedProjectCategory, selectedProjectCategory, onPortfolioModalOpen]);

    const onDragEnd = useCallback(
      (result: DropResult) => {
        if (!result.destination) return;

        setOrderedCollections((prevOrderedCollections) => {
          const newOrder = Array.from(prevOrderedCollections);
          const [reorderedItem] = newOrder.splice(result.source.index, 1);
          newOrder.splice(result.destination!.index, 0, reorderedItem);

          const newOrderIds = newOrder.map((collection) => collection.id);
          dispatch(
            updateTypedUserPreference({
              preferenceKey: "ui_sidebar_portfolio_order",
              value: newOrderIds,
            })
          );

          return newOrder;
        });
      },
      [dispatch]
    );

    const renderDraggableItem = useCallback(
      (collection: Collection, index: number) => (
        <Draggable key={collection.id} draggableId={collection.id} index={index}>
          {(provided) => (
            <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
              <SidebarButton
                onClick={() => navigate(`/portfolios/${collection.id}`)}
                text={collection.name}
                screen={`/portfolios/${collection.id}`}
                cssClasses={["ch-menu-category"]}
                isChildProject
                menuProjectId={collection.id}
              />
            </div>
          )}
        </Draggable>
      ),
      [navigate]
    );

    return (
      <Accordion allowToggle defaultIndex={0} reduceMotion>
        <AccordionItem border="none">
          <Flex alignItems="center" width="100%">
            <SidebarButton text={text} icon={icon} cssClasses={[className || "ch-menu-category"]}>
              <Stack direction={"row"} spacing="5px">
                <AccordionButton _expanded={{}} _hover={{}} p="0" width="auto" minWidth="unset" paddingInline={0}>
                  <AccordionIcon color="gray.500" />
                </AccordionButton>
                <Tooltip
                  maxWidth={"10rem"}
                  label={projectId && projectId.length > 0 ? "" : "Add a new portfolio to manage your equities"}
                  aria-label="Add Portfolio">
                  <IconButton
                    as="div"
                    className="ch-sidebar-portfolio-button"
                    minWidth={"unset"}
                    boxSize="1.2rem"
                    icon={<AddIcon boxSize={".7rem"} />}
                    onClick={(evt) => {
                      evt.preventDefault();
                      onClickHandler();
                    }}
                    _hover={{}}
                    _active={{}}
                    bgColor="primary.default"
                    color={addButtonFontColor}
                    aria-label={""}
                  />
                </Tooltip>
              </Stack>
            </SidebarButton>
          </Flex>
          {orderedCollections.length > 0 && (
            <AccordionPanel pl={"0"} pb="0" pt="1rem" paddingInlineEnd={0}>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="sidebar-buttons">
                  {(provided) => (
                    <Stack spacing="1rem" pl={isMobile ? "2rem" : "unset"} {...provided.droppableProps} ref={provided.innerRef}>
                      {orderedCollections.map(renderDraggableItem)}
                      {provided.placeholder}
                    </Stack>
                  )}
                </Droppable>
              </DragDropContext>
            </AccordionPanel>
          )}
        </AccordionItem>
      </Accordion>
    );
  }
);
