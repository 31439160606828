import React, { useMemo } from "react";
import type { FunctionComponent } from "react";
import { useCollection, useContent, useContents } from "hooks";
import { ProjectThumbnailsGrid } from "screens/landing/tabs/project/projectLandingTileLayouts/components/ProjectThumbnailGrid";

interface Props {
  metadataId: string;
}

export const ContentImageGrid: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({ metadataId }) => {
  const contentData = useContent(metadataId);
  const collectionIdFromContent = useMemo(() => contentData?.urls?.url?.split("/")[4], [contentData?.urls?.url]);

  const collection = useCollection(collectionIdFromContent ? collectionIdFromContent : contentData?.id, { refreshFromNetwork: true });
  const collectionMetadataIds = useMemo(() => {
    return collection?.metadataIds?.slice(0, 4) ?? [];
  }, [collection?.metadataIds]);
  useContents(collectionMetadataIds, { refreshFromNetwork: true });

  return <ProjectThumbnailsGrid metadataIds={collectionMetadataIds} />;
};
