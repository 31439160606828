import React, { useEffect, useRef, useState, useCallback, useContext } from "react";
import { Box, IconButton, Input, Tag, TagLabel, TagRightIcon, useColorModeValue, Center, Icon, Stack } from "@chakra-ui/react";
import { ArrowForwardIcon, CloseIcon } from "@chakra-ui/icons";
import { AiOutlineDelete } from "react-icons/ai";
import ECHighlighter from "react-ec-highlighter";
import { ContentFilterContext } from "screens/content";

interface Tag {
  id: string;
  text: string;
}

interface Props {
  tagType?: "manual" | "auto" | "category" | "topic";
  handleOnDeleteTag?: (tag: string) => void;
  handleUpdate?: (tags: Tag[]) => void;
  allowUpdate?: boolean;
  allowDelete?: boolean;
  size?: "xs" | "sm" | "md";
  onClickTag?: (tag: string) => void;
  maxTagsToShow?: number;
  searchPhrase?: string;
  localTags: Tag[];
  setLocalTags: (value: React.SetStateAction<Tag[]>) => void;
  showAllTags: boolean;
  setShowAllTags: (value: React.SetStateAction<boolean>) => void;
  allowFilter?: boolean;
}

const delimiters = ["Enter"];

export const TagItems = ({
  tagType = "manual",
  allowUpdate = true,
  allowDelete = true,
  handleOnDeleteTag,
  handleUpdate,
  size = "xs",
  onClickTag,
  maxTagsToShow = 5,
  searchPhrase,
  localTags,
  setLocalTags,
  showAllTags,
  setShowAllTags,
  allowFilter = false,
}: Props) => {
  const editTagRef = useRef<HTMLInputElement>(null);
  const [selectedTag, setSelectedTag] = useState<Tag | null>(null);
  const topicBgColor = useColorModeValue("#cff3c8", "gray.800");
  const suggestionsBgColor = useColorModeValue("white", "gray.800");
  const tagEditingBg = useColorModeValue("white", "gray.800");
  const tagDeleteColor = useColorModeValue("#e15d5d", "gray.800");
  const [tagHover, setTagHover] = useState("");
  const [selectedTagIndex, setSelectedTagIndex] = useState("");
  const tagBgColor = useColorModeValue("tags.manual", "gray.800");
  const autoTagBgColor = useColorModeValue("tags.auto", "teal.800");
  const categoryTagBgColor = useColorModeValue("tags.category", "teal.800");
  const tagTextColor = useColorModeValue("tags.color", "gray.200");
  const { selectedTags, setSelectedTags } = useContext(ContentFilterContext);

  const tagBackgroundColor = (tagType: string, tagId?: string) => {
    if (tagId && selectedTags.includes(tagId)) return "#f8f8a4";
    if (tagType === "auto") return autoTagBgColor;
    if (tagType === "category") return categoryTagBgColor;
    if (tagType === "topic") return topicBgColor;
    return tagBgColor;
  };

  const onHandleMouseEnter = useCallback(
    (tagId: string) => {
      if (tagId !== tagHover) {
        setTagHover(tagId);
      }
    },
    [tagHover]
  );

  // focus editTagRef
  useEffect(() => {
    if (selectedTag) {
      editTagRef.current?.focus();
    }
  }, [selectedTag]);

  const handleDeleteTag = (deletedTag: string) => {
    handleOnDeleteTag && handleOnDeleteTag(deletedTag);
    setLocalTags((prevTags) => prevTags.filter((tag) => tag.text !== deletedTag));
    setTimeout(() => {
      setTagHover("");
      setSelectedTagIndex("");
    }, 60000);
  };

  const handleEditSelectedTag = useCallback(() => {
    console.log(`TEST TagItems.tsx 100`);
    if (!selectedTag) {
      return;
    }
    const updatedLocalTags = localTags.map((tag) => {
      if (tag.id === selectedTag.id) {
        return selectedTag;
      }
      return tag;
    });
    handleUpdate && handleUpdate(updatedLocalTags);
    setLocalTags(updatedLocalTags);
    setSelectedTag(null);
  }, [selectedTag, localTags, handleUpdate, setLocalTags]);

  return (
    <Box display={"flex"} flexWrap="wrap" alignItems={"center"}>
      {localTags.slice(0, showAllTags ? localTags.length : maxTagsToShow).map((tag, index) => {
        return (
          <Box key={`tag-item-${tag.id}`} position="relative" mt=".2rem" display={selectedTagIndex === tag.id ? "none" : "block"}>
            <Tag
              maxWidth={"14rem"}
              isTruncated
              className="ch-tag"
              size={size}
              px={selectedTag?.id === tag.id ? "0" : "0.4rem"}
              py={selectedTag?.id === tag.id ? "0" : "0.1rem"}
              mr="0.4rem"
              backgroundColor={handleUpdate && selectedTag?.id === tag.id ? tagEditingBg : tagBackgroundColor(tagType, tag.id)}
              color={tagTextColor}
              borderColor={tagBackgroundColor(tagType, tag.id)}
              borderWidth="0.15rem"
              borderRadius="full"
              cursor={allowUpdate ? "text" : "pointer"}
              onMouseEnter={() => {
                onHandleMouseEnter(`${index}-${tag}`);
              }}
              onMouseLeave={() => {
                setTagHover("");
              }}
              onClick={(evt) => {
                if (allowUpdate) {
                  setSelectedTag({ id: tag.id, text: tag.text });
                } else if (onClickTag) {
                  onClickTag(tag.text);
                  evt.stopPropagation();
                } else if (allowFilter) {
                  setSelectedTags(selectedTags.length > 0 ? [] : [tag.text]);
                  evt.stopPropagation();
                }
              }}>
              {handleUpdate && selectedTag?.id === tag.id ? (
                <Stack direction="row" width="100%" maxWidth="14rem" justifyContent={"space-between"}>
                  <Input
                    borderRadius="10px!important"
                    ref={editTagRef}
                    value={selectedTag.text}
                    onChange={({ target: { value } }) => setSelectedTag({ id: tag.id, text: value })}
                    type="text"
                    size={size}
                    border="none"
                    height="1.2rem"
                    isTruncated
                    paddingInlineEnd="0!important"
                    onKeyPress={(evt) => {
                      if (delimiters.includes(evt.key)) {
                        handleEditSelectedTag();
                      }
                    }}
                  />
                  <Stack direction="row" spacing=".5rem">
                    <Box
                      cursor="pointer"
                      borderRadius="full"
                      backgroundColor={tagEditingBg}
                      onClick={(evt) => {
                        evt.stopPropagation();
                        setSelectedTag(null);
                      }}>
                      <Center>
                        <TagRightIcon
                          height="1.2rem"
                          marginInlineStart="0"
                          backgroundColor="transparent"
                          color={tagTextColor}
                          fontSize={"0.65rem"}
                          as={CloseIcon}
                        />
                      </Center>
                    </Box>
                    <Box
                      cursor="pointer"
                      borderRadius="full"
                      backgroundColor={tagBackgroundColor(tagType)}
                      onClick={(evt) => {
                        evt.stopPropagation();
                        handleEditSelectedTag();
                      }}>
                      <Center>
                        <TagRightIcon
                          height="1.2rem"
                          marginInlineStart="0"
                          backgroundColor="transparent"
                          color={tagTextColor}
                          fontSize={"1rem"}
                          as={ArrowForwardIcon}
                        />
                      </Center>
                    </Box>
                  </Stack>
                </Stack>
              ) : (
                <>
                  <TagLabel fontSize={size} className="ch-tag-label item-tag">
                    {searchPhrase && searchPhrase.length > 0 && tag.id ? (
                      <ECHighlighter searchPhrase={searchPhrase} text={tag.id} />
                    ) : (
                      tag.text
                    )}
                  </TagLabel>
                  {allowDelete && tagHover === `${index}-${tag}` && (
                    <IconButton
                      className="ch-tag-delete"
                      color={tagDeleteColor}
                      variant="outline"
                      backgroundColor={tagEditingBg}
                      rounded="full"
                      aria-label="Delete"
                      size={size}
                      icon={<Icon as={AiOutlineDelete} boxSize="1rem" />}
                      onClick={(evt) => {
                        evt.stopPropagation();
                        handleDeleteTag(tag.text);
                      }}
                      position="absolute"
                      right="-0.1rem"
                      top="-0.5rem"
                    />
                  )}
                </>
              )}
            </Tag>
          </Box>
        );
      })}
      {maxTagsToShow && localTags.length - maxTagsToShow > 0 && (
        <Tag
          maxWidth={"14rem"}
          isTruncated
          onClick={(event) => {
            setShowAllTags(!showAllTags);
            event.stopPropagation();
          }}
          size={size}
          px="0.4rem"
          py="0.1rem"
          mr="0.4rem"
          backgroundColor={suggestionsBgColor}
          color={tagTextColor}
          borderColor={tagBackgroundColor(tagType)}
          borderWidth="0.15rem"
          borderRadius="full"
          cursor="pointer">
          <TagLabel fontSize={size}>{showAllTags ? "show less" : `+ ${localTags.length - maxTagsToShow} more`}</TagLabel>
        </Tag>
      )}
    </Box>
  );
};
