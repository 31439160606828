import React, { useCallback } from "react";
import { Stack, Center } from "@chakra-ui/react";
import { ProjectHeaderImage } from "../../project/projectLandingTileLayouts/components/ProjectHeaderImage";
import { useProjectParams } from "hooks";
import { HiOutlineExternalLink } from "react-icons/hi";
import { SmallActionButton } from "screens/content/contentCanvas/cell/SmallActionButton";
import { useNavigate } from "react-router-dom";

interface ProjectAnalysisPopoverHeaderProps {
  projectId?: string;
  children?: React.ReactNode;
  openProjectButton?: boolean;
}

export const ProjectAnalysisPopoverHeader: React.FC<ProjectAnalysisPopoverHeaderProps> = ({ projectId, children, openProjectButton }) => {
  const { projectFilter, parentRoute } = useProjectParams();
  const navigate = useNavigate();

  const handleNavigate = useCallback(
    (projectId: string) => {
      navigate(`/${parentRoute}/${projectFilter}/${projectId}`);
    },
    [navigate, parentRoute, projectFilter]
  );

  return (
    <Stack direction="row" spacing={0} width="100%" justifyContent="space-between">
      <Stack spacing="0" pt=".3rem">
        <ProjectHeaderImage maxHeight="1.5rem" width="10rem" collectionId={projectId} logoOnly />
      </Stack>
      <Stack direction={"row"} spacing={0} pt=".3rem">
        {children}
        {openProjectButton && (
          <Center>
            <SmallActionButton
              classNames="ch-quadrant-external-link"
              iconTypeName={HiOutlineExternalLink}
              onClick={() => {
                if (projectId) {
                  handleNavigate(projectId);
                }
              }}
              tooltip={"Open project"}
            />
          </Center>
        )}
      </Stack>
    </Stack>
  );
};
