import {
  Button,
  Center,
  Highlight,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useCallback, useContext } from "react";
import { useDispatch } from "react-redux";

import { useCollectionKey, useMainCollectionWorkflowId, useProjectParams } from "./useCollections";
import type { RequestEntities } from "types/charliui";
import { sendMessage } from "state/websocket/operations";
import { useNavigate } from "react-router-dom";
import { useButtonProps } from "./useCommonProps";
import { v4 as uuid } from "uuid";
import { ConversationContext } from "screens/thread/ConversationContext";
import { CloseIcon } from "@chakra-ui/icons";
import { useCompanyStockFromCollectionContentsOrConversation } from "hooks";

export const DeleteCollectionModal = ({ onDeleteCollection }: { onDeleteCollection: () => void }) => {
  const { onDeleteProjectModalClose, isDeleteProjectModalOpen, isDeleteProjectId } = useContext(ConversationContext);
  const collectionName = useCollectionKey(isDeleteProjectId, "name");
  const isActiveProjectInGroup = useCollectionKey(isDeleteProjectId, "isActiveProjectInGroup");
  const primaryButtonProps = useButtonProps("sm", "primary");
  const secondaryButtonProps = useButtonProps("sm", "secondary");
  const companyStockData = useCompanyStockFromCollectionContentsOrConversation(isDeleteProjectId);
  const textColor = useColorModeValue("primary.darkGray", "gray.400");
  const bgColor = useColorModeValue("white", "gray.700");

  return (
    <Modal onClose={onDeleteProjectModalClose} isOpen={isDeleteProjectModalOpen} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader
          bgColor={bgColor}
          color={textColor}
          maxHeight="10rem"
          display="flex"
          flexDirection="column"
          padding={0}
          borderTopRadius="lg">
          <Stack direction={"row"} fontSize="md" textAlign="center" pt="1rem" width="100%" justifyContent={"space-between"} px="1.5rem">
            <Text fontWeight={"semibold"} fontSize="lg">
              Confirm Delete
            </Text>
            <Center>
              <CloseIcon cursor={"pointer"} boxSize={".8rem"} onClick={onDeleteProjectModalClose} />
            </Center>
          </Stack>
        </ModalHeader>
        <ModalBody fontSize={"sm"} pt="1rem">
          <Text fontWeight="normal" color={textColor}>
            <Highlight
              query={collectionName?.toUpperCase() || ""}
              styles={{ py: "1", whiteSpace: "normal", fontWeight: "bold", color: textColor }}>
              {isActiveProjectInGroup
                ? `Please confirm that you would like to delete ${
                    companyStockData?.name?.toUpperCase() || collectionName?.toUpperCase() || "this project"
                  }. This can not be undone.`
                : `Please confirm that you would like to delete this version from the ${
                    companyStockData?.name?.toUpperCase() || collectionName?.toUpperCase()
                  } project. This can not be undone.`}
            </Highlight>
          </Text>
        </ModalBody>
        <ModalFooter>
          <Stack direction="row" spacing="1rem">
            <Button {...secondaryButtonProps} onClick={onDeleteProjectModalClose}>
              Cancel
            </Button>
            <Button className="ch-delete-project-confirm" {...primaryButtonProps} onClick={onDeleteCollection}>
              Delete
            </Button>
          </Stack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export const useDeleteCollection = () => {
  const { projectId } = useProjectParams();
  const { onDeleteProjectModalClose, isDeleteProjectId, navigatePath, setNavigatePath } = useContext(ConversationContext);
  const workflowId = useMainCollectionWorkflowId(isDeleteProjectId);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onDeleteCollection = useCallback(() => {
    if (!isDeleteProjectId || !workflowId) {
      return;
    }

    const entities: RequestEntities = [];

    entities.push({ entity: "collection_id", value: isDeleteProjectId });
    entities.push({ entity: "workflow_id", value: workflowId });

    dispatch(
      sendMessage({
        conversationId: uuid(),
        intent: "/cancel_project",
        entities: entities,
      })
    );

    if (navigatePath) {
      navigate(navigatePath);
      setNavigatePath(undefined);
    }
    if (!navigatePath && projectId) {
      navigate(-1);
    }

    onDeleteProjectModalClose();
  }, [isDeleteProjectId, workflowId, dispatch, navigatePath, projectId, onDeleteProjectModalClose, navigate, setNavigatePath]);

  return {
    onDeleteCollection,
  };
};
