import { Button, Stack, useColorModeValue } from "@chakra-ui/react";
import { useTags } from "hooks";
import React, { useImperativeHandle, useMemo } from "react";
import uniq from "lodash/uniq";

interface Props {
  findText: string;
  setWidth?: string;
  setLeftMargin?: string;
  setTopOffset?: string;
  onSuggestionClick: (text: string) => void;
  selectedSuggestionIndex: number | false;
  setSelectedSuggestionIndex: (value: number | false) => void;
}

export type InteractionBarFindInputTagAutocompleteRef = {
  setSuggestionFocus: (direction: "up" | "down") => number | false;
  applySelectedSuggestion: () => void;
};

const MAX_SUGGESTIONS = 6;

export const InteractionBarFindInputTagAutocomplete = React.forwardRef<InteractionBarFindInputTagAutocompleteRef, Props>(
  (props: Props, ref: React.Ref<InteractionBarFindInputTagAutocompleteRef>) => {
    const { findText, onSuggestionClick, selectedSuggestionIndex, setSelectedSuggestionIndex } = props;
    const selectedBgColor = useColorModeValue("gray.200", "gray.600");
    const suggestionsBgColor = useColorModeValue("white", "gray.700");
    const suggestionsBgColorHover = useColorModeValue("gray.50", "gray.600");
    const bgColor = useColorModeValue("white", "gray.600");
    const textColor = useColorModeValue("gray.800", "gray.100");
    const { tags: rawTags } = useTags({ hideDefaultTags: true });
    const filteredTags = useMemo(() => {
      if (findText.trim().length > 0 && findText.slice(-1) === " ") {
        return [];
      } else if (findText.trim() === "") {
        return rawTags;
      } else {
        const incompleteTag = (findText.split(" ").reverse()[0] || "").replace("#", "").toLowerCase();
        const isEmpty = incompleteTag === "" || incompleteTag.slice(-1) === " ";
        const usedTags = findText.split("#").map((tag) => tag.split(" ")[0].toLowerCase());

        return rawTags.reduce((accum, tag) => {
          if ((isEmpty || tag.toLowerCase().startsWith(incompleteTag)) && !usedTags.includes(tag.toLowerCase())) {
            accum.push(tag);
          }
          return accum;
        }, [] as string[]);
      }
    }, [findText, rawTags]).slice(0, MAX_SUGGESTIONS);
    const availableSuggestions = filteredTags.length;

    useImperativeHandle(ref, () => ({
      setSuggestionFocus: (direction: "up" | "down") => {
        const focusChangeToApply: number | false = (() => {
          if (availableSuggestions === 0) return false;

          switch (direction) {
            case "up":
              if (selectedSuggestionIndex === false || selectedSuggestionIndex === 0) {
                return false;
              }
              return selectedSuggestionIndex - 1;
            case "down":
              if (selectedSuggestionIndex === false) {
                return 0;
              }
              return Math.min(selectedSuggestionIndex + 1, availableSuggestions - 1);
          }
        })();

        setSelectedSuggestionIndex(focusChangeToApply);
        return focusChangeToApply;
      },
      applySelectedSuggestion: () => {
        if (availableSuggestions > 0) {
          onSuggestionClick(filteredTags[selectedSuggestionIndex || 0]);
        }
      },
    }));

    if (filteredTags.length > 0) {
      return (
        <Stack
          top={props.setTopOffset}
          zIndex="3"
          spacing="0"
          borderRadius="sm"
          backgroundColor={bgColor}
          boxShadow="xl"
          position={"absolute"}>
          {uniq(filteredTags).map((tag, index) => {
            return (
              <Button
                size="sm"
                onMouseEnter={() => {
                  setSelectedSuggestionIndex(index);
                }}
                borderRadius="none"
                backgroundColor={selectedSuggestionIndex === index ? suggestionsBgColorHover : suggestionsBgColor}
                _hover={{ backgroundColor: selectedBgColor }}
                className="ch-find-input-tag-suggestion"
                key={tag}
                justifyContent="flex-start"
                variant="ghost"
                height="2rem"
                textColor={textColor}
                fontWeight="500"
                overflow="hidden"
                onClick={(ev) => {
                  ev.preventDefault();
                  ev.stopPropagation();
                  onSuggestionClick(tag);
                }}>
                {tag}
              </Button>
            );
          })}
        </Stack>
      );
    } else {
      return <React.Fragment />;
    }
  }
);
