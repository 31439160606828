import { Box, Center, Stack, useTheme } from "@chakra-ui/react";
import { useCollectionKey, useContents, useProjectGroupIdToGetMetaDataIds } from "hooks";
import { useCollectionMetadataWithTagId } from "hooks";
import type { FunctionComponent } from "react";
import { useMemo } from "react";
import React from "react";
import { ContentDetailImage } from "screens/collection/components/ContentDetailImage";
import { TextOverflowTooltip } from "screens/landing/components/TextOverflowTooltip";
import { ProjectThumbnailsGrid } from "./ProjectThumbnailGrid";

interface Props {
  projectType?: string;
  width?: string;
  maxHeight?: string;
  metadataIds?: string[];
  projectName?: string;
  projectGroupId?: string;
  collectionId?: string;
  numberOfThumbnails?: number;
  logoOnly?: boolean;
  titleStyle?: React.CSSProperties;
}

const NonDDProjectThumbnailGrid = ({
  collectionId,
  calculatedWidth,
  numberOfThumbnails = 2,
}: {
  collectionId: string;
  calculatedWidth: string;
  numberOfThumbnails?: number;
}) => {
  const collectionMetadataIds = useCollectionKey(collectionId, "metadataIds");
  const slicedMetadataIds = useMemo(
    () => (collectionMetadataIds ? collectionMetadataIds?.slice(0, numberOfThumbnails) : []),
    [collectionMetadataIds, numberOfThumbnails]
  );

  useContents(slicedMetadataIds, { refreshFromNetwork: true });

  return (
    <Box width={calculatedWidth}>
      <ProjectThumbnailsGrid
        metadataIds={slicedMetadataIds}
        contentCount={collectionMetadataIds && collectionMetadataIds?.length + 1 - numberOfThumbnails}
      />
    </Box>
  );
};

export const ProjectHeaderImage: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  projectType,
  width,
  maxHeight = "2rem",
  metadataIds,
  projectName,
  projectGroupId,
  collectionId,
  numberOfThumbnails,
  logoOnly,
  titleStyle,
}) => {
  const contentIdOfCompanyLogoFromGroup = useProjectGroupIdToGetMetaDataIds(projectGroupId);
  const collectionMetadataIds = useCollectionKey(collectionId, "metadataIds");
  const collectionName = useCollectionKey(collectionId, "name");
  const contentIdOfCompanyLogo = useCollectionMetadataWithTagId(metadataIds || collectionMetadataIds || [], "company_logo");
  const contentIdOfProjectLandingThumbnail = useCollectionMetadataWithTagId(
    metadataIds || collectionMetadataIds || [],
    "project_landing_thumbnail"
  );
  const calculatedWidth = width ? width : "8rem";
  const theme = useTheme();

  // if logoOnly is true, only return the company logo and break out of the function
  if (logoOnly) {
    if (contentIdOfCompanyLogo) {
      return (
        <Center width={calculatedWidth}>
          <Box className="ch-project-company-logo" width="100%">
            <ContentDetailImage contentId={contentIdOfCompanyLogo} maxHeight={maxHeight} />
          </Box>
        </Center>
      );
    } else {
      return (
        <Stack spacing={"0.3rem"} direction="row" className="ch-project-default-icon" width={calculatedWidth}>
          <Center>
            <TextOverflowTooltip
              className="ch-project-landing-default-tile-title"
              label={projectName || collectionName || ""}
              style={{ fontSize: "1.4rem", fontWeight: "bold", fontFamily: theme.fonts.logo, ...titleStyle }}
            />
          </Center>
        </Stack>
      );
    }
  }

  if (contentIdOfProjectLandingThumbnail) {
    return (
      <Box width={calculatedWidth}>
        <ContentDetailImage contentId={contentIdOfProjectLandingThumbnail} maxHeight={maxHeight} />
      </Box>
    );
  }

  if (contentIdOfCompanyLogo) {
    return (
      <Center width={calculatedWidth}>
        <Box className="ch-project-company-logo" width="100%">
          <ContentDetailImage contentId={contentIdOfCompanyLogo} maxHeight={maxHeight} />
        </Box>
      </Center>
    );
  } else if (contentIdOfCompanyLogoFromGroup) {
    return (
      <Center width={calculatedWidth}>
        <Box className="ch-project-company-logo" width="100%">
          <ContentDetailImage contentId={contentIdOfCompanyLogoFromGroup} maxHeight={maxHeight} />
        </Box>
      </Center>
    );
  } else if (collectionId && projectType !== "due_diligence") {
    return (
      <NonDDProjectThumbnailGrid collectionId={collectionId} calculatedWidth={calculatedWidth} numberOfThumbnails={numberOfThumbnails} />
    );
  } else {
    return (
      <Box className="ch-project-default-icon" width={calculatedWidth}>
        <TextOverflowTooltip
          className="ch-project-landing-default-tile-title"
          label={projectName ?? ""}
          style={{ fontSize: "1.4rem", fontWeight: "bold", fontFamily: theme.fonts.logo, ...titleStyle }}
        />
      </Box>
    );
  }
};
