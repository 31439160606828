import { combineReducers } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import type { AnyAction, CombinedState } from "@reduxjs/toolkit";
import sessionReducer from "./session/reducer";
import billingReducer from "./billing/reducer";
import conversationReducer from "./conversation/reducer";
import websocketReducer from "./websocket/reducer";
import selectionReducer from "./selection/reducer";
import commandReducer from "./command/reducer";
import flagReducer from "./flags/reducer";
import entitlementReducer from "./entitlements/reducer";
import navigationReducer from "./navigation/reducer";
import notificationReducer from "./notification/reducer";
import collectionReducer from "./collection/reducer";
import userPreferenceReducer from "./userPreference/reducer";
import homeReducer from "./home/reducer";
import shareHistoryReducer from "./shareHistory/reducer";
import integrationsReducer from "./integrations/reducer";
import contentReducer from "./content/reducer";
import configuredWorkflowReducer from "./configuredWorkflow/reducer";
import workflowReducer from "./workflow/reducer";
import checkpointReducer from "./checkpoints/reducer";
import usersReducer from "./users/reducer";
import meetingSyncReducer from "./meetings/reducer";
import taskReducer from "./task/reducer";
import billingPlansReducer from "./billingPlans/reducer";
import systemPreferenceReducer from "./systemPreference/reducer";
import organizationReducer from "./organization/reducer";
import memberOrganizationsReducer from "./memberOrganizations/reducer";
import reportTemplatesReducer from "./reportTemplates/reducer";
import entityConfigurationsReducer from "./entityConfigurations/reducer";
import memorizedClarificationConfigurationsReducer from "./memorizedClarificationConfig/reducer";
import configReducer from "./config/reducer";
import workflowStatsReducer from "./workflowStats/reducer";
import informationExtractionConfigReducer from "./informationExtractionConfig/reducer";
import organizationsPreferenceReducer from "./organizationPreference/reducer";
import charliActivitiesReducer from "./charliActivities/reducer";
import localforage from "localforage";
import suggestedQuestionsReducer from "./suggestedQuestions/reducer";
import usageReducer from "./usage/reducer";
import newsInformationReducer from "./newsInformation/reducer";
import contextCoordinatesReducer from "./contextCoordinates/reducer";
import subscriptionsReducer from "./subscription/reducer";
import financialMarketReducer from "./financialMarket/reducer";
import collectionMetadataReducer from "./collectionMetadata/reducer";
import copiesReducer from "./copies/reducer";
import milestonesReducer from "./milestones/reducer";
import workflowsSchedulesReducers from "./workflowSchedules/reducer";

const checkpointPersistConfig = {
  key: "checkpoint",
  storage: localforage,
};

const conversationPersistConfig = {
  key: "conversation",
  storage: localforage,
  whitelist: ["hasInitialSyncCompleted"],
};

const contentPersistConfig = {
  key: "content",
  storage: localforage,
  whitelist: ["recentContent"],
};

const collectionPersistConfig = {
  key: "collection",
  storage: localforage,
};

const charliActivitiesPersistConfig = {
  key: "charliActivities",
  storage: localforage,
};

const workflowPersistConfig = {
  key: "workflow",
  storage: localforage,
};

const contextCoordinatesPersistConfig = {
  key: "contextCoordinates",
  storage: localforage,
};

const collectionMetadataPersistConfig = {
  key: "collectionMetadata",
  storage: localforage,
};

const milestonesPersistConfig = {
  key: "milestones",
  storage: localforage,
};

// NOTE: any new reducer needs to have a flush() method to reset the state
// and needs to be added in src/state/middleware/sessionMiddleware.ts
const appReducer = combineReducers({
  session: sessionReducer,
  billing: billingReducer,
  conversation: persistReducer(conversationPersistConfig, conversationReducer),
  websocket: websocketReducer,
  selection: selectionReducer,
  command: commandReducer,
  flag: flagReducer,
  entitlement: entitlementReducer,
  navigation: navigationReducer,
  notification: notificationReducer,
  collection: persistReducer(collectionPersistConfig, collectionReducer),
  userPreference: userPreferenceReducer,
  systemPreference: systemPreferenceReducer,
  home: homeReducer,
  shareHistory: shareHistoryReducer,
  integrations: integrationsReducer,
  content: persistReducer(contentPersistConfig, contentReducer),
  configuredWorkflow: configuredWorkflowReducer,
  workflow: persistReducer(workflowPersistConfig, workflowReducer),
  checkpoint: persistReducer(checkpointPersistConfig, checkpointReducer),
  users: usersReducer,
  meetings: meetingSyncReducer,
  tasks: taskReducer,
  billingPlans: billingPlansReducer,
  organizations: organizationReducer,
  memberOrganizations: memberOrganizationsReducer,
  reportTemplate: reportTemplatesReducer,
  entityConfigurations: entityConfigurationsReducer,
  memorizedClarificationConfigurations: memorizedClarificationConfigurationsReducer,
  configMap: configReducer,
  workflowsStats: workflowStatsReducer,
  informationExtractionConfig: informationExtractionConfigReducer,
  organizationsPreference: organizationsPreferenceReducer,
  charliActivities: persistReducer(charliActivitiesPersistConfig, charliActivitiesReducer),
  suggestedQuestions: suggestedQuestionsReducer,
  newsInformation: newsInformationReducer,
  usage: usageReducer,
  contextCoordinates: persistReducer(contextCoordinatesPersistConfig, contextCoordinatesReducer),
  subscriptions: subscriptionsReducer,
  financialMarket: financialMarketReducer,
  collectionMetadata: persistReducer(collectionMetadataPersistConfig, collectionMetadataReducer),
  copies: copiesReducer,
  milestones: persistReducer(milestonesPersistConfig, milestonesReducer),
  workflowsSchedules: workflowsSchedulesReducers,
});

const rootReducer = (state: CombinedState<RootState> | undefined, action: AnyAction) => {
  return appReducer(state, action);
};

export type RootState = ReturnType<typeof appReducer>;
export default rootReducer;
