import { Box, Icon, Tooltip, useColorModeValue, Center, Stack, Text, Button } from "@chakra-ui/react";
import type { FunctionComponent } from "react";
import { useContext } from "react";
import React from "react";
import { AiOutlineDelete } from "react-icons/ai";
import { TypingIndicator } from "screens/thread/components/cells/components";
import { OptionsMenuItem } from "../../components/OptionsMenuItem";
import { ConversationContext } from "screens/thread/ConversationContext";
import { useAddToCharliContext } from "screens/panels/addToCharli/AddToCharliWizard/AddToCharliProvider";

interface Props {
  size?: string;
  isMenuItem?: boolean;
  isDeletingCollection?: boolean;
  onOpenConfirmation: () => void;
  label?: string;
  className?: string;
  projectId: string;
  navigatePath?: string;
}

export const ProjectDeleteButton: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  size = "1rem",
  isMenuItem,
  isDeletingCollection = false,
  onOpenConfirmation,
  label = "Delete Project",
  className = "ch-delete-project",
  projectId,
  navigatePath,
}) => {
  const tileBorderColor = useColorModeValue("gray.500", "gray.600");
  const additionalThumbnailCountTextColor = useColorModeValue("gray.500", "gray.400");
  const { setIsDeleteProjectId, setNavigatePath } = useContext(ConversationContext);
  const { onPortfolioSettingsOpen } = useAddToCharliContext();

  const onHandleDeleteProject = () => {
    setIsDeleteProjectId(projectId);
    onOpenConfirmation();
    navigatePath && setNavigatePath(navigatePath);
  };

  return (
    <>
      {isMenuItem ? (
        <OptionsMenuItem
          menuLabel={label}
          className={className}
          onClick={(event) => {
            event.stopPropagation();
            onHandleDeleteProject();
          }}
          isDisabled={isDeletingCollection}
        />
      ) : isDeletingCollection ? (
        <Stack direction="row" spacing="1rem">
          <Text color={additionalThumbnailCountTextColor} fontSize={"xs"}>
            Deleting Project
          </Text>
          <TypingIndicator size="small" />
        </Stack>
      ) : (
        <Box
          className={className}
          width="unset"
          cursor="pointer"
          backgroundColor="transparent"
          borderRadius="md"
          onClick={(event) => {
            event.stopPropagation();
            onHandleDeleteProject();
          }}>
          {label ? (
            <Button
              className="ch-open-portfolio-delete-button"
              aria-label="Show/Hide Settings"
              colorScheme="red"
              size="sm"
              onClick={() => {
                onPortfolioSettingsOpen();
              }}>
              Delete Portfolio
            </Button>
          ) : (
            <Tooltip aria-label="" label={label} placement="top" hasArrow>
              <Center>
                <Icon as={AiOutlineDelete} color={tileBorderColor} boxSize={size} _hover={{ color: additionalThumbnailCountTextColor }} />
              </Center>
            </Tooltip>
          )}
        </Box>
      )}
    </>
  );
};
