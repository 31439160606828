import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useCollections, useProjectParams } from "hooks/useCollections";
import type { RequestEntities } from "types/charliui";
import { sendMessage } from "state/websocket/operations";
import { useButtonProps } from "hooks/useCommonProps";
import { Select } from "chakra-react-select";
import { useNavigate } from "react-router-dom";
import { TypingIndicator } from "screens/thread/components/cells/components";
import { useCompanyStockFromCollectionContentsOrConversation } from "hooks";

export const MoveProjectModal = ({
  onClose,
  isOpen,
  collectionId,
  conversationId,
}: {
  onClose: () => void;
  isOpen: boolean;
  collectionId: string;
  conversationId: string;
}) => {
  const { projectFilter, projectId } = useProjectParams();
  const primaryButtonProps = useButtonProps("sm", "primary");
  const secondaryButtonProps = useButtonProps("sm", "secondary");
  const [toPortfolioId, setToPortfolioId] = useState<string | undefined>(undefined);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const collections = useCollections();
  const [hasProjectMoved, setHasProjectMoved] = useState(false);
  const companyStockData = useCompanyStockFromCollectionContentsOrConversation(collectionId);
  const textColor = useColorModeValue("primary.darkGray", "gray.400");

  const portfolioCollections = useMemo(
    () =>
      collections.filter(
        (collection) =>
          collection.collectionType === "portfolio" && collection.id !== projectFilter && !collection.metadataIds.includes(collectionId)
      ),
    [collections, projectFilter, collectionId]
  );

  const maybeCurrentProjectPortfolioId = useMemo(() => {
    return collections.find((collection) => collection.collectionType === "portfolio" && collection.metadataIds.includes(collectionId))?.id;
  }, [collectionId, collections]);

  const onMoveCollection = useCallback(() => {
    if (!collectionId || !toPortfolioId) return;

    const entities: RequestEntities = [
      { entity: "collection_id", value: collectionId },
      { entity: "to_portfolio_id", value: toPortfolioId },
    ];

    if (maybeCurrentProjectPortfolioId) {
      entities.push({ entity: "from_portfolio_id", value: maybeCurrentProjectPortfolioId });
    }

    dispatch(
      sendMessage({
        conversationId,
        intent: "/move_project",
        entities,
      })
    );

    setHasProjectMoved(true);
  }, [collectionId, toPortfolioId, maybeCurrentProjectPortfolioId, dispatch, conversationId]);

  useEffect(() => {
    if (hasProjectMoved) {
      const toProjectPortfolioProjectCount = collections.find(
        (collection) => collection.collectionType === "portfolio" && collection.id === toPortfolioId
      );

      const isProjectInToPortfolio = toProjectPortfolioProjectCount?.metadataIds.includes(collectionId);

      if (isProjectInToPortfolio) {
        onClose();
        setHasProjectMoved(false);
      }
      if (projectId) {
        navigate(`/portfolios/${toPortfolioId}/${collectionId}`);
      }
    }
  }, [hasProjectMoved, collections, toPortfolioId, onClose, collectionId, projectFilter, navigate, projectId]);

  return (
    <Modal onClose={onClose} isOpen={isOpen} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader color={textColor}>Move Project</ModalHeader>
        <ModalCloseButton />
        <ModalBody fontSize="sm" color={textColor}>
          <Stack>
            <Text>Please select a portfolio you would like to move {companyStockData?.name?.toUpperCase() || "this project"} to.</Text>
            <Text>
              {projectFilter === "due_diligence"
                ? "This will move your project to the selected portfolio but you will still be able to see it in the All AI Due Diligence list"
                : "The project will be removed from this portfolio and added to the selected portfolio."}
            </Text>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <Stack spacing="2rem" width="100%">
            <Select
              classNamePrefix="ch-move-portfolios"
              size="sm"
              isDisabled={hasProjectMoved}
              placeholder="Select portfolio"
              onChange={(value) => value && setToPortfolioId(value.value)}
              options={portfolioCollections.map((collection) => ({
                class: "ch-move-project-portfolio-select-option",
                label: collection.name,
                value: collection.id,
              }))}
            />
            <Stack direction="row" spacing="1rem" justifyContent="flex-end">
              <Button {...secondaryButtonProps} isDisabled={hasProjectMoved} onClick={onClose}>
                Cancel
              </Button>
              <Button className="ch-move-project-confirm" {...primaryButtonProps} onClick={onMoveCollection} isDisabled={!toPortfolioId}>
                {hasProjectMoved ? <TypingIndicator size="small" /> : "Move Project"}
              </Button>
            </Stack>
          </Stack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
