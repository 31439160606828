import React, { memo } from "react";
import { Badge, Stack, Text, useColorModeValue } from "@chakra-ui/react";

export const DefaultChartColors = ["#72acad", "#ad729d", "#8f97a4", "#72ad91", "#6482C0"] as const;

export function subtractDays(days: number): Date {
  const date = new Date();
  date.setDate(date.getDate() - days);
  return date;
}

export type ChartStatus =
  | "succeeded"
  | "complete"
  | "high"
  | "high risk"
  | "0-5"
  | "ai_generated_cost"
  | "queued"
  | "in_progress"
  | "in progress"
  | "5-10"
  | "medium"
  | "medium risk"
  | "denied_intent_confirmation"
  | "failed"
  | "incomplete"
  | "human_resource_cost"
  | "error"
  | "clarification_needed"
  | "clarification"
  | "low"
  | "low risk"
  | "10+";

export const chartItemColor = (status: string): string => {
  switch (status.toLowerCase()) {
    case "succeeded":
      return "#72ad91";
    case "complete":
    case "high":
    case "high risk":
    case "0-5":
    case "ai_generated_cost":
      return "#72acad";
    case "queued":
      return "#ffdb7f";
    case "in_progress":
    case "in progress":
      return "#ecba5e";
    case "5-10":
    case "medium":
    case "medium risk":
      return "#6482C0";
    case "denied_intent_confirmation":
      return "#ffd466";
    case "failed":
    case "incomplete":
    case "human_resource_cost":
      return "#ff9b7f";
    case "error":
      return "#ff9d81";
    case "clarification_needed":
    case "clarification":
      return "#80B9E1";
    case "low":
    case "low risk":
    case "10+":
      return "#A0AEC0";
    default:
      return "#8295b5";
  }
};

export const getShadesFromHexColor = (hex: string, numColors: number): { color: string }[] => {
  if (!hex.startsWith("#") || hex.length !== 7) {
    console.warn("Invalid hex color provided");
    return [];
  }

  const hexWithoutHash = hex.substring(1);
  const [r, g, b] = hexWithoutHash.match(/.{2}/g)?.map((c) => parseInt(c, 16)) ?? [0, 0, 0];

  const colorStep = 16;
  const maxColorValue = 255;

  return Array.from({ length: numColors }, (_, i) => {
    const newR = Math.min(r + colorStep * i, maxColorValue);
    const newG = Math.min(g + colorStep * i, maxColorValue);
    const newB = Math.min(b + colorStep * i, maxColorValue);

    const color = `#${newR.toString(16).padStart(2, "0")}${newG.toString(16).padStart(2, "0")}${newB.toString(16).padStart(2, "0")}`;
    return { color };
  });
};

interface RenderCustomizedLabelProps {
  cx: number;
  cy: number;
  midAngle: number;
  innerRadius: number;
  outerRadius: number;
  percent: number;
  index: string;
}

export const renderCustomizedLabel = memo(({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }: RenderCustomizedLabelProps) => {
  const RADIAN = Math.PI / 180;
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return React.createElement(
    "text",
    {
      id: `pie-segment-${index}`,
      cursor: "pointer",
      style: { pointerEvents: "none" },
      x,
      y,
      fontSize: "13px",
      fill: "white",
      textAnchor: "middle",
      dominantBaseline: "central",
    },
    `${(percent * 100).toFixed(0)}%`
  );
});

renderCustomizedLabel.displayName = "RenderCustomizedLabel";

interface TooltipPayload {
  state?: string;
  label: string;
  value: number;
  valueSuffix?: string;
}

interface CustomTooltipProps {
  active?: boolean;
  payload?: Array<{ payload: TooltipPayload }>;
  color?: string;
  showState?: boolean;
}

export const CustomTooltip = memo(({ active, payload, color, showState }: CustomTooltipProps) => {
  const tooltipBgColor = useColorModeValue("white", "gray.600");
  const payloadData = payload?.[0]?.payload;

  if (!active || !payloadData) {
    return null;
  }

  return React.createElement(
    Stack,
    {
      bgColor: tooltipBgColor,
      fontSize: "xs",
      boxShadow: "none",
      maxWidth: "14rem",
      p: ".5rem",
      borderRadius: "md",
    },
    [
      showState && payloadData.state && React.createElement(Text, { key: "state" }, payloadData.state),
      React.createElement(Stack, { direction: "row", key: "content" }, [
        React.createElement(
          Badge,
          {
            color: color || tooltipBgColor,
            bgColor: payloadData.state ? chartItemColor(payloadData.state) : "#8295b5",
            key: "badge",
          },
          payloadData.label
        ),
        React.createElement(Text, { key: "value" }, `${payloadData.value}${payloadData.valueSuffix ? ` ${payloadData.valueSuffix}` : ""}`),
      ]),
    ]
  );
});

CustomTooltip.displayName = "CustomTooltip";

interface AreaChartTooltipPayload {
  name: string;
  dataKey: string;
  value: number;
  valueSuffix?: string;
}

interface CustomTooltipAreaChartProps {
  active: boolean;
  payload?: AreaChartTooltipPayload[];
  prefix?: string;
  baseColor?: string;
}

export const CustomTooltipAreaChart = memo(({ active, payload, prefix, baseColor }: CustomTooltipAreaChartProps) => {
  const tooltipBgColor = useColorModeValue("white", "gray.600");

  if (!active || !payload?.length) {
    return React.createElement(
      Stack,
      {
        bgColor: tooltipBgColor,
        fontSize: "xs",
        boxShadow: "none",
        maxWidth: "14rem",
        width: "100%",
        p: ".5rem",
        borderRadius: "md",
      },
      React.createElement(Text, null, "No Data Available")
    );
  }

  const payloadData = payload[0];
  const orderPayloadByName = [...payload].sort((a, b) => a.name.localeCompare(b.name));

  return React.createElement(
    Stack,
    {
      bgColor: tooltipBgColor,
      fontSize: "xs",
      boxShadow: "none",
      maxWidth: "14rem",
      width: "100%",
      p: ".5rem",
      borderRadius: "md",
    },
    [
      React.createElement(Text, { isTruncated: true, width: "100%", key: "title" }, payloadData.name),
      ...orderPayloadByName.map((item) =>
        React.createElement(
          Stack,
          {
            direction: "row",
            key: item.dataKey,
            justifyContent: "space-between",
            alignItems: "center",
          },
          [
            React.createElement(
              Badge,
              {
                isTruncated: true,
                color: tooltipBgColor,
                bgColor: baseColor || (item.name && chartItemColor(item.name)),
                key: "badge",
              },
              item.name
            ),
            React.createElement(
              Text,
              { width: "4rem", textAlign: "right", key: "value" },
              `${prefix || ""}${item.value}${item.valueSuffix ? ` ${item.valueSuffix}` : ""}`
            ),
          ]
        )
      ),
    ]
  );
});

CustomTooltipAreaChart.displayName = "CustomTooltipAreaChart";
