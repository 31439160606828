import { createSlice } from "@reduxjs/toolkit";
import type { WorkflowSchedule } from "api/workflowSchedules/models/WorkflowSchedule";
import { downloadWorkflowsSchedules } from "./operations";

interface WorkflowSchedulesState {
  isLoading: boolean;
  workflowsSchedulesById: Record<string, WorkflowSchedule>;
  workflowsSchedulesIds: string[];
}

const initialState: WorkflowSchedulesState = {
  isLoading: false,
  workflowsSchedulesById: {},
  workflowsSchedulesIds: [],
};

export const { actions, reducer } = createSlice({
  name: "workflowSchedules",
  initialState,
  reducers: {
    flush() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(downloadWorkflowsSchedules.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(downloadWorkflowsSchedules.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(downloadWorkflowsSchedules.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.workflowsSchedulesById = payload.reduce((acc, workflowSchedule) => {
        return { ...acc, [workflowSchedule.id]: workflowSchedule };
      }, {});
      state.workflowsSchedulesIds = payload.map((workflowSchedule) => workflowSchedule.id);
    });
  },
});

export default reducer;
