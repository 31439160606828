import { prependProxyBaseUrl, request } from "api/request";
import { GetWorkflowSchedules } from "./models/requests/GetWorkflowSchedules";

const WORKFLOW_SCHEDULING_BASE_ENDPOINT = prependProxyBaseUrl("/api/workflow-schedules");

export const getWorkflowsSchedules = async (): Promise<GetWorkflowSchedules> => {
  const response = await request().url(WORKFLOW_SCHEDULING_BASE_ENDPOINT).get().json();

  const validate = GetWorkflowSchedules.validate(response);

  if (validate.success) {
    return validate.value;
  } else {
    console.error(validate.message);
    throw new Error(validate.message);
  }
};
