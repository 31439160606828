import React from "react";
import type { FunctionComponent } from "react";
import { Tooltip, Box, Center, useColorModeValue } from "@chakra-ui/react";
import { getConfidenceColor } from "hooks/useStats";

interface Props {
  scoreTooltip?: string;
  scoreBgColor?: string;
  score?: number | "--";
  onClick?: () => void;
}

export const SmallActionConfidenceScoreBadge: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  scoreTooltip,
  scoreBgColor,
  score,
  onClick,
}) => {
  const iconBgColor = useColorModeValue("100", "500");
  const iconColor = useColorModeValue("500", "900");

  return (
    <Tooltip aria-label="" label={scoreTooltip} placement="left" hasArrow>
      <Center>
        <Box
          cursor={onClick ? "pointer" : "default"}
          onClick={onClick}
          borderRadius={"5px"}
          px="6px"
          py="3px"
          className="ch-qa-result-score"
          fontSize={"sm"}
          fontWeight="normal"
          borderColor={scoreBgColor}
          bg={typeof score === "number" ? `${getConfidenceColor(score)}.${iconBgColor}` : scoreBgColor}
          color={typeof score === "number" ? `${getConfidenceColor(score)}.${iconColor}` : "white"}>
          {score}
        </Box>
      </Center>
    </Tooltip>
  );
};
