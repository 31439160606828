import type { FunctionComponent } from "react";
import React, { useMemo, useRef } from "react";
import { Box, useColorMode, useTheme } from "@chakra-ui/react";
import Marquee from "react-fast-marquee";
import { StockIndicator } from "screens/landing/tabs/project/projectLandingTileLayouts/components/StockIndicator";
import { useFinancialMarket } from "hooks/useFinancialMarket";

export const ScrollingTickers: FunctionComponent = () => {
  const tickerData = useFinancialMarket();
  const tickersRef = useRef<HTMLDivElement>(null);
  const { colorMode } = useColorMode();
  const theme = useTheme();

  const gradientColor = useMemo(() => {
    return colorMode === "dark" ? theme.colors.gray[800] : "rgb(255, 255, 255)";
  }, [colorMode, theme.colors.gray]);

  const stockIndicators = useMemo(
    () =>
      tickerData.map((ticker, index) => (
        <Box className="ch-scrolling-stock-ticker" key={`stock-ticker-${index}`} px=".5rem">
          <StockIndicator
            style={{ cursor: "default" }}
            companyTicker={ticker.stock}
            companyStockTitle={`${ticker.name}: ${ticker.stock.replace(".", "").split(":", 1)}`}
            companyStockExchange={ticker.name}
            companyStockPrice={ticker.price}
            companyStockPercentageChange={ticker.percent_change}
            companyStockMovement={ticker.movement}
            companyStockValueChange={ticker.value_change}
          />
        </Box>
      )),
    [tickerData]
  );
  if (!tickerData.length) return null;

  return (
    <Box ref={tickersRef} width={["100vw", "90vw", "80vw"]}>
      <Marquee speed={25} pauseOnHover autoFill gradient gradientColor={gradientColor}>
        {stockIndicators}
      </Marquee>
    </Box>
  );
};
