import type { FunctionComponent } from "react";
import { useEffect, useState } from "react";
import React from "react";
import { Box, Flex, useColorModeValue } from "@chakra-ui/react";
import { LandingTitle } from "screens/landing/components";

import { NewsTilesSection } from "./NewsTilesSection";
import { HomeRightPanelActivities } from "./HomeRightPanelActivities";

interface Props {
  currentSlide: number;
}

const SIDEBAR_WIDTH = 30;

export const HomeRightPanel: FunctionComponent<Props> = ({ currentSlide = 0 }) => {
  const bgSectionsColor = useColorModeValue("white", "gray.800");
  const [carouselStyle, setCarouselStyle] = useState({});

  useEffect(() => {
    const remValue = currentSlide * SIDEBAR_WIDTH;
    setCarouselStyle({
      transition: "all .5s",
      transform: `translateX(-${remValue}rem)`,
    });
  }, [currentSlide]);

  return (
    <Flex w={`${SIDEBAR_WIDTH}rem`} pos="relative" overflow="hidden" p="1rem">
      <Flex h="100%" w={`${SIDEBAR_WIDTH * 2}rem`} {...carouselStyle}>
        <Box height="100%" width={`${SIDEBAR_WIDTH}rem`} className="ch-home-recent-activity" bgColor={bgSectionsColor}>
          <LandingTitle text="Latest News" tooltip="Hotlinks Coming Soon" underline color={"primary.default"} />
          <NewsTilesSection />
        </Box>
        <HomeRightPanelActivities sidebarWidth={SIDEBAR_WIDTH} />
      </Flex>
    </Flex>
  );
};
