import { Progress, useColorModeValue, Stack, CircularProgress, Button, Center } from "@chakra-ui/react";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useWorkflowProgress, useWorkflowInProgressOrClarificationRequested, useWorkflowActiveTaskWithCount } from "hooks/useCheckpoints";
import { TextOverflowTooltip } from "./TextOverflowTooltip";
import { useWorkflowKey } from "hooks/useWorkflows";
import { useButtonProps } from "hooks";
import { sendMessage } from "state/websocket/operations";
import { useDispatch } from "react-redux";
import { ConversationContext } from "screens/thread/ConversationContext";

interface Props {
  workflowId: string;
  renderCompletedWorkflow?: () => JSX.Element;
  size?: "compact" | "full";
  progressIndicatorStyle?: "bar" | "circle" | "combined";
  appendText?: string;
  align?: "start" | "end";
  showInline?: boolean;
  maxWidth?: string;
  setIsCancelling?: (isCancelling: boolean) => void;
  shouldOpenConversation?: boolean;
  showCancelButton?: boolean;
  isReadOnly?: boolean;
}

export const WorkflowProgress = (props: Props) => {
  const {
    workflowId,
    size,
    showInline,
    progressIndicatorStyle = "bar",
    appendText,
    align = "end",
    maxWidth = "unset",
    setIsCancelling,
    shouldOpenConversation = true,
    showCancelButton = false,
    isReadOnly = false,
  } = props;
  const { percent, completed, total } = useWorkflowProgress(workflowId);
  const activeTaskWithCount = useWorkflowActiveTaskWithCount(workflowId);
  const isInProgress = useWorkflowInProgressOrClarificationRequested(workflowId);
  const progressBgColor = useColorModeValue("gray.300", "gray.700");
  const progressTextColor = useColorModeValue("gray.600", "gray.300");
  const progressColor = useColorModeValue("teal", "blue");
  const progressOpacity = useColorModeValue(".4", ".3");
  const workflowStatus = useWorkflowKey(workflowId, "status");
  const primaryButtonProps = useButtonProps("xs", "primary");
  const isPaused = workflowStatus === "paused";
  const conversationId = useWorkflowKey(workflowId, "conversationId");
  const workflowIntent = useWorkflowKey(workflowId, "intent");
  const dispatch = useDispatch();
  const { setConversationId, onConversationOpen, isConversationOpen } = useContext(ConversationContext);
  const [activeTaskName, setActiveTaskName] = useState("");

  const onCancel = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (!conversationId || isReadOnly) return;
      event.stopPropagation();
      setIsCancelling && setIsCancelling(true);
      dispatch(
        sendMessage({
          conversationId,
          intent: "/cancel",
        })
      );
    },
    [conversationId, dispatch, isReadOnly, setIsCancelling]
  );

  const onClickProgress = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (!conversationId || !shouldOpenConversation || isReadOnly) return;
      event.stopPropagation();
      setConversationId(conversationId);
      !isConversationOpen && onConversationOpen();
    },
    [conversationId, shouldOpenConversation, isReadOnly, setConversationId, isConversationOpen, onConversationOpen]
  );

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    if (!activeTaskWithCount.mostCommonTask) {
      timeout = setTimeout(() => {
        setActiveTaskName("Waiting for next task to start.");
      }, 500);
    } else {
      setActiveTaskName(activeTaskWithCount.mostCommonTask);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [activeTaskWithCount.mostCommonTask]);

  if (!isInProgress) {
    return <React.Fragment />;
  } else {
    return (
      <>
        {(progressIndicatorStyle === "bar" || progressIndicatorStyle === "combined") && (
          <Stack direction="row" zIndex={2} justifyContent="space-between" spacing="1rem" maxWidth={maxWidth} width="100%">
            <Stack
              width="100%"
              direction={showInline ? "row" : "column"}
              textAlign={align}
              spacing={showInline ? "1rem" : "0"}
              justifyContent="space-between"
              onClick={onClickProgress}>
              <Stack direction="row" spacing=".3rem" justifyContent="space-between" width="100%">
                <TextOverflowTooltip
                  label={
                    isPaused
                      ? "Paused temporarily"
                      : `${activeTaskWithCount.count > 1 ? `${activeTaskWithCount.count} ` : ""}${activeTaskName}${
                          activeTaskWithCount.remainingTasksCount > 1 ? ` and ${activeTaskWithCount.remainingTasksCount} more tasks` : ""
                        }`
                  }
                  fontSize={size === "compact" ? "11px" : "sm"}
                  style={{
                    paddingBottom: "0",
                    width: "100%",
                    textAlign: "start",
                  }}
                  color={progressTextColor}
                />
                <TextOverflowTooltip
                  fontSize={size === "compact" ? "11px" : "sm"}
                  style={{ paddingBottom: "0", maxWidth: appendText ? "14rem" : "5rem", width: "100%" }}
                  color={progressTextColor}
                  label={`${completed} of ~${total} ${appendText ?? ""}`}
                />
              </Stack>
              <Progress
                width="100%"
                alignSelf={align}
                mt={size === "compact" ? "0!important" : ".5rem!important"}
                mb={showInline ? ".5rem!important" : "0!important"}
                size={size === "compact" ? "xs" : "sm"}
                className="ch-progressbar"
                opacity={progressOpacity}
                backgroundColor={progressBgColor}
                borderRadius="full"
                isAnimated
                value={percent}
                colorScheme={isPaused ? "yellow" : progressColor}
              />
            </Stack>
            {showCancelButton && workflowIntent !== "cancel" && !isReadOnly && (
              <Center width="6rem">
                <Button {...primaryButtonProps} isDisabled={!conversationId} width="100%" onClick={onCancel}>
                  Cancel
                </Button>
              </Center>
            )}
          </Stack>
        )}
        {progressIndicatorStyle === "circle" && <CircularProgress value={percent} size="19px" thickness={"10"} color="green.500" />}
      </>
    );
  }
};
