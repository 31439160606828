import React, { useCallback, useEffect, useMemo, useState } from "react";
import { validate } from "json-schema";
import { Stack, Text, Icon, Tag, Tooltip, Center, useColorModeValue, useToast, Button, Input, Box, Checkbox } from "@chakra-ui/react";
import { useAppDispatch, useButtonProps, useConfigMap, useConfigProjectTypes, useIsLoadingProjectConfig } from "hooks";
import { getTypeIcon } from "configs/configMap";
import { ConfiguredWorkflowUpsertModal } from "../configuredWorkflows/components/ConfiguredWorkflowUpsertModal";
import { useConfiguredWorkflows } from "hooks/useConfiguredWorkflows";
import type { ConfiguredWorkflow } from "types/configuredWorkflows";
import { useEntitlementConfigs } from "hooks/useEntitlementConfigs";
import type { EntitlementConfig } from "api/entitlementConfigs/models/Entitlement";
import { EntitlementConfigUpsertModal } from "../entitlements/modals/EntitlementConfigUpsertModal";
import type { ProjectConfigState } from "state/config/reducer";
import { AiOutlineDownload } from "react-icons/ai";
import { BiDuplicate, BiTrash } from "react-icons/bi";
import { getEnvironment } from "screens/common/app";
import { useAddToCharliContext } from "screens/panels/addToCharli/AddToCharliWizard/AddToCharliProvider";
import { ConfirmDeleteModal } from "screens/common/components/ConfirmDeleteModal";
import { deleteProjectConfig } from "state/config/operations";
import { useSortList } from "hooks/useSortList";
import { BulkUpsertProjectConfigs } from "./BulkUpsertProjectConfigs";
import type { UserConfig } from "types/config";
import { ProjectConfigSchema } from "./schemas/ProjectConfigSchema";
import { formatDistanceToNow } from "date-fns";
import { TextOverflowTooltip } from "screens/landing/components/TextOverflowTooltip";
import { UpdateProjectConfigForm } from "./UpdateProjectConfigForm";

function saveAllToFile(projectConfigs: ProjectConfigState[]) {
  const jsonContent = JSON.stringify(
    projectConfigs.map((projectConfig) => ({ newProjectForm: null, ...projectConfig.config })),
    null,
    2
  );
  const blob = new Blob([jsonContent], { type: "application/json" });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  const date = new Date();
  const formattedDate = `${date.getFullYear()}${("0" + (date.getMonth() + 1)).slice(-2)}${("0" + date.getDate()).slice(-2)}`;

  link.setAttribute("download", `${getEnvironment().label}-project-config-${formattedDate}.json`);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export const ProjectConfigAdmin = () => {
  const [isOpen, setIsOpen] = useState<string | undefined>(undefined);
  const { isAdminPanelOpen, onAdminPanelOpen, onAdminPanelClose } = useAddToCharliContext();
  const [selectedConfig, setSelectedConfig] = useState<ProjectConfigState>();
  const [selectedEntitlement, setSelectedEntitlement] = useState<string | undefined>(undefined);
  const [selectedIntent, setSelectedIntent] = useState<string | undefined>(undefined);
  const [importedConfigs, setImportedConfigs] = useState<UserConfig[] | null>(null);
  const [checked, setChecked] = useState<Record<string, boolean>>({});
  const configMap = useConfigMap();
  const projectTypes = useConfigProjectTypes(configMap);
  const configuredWorkflows = useConfiguredWorkflows();
  const entitlements = useEntitlementConfigs();
  const buttonColor = useColorModeValue("gray.500", "gray.300");
  const buttonHoverColor = useColorModeValue("gray.600", "gray.400");
  const tagBgColorValue = useColorModeValue("100", "800");
  const isLoading = useIsLoadingProjectConfig();
  const dispatch = useAppDispatch();
  const toast = useToast();
  const secondaryButtonProps = useButtonProps("sm", "secondary");
  const { selectSortComponent: SortComponent, sortedList: sortedListWithHome } = useSortList<ProjectConfigState>(
    projectTypes,
    ["config.title", "config.collectionType", "config.entitlement", "config.intent", "lastModifiedDate", "lastImportedDate"],
    "config.title" as keyof ProjectConfigState,
    "asc"
  );
  const textColor = useColorModeValue("primary.darkGray", "gray.200");

  const sortedList = useMemo(() => sortedListWithHome, [sortedListWithHome]);

  const onClose = useCallback(() => {
    setSelectedConfig(undefined);
    setSelectedEntitlement(undefined);
    setSelectedIntent(undefined);
    setIsOpen(undefined);
    setImportedConfigs(null);
    onAdminPanelClose();
  }, [onAdminPanelClose]);

  const onDelete = async () => {
    if (!selectedConfig) return;

    const response = await dispatch(deleteProjectConfig({ id: selectedConfig.id }));

    if (response.type === deleteProjectConfig.rejected.type) {
      toast({
        title: "Error",
        description: (response.payload as { message: string }).message,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }

    onClose();
  };

  const getWorkflow = useCallback(
    (intent: string): ConfiguredWorkflow | undefined => {
      return configuredWorkflows.find((workflow) => workflow.userIntent === intent);
    },
    [configuredWorkflows]
  );

  const getEntitlement = useCallback(
    (entitlementCode: string): EntitlementConfig | undefined => {
      return entitlements.find((entitlement) => entitlement.code === entitlementCode);
    },
    [entitlements]
  );

  const importProjectConfigs = useCallback(
    (evt: React.ChangeEvent<HTMLInputElement>) => {
      if (!evt.target.files || !evt.target.files[0]) {
        return;
      }

      const file = evt.target.files[0];
      const reader = new FileReader();

      evt.target.value = "";

      reader.onload = (e) => {
        try {
          if (!e.target?.result || typeof e.target.result !== "string") {
            return;
          }

          const data = JSON.parse(e.target.result);

          const validatedData = validate(data, {
            $schema: "http://json-schema.org/draft-07/schema#",
            type: "array",
            items: ProjectConfigSchema,
          });

          if (!validatedData.valid) {
            toast({
              title: "Error",
              description: "Invalid JSON: " + validatedData.errors.map((error) => `${error.property} ${error.message}`).join(", "),
              status: "error",
              duration: 9000,
              isClosable: true,
            });
            return;
          }

          setImportedConfigs(data);
          setIsOpen("bulk-upsert");
        } catch (error) {
          console.error("Error parsing JSON:", error);
        }
      };

      reader.readAsText(file);
    },
    [toast]
  );

  const userConfigs = useMemo(() => projectTypes.map(({ config }) => config), [projectTypes]);

  useEffect(() => {
    setChecked(sortedList.reduce((acc, val) => ({ ...acc, [val.id]: false }), {}));
  }, [sortedList]);

  const areAllChecked = useMemo(() => sortedList.every((projectConfig) => checked[projectConfig.id]), [checked, sortedList]);
  const isIndeterminate = useMemo(
    () => sortedList.some((projectConfig) => checked[projectConfig.id]) && !areAllChecked,
    [areAllChecked, checked, sortedList]
  );

  const renderProjectConfigForm = useCallback(() => {
    if (!selectedConfig) {
      return <React.Fragment />;
    }
    return <UpdateProjectConfigForm projectConfig={selectedConfig} onClose={onClose} />;
  }, [selectedConfig, onClose]);

  return (
    <Box>
      <Stack alignItems="center" justifyContent={"space-between"} direction="row" spacing="3rem" pb=".5rem">
        {SortComponent}
        <Stack direction="row">
          <Button
            onClick={() => saveAllToFile(isIndeterminate ? projectTypes.filter(({ id }) => checked[id]) : projectTypes)}
            {...secondaryButtonProps}>
            {isIndeterminate ? "Export Selected" : "Export All"}
          </Button>
          <Input onChange={(evt) => importProjectConfigs(evt)} type="file" accept=".json" hidden id="ch-import-project-configs" />
          <Button onClick={() => document.getElementById("ch-import-project-configs")?.click()} width="6rem" {...secondaryButtonProps}>
            Import
          </Button>
        </Stack>
      </Stack>

      <Box height={"calc(100vh - 15rem)"} overflow="auto" fontSize={"sm"}>
        <Box as="table" width="100%" mb="4rem">
          <Box as="thead">
            <Box as="tr" lineHeight={"3rem"}>
              <Box as="th" width="2%">
                <Center>
                  <Checkbox
                    onChange={() => {
                      if (areAllChecked) setChecked(sortedList.reduce((acc, val) => ({ ...acc, [val.id]: false }), {}));
                      else setChecked(sortedList.reduce((acc, val) => ({ ...acc, [val.id]: true }), {}));
                    }}
                    isChecked={areAllChecked}
                    isIndeterminate={isIndeterminate}
                    pr=".5rem"
                  />
                </Center>
              </Box>
              <Box as="th" width="18%" textAlign={"left"}>
                Project Title
              </Box>
              <Box as="th" width="20%" textAlign={"left"}>
                Entitlement
              </Box>
              <Box as="th" width="20%" textAlign={"left"}>
                Workflow Intent
              </Box>
              <Box as="th" width={"10%"} textAlign={"left"}>
                Modified at
              </Box>
              <Box as="th" width={"10%"} textAlign={"left"}>
                Imported at
              </Box>
              <Box as="th" width="5%"></Box>
              <Box as="th" width="5%"></Box>
              <Box as="th" width="5%"></Box>
            </Box>
          </Box>
          <Box as="tbody">
            {sortedList.map((projectConfig) => (
              <Box
                {...(checked[projectConfig.id] && { backgroundColor: "gray.100" })}
                as="tr"
                lineHeight={"2.3rem"}
                role={"button"}
                onClick={() => {
                  setSelectedConfig(projectConfig);
                  onAdminPanelOpen();
                }}
                key={projectConfig.id}>
                <Box
                  onClick={(evt) => {
                    evt.stopPropagation();
                  }}
                  as="td"
                  width="2%">
                  <Center>
                    <Checkbox
                      onChange={() => setChecked({ ...checked, [projectConfig.id]: !checked[projectConfig.id] })}
                      isChecked={checked[projectConfig.id]}
                      pr=".5rem"
                    />
                  </Center>
                </Box>
                <Box as="td" width="18%">
                  <Stack direction="row" alignItems={"center"}>
                    <Icon as={getTypeIcon(projectConfig.config.icon)} boxSize="1rem" />
                    <TextOverflowTooltip color={textColor} label={projectConfig.config.title || projectConfig.config.intent} />
                  </Stack>
                </Box>
                <Box
                  as="td"
                  width="20%"
                  cursor="pointer"
                  onClick={(event: { stopPropagation: () => void }) => {
                    event.stopPropagation();
                    setSelectedEntitlement(projectConfig.config.entitlement);
                    setIsOpen("entitlement");
                  }}>
                  {projectConfig.config.entitlement && (
                    <Stack direction="row" alignItems={"center"}>
                      <Tooltip label={getEntitlement(projectConfig.config.entitlement) ? "Edit Entitlement" : "Create Entitlement"}>
                        <Tag
                          size="sm"
                          color={textColor}
                          fontWeight={"semibold"}
                          backgroundColor={
                            getEntitlement(projectConfig.config.entitlement) ? `green.${tagBgColorValue}` : `red.${tagBgColorValue}`
                          }>
                          {projectConfig.config.entitlement}
                        </Tag>
                      </Tooltip>
                    </Stack>
                  )}
                </Box>
                <Box
                  as="td"
                  width="20%"
                  cursor="pointer"
                  onClick={(event: { stopPropagation: () => void }) => {
                    event.stopPropagation();
                    const selectedWorkflow = getWorkflow(projectConfig.config.intent);
                    selectedWorkflow && setSelectedIntent(selectedWorkflow.id);
                    setIsOpen("intent");
                  }}>
                  {projectConfig.config.intent && (
                    <Stack direction="row" alignItems={"center"}>
                      <Tooltip label={getWorkflow(projectConfig.config.intent) ? "Edit Workflow" : "Create Workflow"}>
                        <Tag
                          size="sm"
                          color={textColor}
                          fontWeight={"semibold"}
                          backgroundColor={
                            getWorkflow(projectConfig.config.intent) ? `green.${tagBgColorValue}` : `red.${tagBgColorValue}`
                          }>
                          {projectConfig.config.intent}
                        </Tag>
                      </Tooltip>
                    </Stack>
                  )}
                </Box>
                <Box as="td" width={"10%"}>
                  <Text>{formatDistanceToNow(new Date(projectConfig.lastModifiedDate))}</Text>
                </Box>
                <Box as="td" width={"10%"}>
                  <Text>{projectConfig.lastImportedDate ? formatDistanceToNow(new Date(projectConfig.lastImportedDate)) : "n/a"}</Text>
                </Box>
                <Box as="td" width="2%">
                  <Tooltip aria-label="" label="Export Config" placement="top" hasArrow>
                    <Center>
                      <Icon
                        cursor="pointer"
                        as={AiOutlineDownload}
                        color={buttonColor}
                        boxSize="1rem"
                        _hover={{ color: buttonHoverColor }}
                        onClick={(event) => {
                          saveAllToFile([projectConfig]);
                          event.stopPropagation();
                        }}
                      />
                    </Center>
                  </Tooltip>
                </Box>
                <Box as="td" width="2%">
                  <Tooltip aria-label="" label="Duplicate Project" placement="top" hasArrow>
                    <Center>
                      <Icon
                        cursor="pointer"
                        as={BiDuplicate}
                        color={buttonColor}
                        boxSize="1rem"
                        _hover={{ color: buttonHoverColor }}
                        onClick={(event) => {
                          const duplicateConfig = {
                            ...projectConfig,
                            id: "duplicate",
                            config: {
                              ...projectConfig.config,
                              title: "",
                              route: "projects/",
                              collectionType: "",
                              intent: "",
                              entitlement: "",
                            },
                          };
                          setSelectedConfig(duplicateConfig);
                          onAdminPanelOpen();
                          event.stopPropagation();
                        }}
                      />
                    </Center>
                  </Tooltip>
                </Box>
                <Box as="td" width="2%">
                  <Tooltip aria-label="Delete Project" label="Delete Project" placement="top" hasArrow>
                    <Center>
                      <Icon
                        cursor="pointer"
                        as={BiTrash}
                        color={buttonColor}
                        boxSize="1rem"
                        _hover={{ color: buttonHoverColor }}
                        onClick={(event) => {
                          setSelectedConfig(projectConfig);
                          setIsOpen("delete");
                          event.stopPropagation();
                        }}
                      />
                    </Center>
                  </Tooltip>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>

      {isAdminPanelOpen && selectedConfig && renderProjectConfigForm()}
      {isOpen === "entitlement" && selectedEntitlement && (
        <EntitlementConfigUpsertModal code={selectedEntitlement} isOpen onClose={onClose} />
      )}
      {isOpen === "intent" && (
        <ConfiguredWorkflowUpsertModal
          id={selectedIntent}
          isOpen
          onClose={onClose}
          onSubmit={function (): void {
            throw new Error("Function not implemented.");
          }}
          onSubmitState={function (): void {
            throw new Error("Function not implemented.");
          }}
        />
      )}

      {isOpen === "delete" && (
        <ConfirmDeleteModal
          title="Delete Project Config"
          body={`This will delete your project config. Are you sure?`}
          isOpen
          onClose={onClose}
          onConfirm={onDelete}
          isLoading={isLoading}
        />
      )}

      {isOpen === "bulk-upsert" && importedConfigs && (
        <BulkUpsertProjectConfigs projectConfigs={userConfigs} importedProjectConfigs={importedConfigs} onClose={onClose} />
      )}
    </Box>
  );
};
