import {
  Box,
  Center,
  useColorModeValue,
  useBreakpointValue,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Stack,
  Tooltip,
  Tag,
  SimpleGrid,
} from "@chakra-ui/react";
import type { FunctionComponent } from "react";
import { useMemo } from "react";
import { useCallback } from "react";
import React, { useContext, useEffect, useRef } from "react";
import { CollectionsFilterContext } from "../collections/CollectionsFilterContext";
import { ItemLoadingIndicator } from "../collections/ItemLoadingIndicator";
import { NotesPanel } from "screens/content/common/notes/NotesPanel";
import { ContentViewPanel } from "screens/content/contentView/ContentViewPanel";
import { SharePanel } from "screens/panels/share/SharePanel";
import { VerifiedAIPanel } from "screens/content/contentView/VerifiedAIPanel";
import { useDispatch } from "react-redux";
import { deleteUserPreference } from "state/userPreference/operations";
import {
  useCollectionKey,
  useEquityPortfolioId,
  useGetConversationCompanyTickerValue,
  useGroupCollectionsIds,
  usePortfolioCollections,
  useProjectParams,
  useSortedCollectionsIds,
  useUserPreference,
} from "hooks";
import { ProjectLandingDefaultTile } from "./projectLandingTileLayouts/ProjectLandingDefaultTile";
import { placeholderCompanies } from "../dashboard/PlaceholderCompanies";
import { HomePlaceholderTile } from "./projectLandingTileLayouts/HomePlaceholderTile";
import { downloadCollectionsByGroup } from "state/collection/operations";
import { useScheduledPlaceholderProjectsByPortfolio } from "hooks/useWorkflowSchedules";
import { ScheduledProjectPlaceholderTile } from "./projectLandingTileLayouts/ScheduledProjectPlaceholderTile";

const ProjectGroup = ({ collectionId }: { collectionId: string }) => {
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });
  const maybeGroupId = useCollectionKey(collectionId, "projectGroupId");
  const maybeCollectionsIds = useGroupCollectionsIds(maybeGroupId);
  const maybeSortedCollectionsIds = useSortedCollectionsIds(maybeCollectionsIds);
  const tagBgColor = useColorModeValue("white", "gray.700");
  const tagBorderColor = useColorModeValue("gray.300", "gray.500");
  const tagColor = useColorModeValue("gray.600", "gray.500");
  const dispatch = useDispatch();

  const { firstCollectionId, restCollections } = useMemo(() => {
    if (!maybeSortedCollectionsIds || maybeSortedCollectionsIds.length === 0)
      return { firstCollectionId: collectionId, restCollections: [] };
    else {
      return {
        firstCollectionId: collectionId,
        restCollections: maybeSortedCollectionsIds.filter((id) => id !== collectionId),
      };
    }
  }, [maybeSortedCollectionsIds, collectionId]);

  const renderRestCollections = useCallback(
    (isExpanded: boolean) => {
      return restCollections.length > 0 ? (
        <AccordionPanel pb="1rem" pr="0" pt=".75rem">
          {isExpanded && (
            <Stack spacing=".75rem">
              {restCollections.map((id) => (
                <ProjectLandingDefaultTile key={`project-${id}`} collectionId={id} isChildProject />
              ))}
            </Stack>
          )}
        </AccordionPanel>
      ) : null;
    },
    [restCollections]
  );

  useEffect(() => {
    if (maybeGroupId) {
      dispatch(downloadCollectionsByGroup({ groupId: maybeGroupId }));
    }
  }, [maybeGroupId, dispatch]);

  return (
    <AccordionItem {...(maybeGroupId && { className: maybeGroupId })} border="none" pb="1.3rem" position="relative">
      {({ isExpanded }) => (
        <>
          {restCollections.length > 0 && (
            <AccordionButton
              className="ch-rerun-version-tag"
              _hover={{}}
              padding={0}
              paddingInline={0}
              position="absolute"
              zIndex={1}
              top={isMobile ? "3.95rem" : "4.65rem"}
              left={isMobile ? "unset" : "1.8rem"}
              right={isMobile ? "1rem" : "unset"}
              width="unset">
              <Tooltip
                label={`${isExpanded ? "Hide" : "View"} previous ${restCollections.length} projects`}
                aria-label="Portfolio Name"
                placement="top">
                <Tag
                  variant="subtle"
                  borderColor={tagBorderColor}
                  borderWidth="1px"
                  bgColor={tagBgColor}
                  color={tagColor}
                  fontSize="0.65rem"
                  cursor={"pointer"}>
                  <AccordionIcon />
                  {`${restCollections.length} ${restCollections.length === 1 ? "version" : "versions"}`}
                </Tag>
              </Tooltip>
            </AccordionButton>
          )}
          <ProjectLandingDefaultTile collectionId={firstCollectionId} />
          {renderRestCollections(isExpanded)}
        </>
      )}
    </AccordionItem>
  );
};

export const ProjectLandingTiles: FunctionComponent = () => {
  const {
    collectionCount,
    setCollectionCount,
    filteredCollections,
    selectedQuadrant,
    searchText,
    setSelectedQuadrant,
    setSearchText,
    selectedRating,
    setSelectedRating,
    setSelectedProjectId,
  } = useContext(CollectionsFilterContext);
  const sectionRef = useRef<HTMLDivElement>(null);
  const bgColor = useColorModeValue("white", "gray.800");
  const textColor = useColorModeValue("primary.darkGray", "gray.400");
  const dispatch = useDispatch();
  const userPreferenceSourceWeight = useUserPreference("source_weights_index") as 0 | 1;
  const observer = useRef<IntersectionObserver | null>(null);
  const portfolioCollections = usePortfolioCollections();
  const portfolioId = useEquityPortfolioId(portfolioCollections, "Magnificent 7");

  const { projectFilter, isPortfolios } = useProjectParams();
  const loadTilesCount = 5;
  const observerRef = useRef<HTMLDivElement>(null);
  const isDueDiligence = useMemo(() => projectFilter === "due_diligence" || isPortfolios, [isPortfolios, projectFilter]);
  const scheduledNewProjects = useScheduledPlaceholderProjectsByPortfolio(projectFilter ?? "");

  useEffect(() => {
    if (loadTilesCount > 0) {
      setCollectionCount(loadTilesCount);
    }
  }, [loadTilesCount, setCollectionCount]);

  useEffect(() => {
    if (!userPreferenceSourceWeight) return;

    dispatch(
      deleteUserPreference({
        preferenceKey: "source_weights_index",
      })
    );
  }, [dispatch, userPreferenceSourceWeight]);

  const filteredActiveOrUniqueCollections = useMemo(() => {
    return filteredCollections.flatMap((collection) => {
      if (collection.isActiveProjectInGroup || !collection.projectGroupId) {
        return [collection.id];
      } else {
        return [];
      }
    });
  }, [filteredCollections]);

  const collectionsToRender = useMemo(() => {
    return filteredActiveOrUniqueCollections.slice(0, collectionCount);
  }, [collectionCount, filteredActiveOrUniqueCollections]);

  const allProjectConversationIds = useMemo(
    () => filteredCollections.map((collection) => collection.conversationId).filter((id): id is string => id !== undefined),
    [filteredCollections]
  );

  const getConversationTicker = useGetConversationCompanyTickerValue();
  const filteredPlaceholderCompanies = useMemo(() => {
    const stockTickers = new Set(filteredCollections.map((collection) => collection.ticker && collection.ticker.toLowerCase()));
    const conversationTickerValues = allProjectConversationIds.flatMap((conversationId) => {
      const maybeValue = getConversationTicker(conversationId);

      if (maybeValue) {
        return [maybeValue];
      } else {
        return [];
      }
    });
    const allTickers = new Set([...stockTickers, ...conversationTickerValues.map((ticker) => ticker.toLowerCase())]);

    return placeholderCompanies.filter((company) => !allTickers.has(company.ticker.toLowerCase()));
  }, [filteredCollections, allProjectConversationIds, getConversationTicker]);

  useEffect(() => {
    const currentObserver = observer.current;
    const observerElement = observerRef.current;

    if (!observerElement) return;

    const options = {
      root: null,
      rootMargin: "20px",
      threshold: 0.1,
    };

    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        setCollectionCount((prev) => prev + 3);
      }
    }, options);

    observer.current.observe(observerElement);

    const checkInitialVisibility = () => {
      if (observerElement) {
        const rect = observerElement.getBoundingClientRect();
        const isVisible = rect.top <= window.innerHeight && rect.bottom >= 0;
        if (isVisible) {
          setCollectionCount((prev) => prev + 3);
        }
      }
    };

    setTimeout(checkInitialVisibility, 100);

    return () => {
      if (currentObserver) {
        currentObserver.disconnect();
      }
    };
  }, [setCollectionCount]);

  const handleClearFilters = () => {
    setSearchText("");
    setSelectedQuadrant(undefined);
    setSelectedRating(undefined);
  };

  return (
    <Box ref={sectionRef} height="100%" onMouseLeave={() => setSelectedProjectId(undefined)}>
      {collectionsToRender.length > 0 ? (
        <Accordion allowMultiple pb="2rem">
          {scheduledNewProjects.map((schedule) => (
            <ScheduledProjectPlaceholderTile
              key={schedule.scheduleId}
              scheduleId={schedule.scheduleId}
              companyName={schedule.companyName}
              exchange={schedule.companyStockExchange}
              ticker={schedule.companyTicker}
            />
          ))}
          {collectionsToRender.map((collectionId) => {
            return <ProjectGroup key={`group-${collectionId}`} collectionId={collectionId} />;
          })}
        </Accordion>
      ) : (
        <>
          {selectedQuadrant || searchText || selectedRating ? (
            <Stack textAlign="center" mt="3rem" mb="4rem" color={textColor}>
              <Box fontSize="lg">
                No projects found {searchText.length > 0 || selectedRating ? "using the filter" : ""} <b>{searchText || selectedRating}</b>{" "}
                {selectedQuadrant ? "in quadrant" : ""} <b>{selectedQuadrant}</b>
              </Box>
              <Box fontSize="md" onClick={handleClearFilters} color="charli.primaryBlue" cursor="pointer">
                Click here to clear filter
              </Box>
            </Stack>
          ) : (
            isDueDiligence && portfolioId !== projectFilter && <ProjectLandingDefaultTile collectionId="placeholder" />
          )}
        </>
      )}
      <Box
        height={collectionsToRender.length === 0 && portfolioId === projectFilter ? "0" : "20px"}
        width="100%"
        className="last-content-item"
        ref={observerRef}
      />
      {collectionsToRender.length > 0 && (
        <Box position={"fixed"} bottom={"0"} backgroundColor={bgColor} borderRadius="full" border="none" zIndex={2} mb="5px" right="8px">
          <Center height="100%">
            <ItemLoadingIndicator
              onClick={() => null}
              currentItemCount={collectionsToRender.length}
              totalItemCount={filteredActiveOrUniqueCollections.length}
            />
          </Center>
        </Box>
      )}
      {!selectedQuadrant && !searchText && !selectedRating && portfolioId === projectFilter && (
        <SimpleGrid spacing="1rem" columns={[1, 1, 3, 3, 4]}>
          {filteredPlaceholderCompanies.map((company) => (
            <HomePlaceholderTile
              key={company.ticker}
              logo={company.logo}
              companyName={company.companyName}
              ticker={company.ticker}
              exchange={company.exchange}
              securityType={company.securityType}
              overview={company.overview}
            />
          ))}
        </SimpleGrid>
      )}
      <ContentViewPanel />
      <SharePanel /> <NotesPanel /> <VerifiedAIPanel />
    </Box>
  );
};
