import type { Collection } from "types/collection";
import type { BuySellHoldData } from "../BuySellHoldChart/BuySellHoldChart";
import type { StockTickerData } from "api/stockTickerData";

export const generateBuySellData = (
  groupedCollections: Collection[],
  getStockData: (ticker: string, exchange: string) => StockTickerData | undefined
): BuySellHoldData => {
  const items = (groupedCollections || [])
    .map((collection) => {
      const { ticker, stockExchange, buySellScoreSentiment, id } = collection || {};
      if (!ticker || !stockExchange) return null;

      const sentimentValue = buySellScoreSentiment?.value ?? "";
      const regexRating = sentimentValue.match(/\d+(\.\d+)?/g);

      const stockData = getStockData?.(ticker, stockExchange);
      const price = parseFloat(stockData?.ticker_quote?.price ?? "0");
      const ratingValue = regexRating?.[0] ?? "0";
      const rating = Number.isFinite(parseFloat(ratingValue)) ? Math.min(5, Math.max(0, parseFloat(ratingValue))) : 0;

      return { projectId: id, ticker, currentPrice: price, rating };
    })
    .filter((item): item is NonNullable<typeof item> => item !== null);

  return { items };
};
