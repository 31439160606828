import { Box, Stack, Tab, TabList, TabPanel, TabPanels, Tabs, useBreakpointValue } from "@chakra-ui/react";
import { useContents, useIsMainAppLoading, useTabProps, useCollectionKey, useInitialSyncCompleted } from "hooks";
import type { FunctionComponent } from "react";
import { useMemo } from "react";
import React, { useContext, useState } from "react";
import { ContentFilterContext, ContentRouter } from "screens/content";
import { ProjectDetailDefaultAIResults } from "./ProjectDetailDefaultAIResults";
import { ProjectDetailDefaultActionMenu } from "./ProjectDetailDefaultViewActionMenu";
import { ContentCanvasFilterInput } from "screens/content/contentCanvas/header/ContentCanvasFilterInput";

export const PROJECT_DETAIL_SECTION_SELECTOR = "project-detail-section";

interface Props {
  collectionId: string;
}

export const ProjectDetailViewContent: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({ collectionId }) => {
  const { resetFilter } = useContext(ContentFilterContext);
  const collectionMetadataIds = useCollectionKey(collectionId, "metadataIds");
  const contentData = useContents(collectionMetadataIds || []);
  const [tabIndex, setTabIndex] = useState(0);
  const { defaultTabProps } = useTabProps();
  const isMainAppLoading = useIsMainAppLoading();
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });
  const hasInitialSyncCompleted = useInitialSyncCompleted();

  const handleTabsChange = (index: number) => {
    setTabIndex(index);
    resetFilter();
  };

  const isLoadingContents = useMemo(() => {
    if (!collectionMetadataIds?.length) {
      return false;
    }

    return (contentData?.length ?? 0) < collectionMetadataIds?.length;
  }, [collectionMetadataIds?.length, contentData?.length]);

  return (
    <>
      {!isMainAppLoading && (
        <Tabs isFitted={isMobile} index={tabIndex} onChange={handleTabsChange} mt={isMobile ? "0" : "2.9rem!important"}>
          <Stack
            direction={isMobile ? "column" : "row"}
            justifyContent={"space-between"}
            width="100%"
            spacing={isMobile ? "1rem" : ".5rem"}
            alignItems="center"
            pb=".5rem">
            <TabList borderBottom={"none"} width="100%">
              <Tab {...defaultTabProps} className="ch-project-results">
                AI Results
              </Tab>
              <Tab {...defaultTabProps} className="ch-project-resources">
                Source Content
              </Tab>
            </TabList>
            <ProjectDetailDefaultActionMenu collectionId={collectionId} />
          </Stack>
          <Box width="100%" maxWidth="30rem" ml="auto">
            <ContentCanvasFilterInput placeholderText="Filter AI Results" />
          </Box>
          <TabPanels pb="4rem">
            <TabPanel pt=".65rem" px="0!important" className="ch-project-results-panel">
              <ProjectDetailDefaultAIResults isLoading={!hasInitialSyncCompleted || isLoadingContents} collectionId={collectionId} />
            </TabPanel>
            <TabPanel pt=".65rem" px="0!important" className="ch-project-resources-panel">
              <Stack spacing="1rem">
                <ContentRouter filterOutProjectOutput />
              </Stack>
            </TabPanel>
          </TabPanels>
        </Tabs>
      )}
    </>
  );
};
