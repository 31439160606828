import { useColorModeValue, InputGroup, IconButton, InputRightElement, Box, Stack, useBreakpointValue, Center } from "@chakra-ui/react";
import React, { useEffect, useRef, useState, useMemo, useCallback, useContext } from "react";
import { useLocation } from "react-router-dom";
import { useConversationContext } from "screens/thread/ConversationContext";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import { TypingIndicator } from "screens/thread/components/cells/components";
import { CharliLogoCircle } from "screens/thread/CharliLogoCircle";
import type { SuggestedQuestion, SuggestedQuestions } from "api/suggestions";
import { AutoComplete, AutoCompleteInput, AutoCompleteItem, AutoCompleteList } from "@choc-ui/chakra-autocomplete";
import type { AutoCompleteRefMethods } from "@choc-ui/chakra-autocomplete";
import { TextOverflowTooltip } from "screens/landing/components/TextOverflowTooltip";
import { useSelector } from "react-redux";
import type { RootState } from "state/rootReducer";
import { useAddToCharliContext } from "screens/panels/addToCharli/AddToCharliWizard/AddToCharliProvider";
import { v4 as uuid } from "uuid";
import { InputBarAttachmentButton } from "screens/conversation/components/InputBar/InputBarAttachmentButton";
import { InputBarDropzoneContext } from "screens/conversation/components/InputBar/InputBarDropzoneContext";
import { useCustomScrollbar } from "hooks/useCustomScrollbar";

export interface AutocompleteInputProps {
  initialText?: string;
  suggestedQuestions?: SuggestedQuestions;
  isInputDisabled?: boolean;
  isSubmitDisabled?: boolean;
  onSelectOption: (value: SuggestedQuestion) => void;
  value?: string;
  onChange: (value: string) => void;
  onReset?: () => void;
  isLoading?: boolean;
  onClickSubmit?: (value?: SuggestedQuestion) => void;
  conversationId?: string;
  minWidth?: string;
  defaultIsOpen?: boolean;
  disableFilter?: boolean;
  className?: string;
  conversationState?: string;
  externalInputRef?: React.RefObject<HTMLInputElement>;
  inputId: string;
  canBypassDisabled?: boolean;
  setCanBypassDisabled?: (canBypassDisabled: boolean) => void;
  onFocus?: () => void;
  onBlur?: () => void;
}

export const useFocusBadgeColors = () => {
  const sentiment = useColorModeValue("orange.200", "orange.900");
  const analytical = useColorModeValue("twitter.50", "twitter.900");
  const equity = useColorModeValue("twitter.50", "twitter.900");
  const etf = useColorModeValue("orange.200", "orange.900");
  const question = useColorModeValue("gray.200", "gray.800");
  const nasdaq = useColorModeValue("purple.100", "purple.700");
  const nyse = useColorModeValue("orange.100", "orange.800");
  const tsx = useColorModeValue("blue.100", "blue.700");
  const mandatory = useColorModeValue("orange.200", "orange.700");
  const optional = useColorModeValue("twitter.50", "twitter.700");
  const recommended = useColorModeValue("purple.100", "purple.700");

  return {
    SENTIMENT: sentiment,
    ANALYTICAL: analytical,
    EQUITY: equity,
    ETF: etf,
    QUESTION: question,
    NASDAQ: nasdaq,
    NYSE: nyse,
    TSX: tsx,
    MANDATORY: mandatory,
    OPTIONAL: optional,
    RECOMMENDED: recommended,
  };
};

export const focusBadgeColor = useFocusBadgeColors;

export const AutocompleteInput: React.FC<AutocompleteInputProps> = React.memo(
  ({
    initialText = "",
    suggestedQuestions = [],
    isInputDisabled = false,
    isSubmitDisabled = false,
    onSelectOption,
    value,
    onChange,
    onReset,
    isLoading = false,
    onClickSubmit,
    conversationId,
    minWidth = "32rem",
    disableFilter = false,
    defaultIsOpen = false,
    className,
    conversationState,
    externalInputRef,
    inputId,
    canBypassDisabled,
    setCanBypassDisabled,
    onFocus,
    onBlur,
  }) => {
    const isConnected = useSelector((state: RootState) => state.websocket.isConnected);
    const inputBgColor = useColorModeValue("white", "gray.700");
    const inputBorderColor = useColorModeValue("gray.200", "gray.700");
    const inputSubmitColor = useColorModeValue("gray.50", "gray.800");
    const inputSubmitColorHover = useColorModeValue("gray.200", "primary.default");
    const gradientColor = useColorModeValue("0,0,0", "226,232,240");
    const submitColor = useColorModeValue("primary.default", "gray.700");
    const textColor = useColorModeValue("primary.darkGray", "gray.400");
    const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });
    const colors = useFocusBadgeColors();

    const {
      isConversationOpen,
      onConversationClose,
      onConversationOpen,
      isDialogOpen,
      onDialogClose,
      focusedInputId,
      setFocusedInputId,
      setIsUsingDefaultConversationDialog,
      setIsAnotherInputFocused,
    } = useConversationContext();
    const { setIsNewMenuAction } = useAddToCharliContext();
    const autocompleteRef = useRef<AutoCompleteRefMethods>();
    const internalInputRef = useRef<HTMLInputElement>(null);
    const listRef = useRef<HTMLDivElement>(null);
    const inputRef = externalInputRef || internalInputRef;
    const { files } = useContext(InputBarDropzoneContext);
    const { pathname } = useLocation();
    const { scrollbarStyle } = useCustomScrollbar(listRef, { width: "3px", barTransparency: 0.1 });

    const [showSuggestionList, setShowSuggestionList] = useState(defaultIsOpen);

    const isListVisible = useCallback(() => {
      return !!(listRef.current && listRef.current.offsetParent !== null);
    }, []);

    const suggestedQuestionsWithId = useMemo(() => {
      return suggestedQuestions.map((question) => ({ ...question, id: uuid() }));
    }, [suggestedQuestions]);

    const onHandleConversation = useCallback(() => {
      if (isConversationOpen) {
        onConversationClose();
      } else {
        setIsNewMenuAction(conversationId ? undefined : "command");
        onConversationOpen(conversationId);
      }
    }, [isConversationOpen, onConversationClose, setIsNewMenuAction, onConversationOpen, conversationId]);

    useEffect(() => {
      if (inputRef.current) {
        setIsUsingDefaultConversationDialog(false);
        isConversationOpen && focusedInputId === "conversation-input" && inputRef.current.focus();
      }
    }, [focusedInputId, inputRef, isConversationOpen, setIsUsingDefaultConversationDialog]);

    const isFocusedInputDisabled = useMemo(() => {
      return focusedInputId !== inputId;
    }, [focusedInputId, inputId]);

    useEffect(() => {
      if (isConversationOpen) {
        setFocusedInputId("conversation-input");
        if (isDialogOpen) {
          onDialogClose();
        }
      } else {
        setFocusedInputId("view-input");
      }
      setTimeout(() => {
        inputRef.current && inputRef.current.focus();
      }, 300);
    }, [isConversationOpen, setFocusedInputId, pathname, isDialogOpen, onDialogClose, inputRef]);

    const isSubmitAvailable = useCallback((): boolean => {
      if (value?.startsWith("/")) {
        return false;
      }
      return !!(
        value &&
        value.length > 0 &&
        !suggestedQuestions.some((suggestion) => suggestion.question.toLowerCase().trim() === value.toLowerCase().trim())
      );
    }, [suggestedQuestions, value]);

    const handleInputOnClick = useCallback(
      (onOpen: () => void) => {
        if ((isInputDisabled || isFocusedInputDisabled) && !canBypassDisabled) {
          return;
        }
        setIsAnotherInputFocused(true);
        if (inputId === "view-input" && isConversationOpen) {
          onConversationClose();
        }
        setShowSuggestionList(true);
        onOpen();
        onFocus?.();
        inputRef.current && inputRef.current.focus();
      },
      [
        inputRef,
        isInputDisabled,
        isFocusedInputDisabled,
        canBypassDisabled,
        setIsAnotherInputFocused,
        inputId,
        isConversationOpen,
        onFocus,
        onConversationClose,
      ]
    );

    useEffect(() => {
      const handleKeyDown = (ev: KeyboardEvent) => {
        const { key } = ev;
        const suggestedQuestion =
          suggestedQuestions.length > 0 &&
          suggestedQuestions.filter(
            (suggestion) => value?.toLocaleLowerCase() && suggestion.question.toLocaleLowerCase().includes(value)
          )[0];

        const isListCurrentlyVisible = isListVisible();

        switch (key) {
          case "Enter":
            (!isListCurrentlyVisible || isSubmitAvailable()) && onClickSubmit?.();
            setShowSuggestionList(false);
            setCanBypassDisabled?.(false);
            break;

          case "Tab": {
            if (!isListCurrentlyVisible) {
              return;
            }
            suggestedQuestion && onSelectOption(suggestedQuestion);
            setShowSuggestionList(false);
            setCanBypassDisabled?.(false);
            ev.preventDefault();
            break;
          }

          case " ":
            {
              const doesValueContainEntity = value?.includes(">") || false;
              setShowSuggestionList(doesValueContainEntity);
            }
            break;

          case "Escape":
            setShowSuggestionList(false);
            setCanBypassDisabled?.(false);
            onReset ? onReset() : onChange("");
            inputRef.current && inputRef.current.focus();
            break;

          default:
            break;
        }
      };

      document.addEventListener("keydown", handleKeyDown);
      return () => document.removeEventListener("keydown", handleKeyDown);
    }, [
      isSubmitAvailable,
      onClickSubmit,
      onReset,
      onChange,
      value,
      suggestedQuestions,
      onSelectOption,
      setCanBypassDisabled,
      showSuggestionList,
      setIsAnotherInputFocused,
      onBlur,
      inputRef,
      isListVisible,
    ]);

    const handleInputChange = useCallback(
      (ev: React.ChangeEvent<HTMLInputElement>) => {
        setShowSuggestionList(true);
        onChange(ev.target.value);
      },
      [onChange]
    );

    const handleSubmit = useCallback(() => {
      if (isLoading) {
        return;
      }
      const suggestedQuestion =
        suggestedQuestions.length > 0 &&
        suggestedQuestions.filter((suggestion) => value?.toLocaleLowerCase() && suggestion.question.toLocaleLowerCase().includes(value))[0];

      onClickSubmit && onClickSubmit(suggestedQuestion ? suggestedQuestion : undefined);
      setShowSuggestionList(false);
    }, [isLoading, onClickSubmit, suggestedQuestions, value]);

    const renderSuggestedQuestions = useMemo(() => {
      return suggestedQuestionsWithId.map(({ question, label, focus, type, matchFilter, id }) => (
        <AutoCompleteItem
          _focus={{ bgColor: inputSubmitColor }}
          key={`option-${id}`}
          value={JSON.stringify({ question, focus, matchFilter, type })}
          className="ch-autocomplete-suggestion"
          marginInline={0}
          p="1rem"
          borderRadius="none">
          <Stack direction="row" justifyContent="space-between" spacing="1rem" alignItems="center" width="100%">
            <TextOverflowTooltip
              className="ch-autocomplete-suggestion-text"
              noOfLines={isMobile ? 1 : 3}
              label={label || question.replace("|", "")}
              searchText={value}
              highlightBackground={false}
            />
            <Stack direction={"row"}>
              {type && (
                <Box
                  className="ch-autocomplete-suggestion-type"
                  borderRadius="4px"
                  py="2px"
                  px="5px"
                  fontSize="10px"
                  color={textColor}
                  background={colors[type.toUpperCase()] || "green.100"}>
                  {isMobile ? type.toUpperCase().slice(0, 1) : type.toUpperCase()}
                </Box>
              )}
              {focus && (
                <Box
                  className="ch-autocomplete-suggestion-tag"
                  borderRadius="4px"
                  py="2px"
                  px="5px"
                  fontSize="10px"
                  color={textColor}
                  background={colors[focus.toUpperCase()] || "gray.100"}>
                  {isMobile ? focus.toUpperCase().slice(0, 3) : focus.toUpperCase()}
                </Box>
              )}
            </Stack>
          </Stack>
        </AutoCompleteItem>
      ));
    }, [suggestedQuestionsWithId, inputSubmitColor, isMobile, value, textColor, colors]);

    return (
      <Stack spacing="0" width="100%" height="100%">
        <AutoComplete
          ref={autocompleteRef}
          onSelectOption={({ item: { originalValue } }) => {
            const suggestedQuestion = JSON.parse(originalValue) as SuggestedQuestion;
            onSelectOption(suggestedQuestion);
          }}
          defaultIsOpen={defaultIsOpen}
          disableFilter={disableFilter}
          freeSolo
          emptyState={false}>
          {({ onOpen }) => (
            <>
              <InputGroup position={"relative"}>
                <Stack bottom={-0.5} zIndex={10} display="flex" position={"absolute"} direction="row" justifyContent="space-between">
                  <CharliLogoCircle className="project-conversation-button" onClick={onHandleConversation} />
                  {(value?.startsWith("/") || files.length > 0 || (conversationState && conversationState === "clarification_needed")) &&
                    isConversationOpen && (
                      <Center width="2rem" borderRadius="30px 0px 0px 30px!important">
                        <InputBarAttachmentButton size="md" />
                      </Center>
                    )}
                </Stack>
                <AutoCompleteInput
                  id={inputId}
                  ref={inputRef}
                  onClick={() => handleInputOnClick(onOpen)}
                  onBlur={() => {
                    setIsAnotherInputFocused(false);
                    onBlur?.();
                  }}
                  autoComplete="off"
                  paddingInlineStart="0"
                  paddingInlineEnd="2.5rem"
                  paddingLeft={value?.startsWith("/") && isConversationOpen ? "5.5rem" : "4rem"}
                  borderRadius="30px 30px 30px 30px!important"
                  aria-autocomplete="both"
                  className={`${className ? className : "ch-question-input"}`}
                  disabled={canBypassDisabled && !isFocusedInputDisabled ? false : isInputDisabled || isFocusedInputDisabled}
                  backgroundColor={inputBgColor}
                  placeholder={initialText}
                  onChange={handleInputChange}
                  _disabled={{
                    cursor: "not-allowed",
                    opacity: 1,
                    color: "gray.200",
                  }}
                  _hover={
                    !isConversationOpen
                      ? {
                          boxShadow: `0 0 12px rgba(${gradientColor}, 0.2)`,
                          transition: "box-shadow 0.3s ease-in-out",
                        }
                      : {}
                  }
                  transition="box-shadow 0.3s ease-in-out"
                  loadingIcon={<TypingIndicator size="small" />}
                  size="md"
                  borderColor={inputBorderColor}
                  borderWidth="1px"
                  borderLeftWidth="0"
                  value={value}
                  background={`linear-gradient(to right, transparent 2rem, ${inputBgColor} 2rem) !important`}
                />
                <InputRightElement
                  width="2.5rem"
                  height="2.5rem"
                  zIndex={value && value.length > 0 ? 2 : 0}
                  children={
                    <IconButton
                      className="ch-autocomplete-submit"
                      aria-label="submit"
                      borderRadius="full"
                      color={submitColor}
                      size="sm"
                      backgroundColor={inputSubmitColor}
                      _disabled={{ cursor: "not-allowed", opacity: 0.6 }}
                      _hover={{ fontWeight: "bold", backgroundColor: inputSubmitColorHover }}
                      icon={<ArrowForwardIcon width="1.5rem" height="1.5rem" />}
                      isDisabled={
                        canBypassDisabled && !isFocusedInputDisabled
                          ? false
                          : isLoading || !isConnected || isSubmitDisabled || isInputDisabled || isFocusedInputDisabled
                      }
                      onClick={handleSubmit}
                    />
                  }
                />
              </InputGroup>
              {suggestedQuestionsWithId.length > 0 && showSuggestionList && (
                <AutoCompleteList
                  ref={listRef}
                  css={scrollbarStyle}
                  boxShadow="xl"
                  p="0"
                  marginLeft={["unset", "unset", "3rem!important"]}
                  marginTop="-3px!important"
                  minWidth={["calc(100vw - 2rem)", "unset", minWidth]}
                  width={["100%", "100%", "calc(100% - 3rem)"]}
                  maxHeight={["250px", "350px", "350px"]}>
                  {renderSuggestedQuestions}
                </AutoCompleteList>
              )}
            </>
          )}
        </AutoComplete>
      </Stack>
    );
  }
);

export default AutocompleteInput;
