import { createAsyncThunk } from "@reduxjs/toolkit";
import { getContent } from "api/content";
import type { RootState } from "state/rootReducer";
import { ContentDetails } from "types/content/ContentDetails";
import type { CollectionMetadataChecks } from "./reducer";

export function getCollectionCheckKey(contentDetail: ContentDetails): keyof CollectionMetadataChecks | undefined {
  if (contentDetail.manualTags.includes("company_logo")) {
    return "companyLogoId";
  } else if (contentDetail.manualTags.includes("project_landing_thumbnail")) {
    return "landingThumbnailId";
  } else if (contentDetail.name?.toLowerCase() === "stock equity data") {
    return "stockEquityDataId";
  } else if (contentDetail.dynamicData?.category === "company_details") {
    return "companyDetailsId";
  } else {
    return undefined;
  }
}

export const downloadCollectionMetadata = createAsyncThunk(
  "collections/download-collection-metadata",
  async ({ metadataIds }: { metadataIds: string[]; collectionId: string }, thunkAPI) => {
    const dismissedContentsIds = (thunkAPI.getState() as RootState).collectionMetadata.dismissedContentsIds;
    const filteredMetadataIds = (() => {
      if (!dismissedContentsIds) {
        return metadataIds;
      } else {
        return metadataIds.filter((metadataId) => !dismissedContentsIds[metadataId]);
      }
    })();

    if (filteredMetadataIds.length === 0) {
      return {
        contents: {},
        checks: {
          companyLogoId: "",
          stockEquityDataId: "",
          companyDetailsId: "",
        },
        dismissedIds: [],
      };
    }

    const contents = await getContent(filteredMetadataIds);

    if (contents !== "error") {
      const contentDetailsMap: Record<string, ContentDetails> = {};
      const collectionChecks: CollectionMetadataChecks = {
        companyLogoId: "",
        stockEquityDataId: "",
        companyDetailsId: "",
      };
      const dismissedContentsIds: string[] = [];

      for (let i = 0; i < contents.length; i++) {
        const content = contents[i];
        const contentDetail = new ContentDetails(content);

        const maybeCheckKey = getCollectionCheckKey(contentDetail);
        if (maybeCheckKey) {
          collectionChecks[maybeCheckKey] = contentDetail.id;
          contentDetailsMap[contentDetail.id] = contentDetail;
        } else {
          dismissedContentsIds.push(contentDetail.id);
        }
      }

      return {
        contents: contentDetailsMap,
        checks: collectionChecks,
        dismissedIds: dismissedContentsIds,
      };
    } else {
      throw new Error("Failed to download collection metadata");
    }
  }
);
