import React from "react";
import { Stack, useBreakpointValue, Box, Center, Tag, Table, Tbody, Th, Thead, Tr, useColorModeValue } from "@chakra-ui/react";
import { useProjectParams, useTileProps } from "hooks";
import type { ContentDetails } from "types/content/ContentDetails";
import { ContentPreview } from "screens/content/contentView/previewSection/ContentPreview";
import { useAddToCharliContext } from "../addToCharli/AddToCharliWizard/AddToCharliProvider";
import { ProjectReportPanelNewReportModal } from "./ProjectReportPanelNewReportModal";
import { ProjectReportPanelTableContent } from "./ProjectReportPanelTableContent";

interface Props {
  contentData: ContentDetails[];
}

export const ProjectReportPanelLatestReport = ({ contentData }: Props) => {
  const { projectId } = useProjectParams();
  const { isProjectReportSelectorOpen, onProjectReportSelectorClose } = useAddToCharliContext();
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });
  const commonTileProps = useTileProps();
  const bgColor = useColorModeValue("white", "gray.800");

  return (
    <>
      {contentData[0] && (
        <Stack
          direction={isMobile ? "column" : "row"}
          width="100%"
          justifyContent={"space-between"}
          pt="1rem"
          spacing={isMobile ? "1rem" : "2rem"}>
          <Stack
            position={"relative"}
            className="ch-latest-project-report"
            {...commonTileProps}
            backgroundColor={bgColor}
            borderColor={bgColor}
            borderRadius={"0"}
            spacing="0"
            p="0"
            height={"100%"}
            maxWidth={"15rem"}
            cursor="default">
            <Box position={"absolute"} zIndex={2} top="5px" right="5px">
              <Tag size={"sm"} variant="solid" colorScheme="green">
                LATEST
              </Tag>
            </Box>
            <Center>
              <ContentPreview backgroundColor={bgColor} itemContent={contentData[0]} thumbnailHeight={"300"} thumbnailWidth={"230"} />
            </Center>
          </Stack>
          <Box width="100%">
            <Table variant="simple" fontSize={isMobile ? "xs" : "sm"}>
              <Thead>
                <Tr>
                  <Th p={isMobile ? "0" : ".5rem"}>Name</Th>
                  {!isMobile && (
                    <Th textAlign={"center"} p={isMobile ? "0" : ".5rem"}>
                      Type
                    </Th>
                  )}
                  {!isMobile && (
                    <Th p={isMobile ? "0" : ".5rem"} width="10rem">
                      Date Added
                    </Th>
                  )}
                  <Th textAlign={"center"} p={isMobile ? "0" : ".5rem"}>
                    Actions
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                <ProjectReportPanelTableContent contentData={contentData} />
              </Tbody>
            </Table>
          </Box>
        </Stack>
      )}
      {projectId && (
        <ProjectReportPanelNewReportModal
          onClose={onProjectReportSelectorClose}
          isOpen={isProjectReportSelectorOpen}
          collectionId={projectId}
        />
      )}
    </>
  );
};
