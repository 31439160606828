import type { PlanName } from "api/subscription/models/PlanName";

export const CopiesKeys = [
  "copy_subscription_features",
  "copy_subscription_title",
  "copy_subscription_panel_list",
  "copy_subscription_plan",
  "copy_subscription_plan_price",
  "copy_upgrade_modal_title",
  "copy_upgrade_modal_list",
  "copy_upgrade_modal_link",
  "copy_upgrade_modal_link_label",
  "copy_onboarding_modal_step1_title",
  "copy_onboarding_modal_step1_subtitle",
  "copy_onboarding_modal_step1_list",
  "copy_onboarding_modal_step2_title",
  "copy_onboarding_modal_step2_subtitle",
  "copy_onboarding_modal_step2_list",
  "copy_login_list",
  "copy_maintenance_banner",
  "copy_maintenance_banner_bg_color",
  "copy_maintenance_banner_text_color",
  "copy_ticker_placeholder_text",
  "copy_promotion_code",
] as const;
export type CopyTypedKey = typeof CopiesKeys[number];

export interface CopiesTypeMapping {
  copy_subscription_features: string[];
  copy_subscription_title: string;
  copy_subscription_panel_list: string[];
  copy_subscription_plan: PlanName;
  copy_subscription_plan_price: string;
  copy_upgrade_modal_title: string;
  copy_upgrade_modal_list: string[];
  copy_upgrade_modal_link: string;
  copy_upgrade_modal_link_label: string;
  copy_onboarding_modal_step1_title: string;
  copy_onboarding_modal_step1_subtitle: string;
  copy_onboarding_modal_step1_list: string[];
  copy_onboarding_modal_step2_title: string;
  copy_onboarding_modal_step2_subtitle: string;
  copy_onboarding_modal_step2_list: string[];
  copy_login_list: string[];
  copy_maintenance_banner: string;
  copy_maintenance_banner_bg_color: string;
  copy_maintenance_banner_text_color: string;
  copy_ticker_placeholder_text: string;
  copy_promotion_code: string;
}

export type CopyValueMap = {
  [key in CopyTypedKey]: CopiesTypeMapping[key];
};

export type CopyValueType<K extends keyof CopyValueMap> = CopyValueMap[K];

export const defaultCopiesValues: CopiesTypeMapping = {
  copy_ticker_placeholder_text: "Enter the ticker symbol that you would like me to analyze",
  copy_subscription_features: [
    "Access to trusted FactSet Data | true | false | true | true | true",
    "Access to Nasdaq Real Time Data | true | false | true | true | true",
    "Automatically collect and analyze information from SEC, EDGAR & SEDAR | true | true | true | true | true",
    "Augment research using web content and internet search | true | true | true | true | true",
    "Number of Concurrent Equity Research Reports Analysis | 2 | 6 | 10 | 20 | Custom",
    "Number of individual Equity Research Reports per month | 3 | 15 | 40 | 100 | Custom",
    "Number of Easy Project Reruns and re-analysis per project per month | 0 | 1 | 4 | 10 | Custom",
    "Number of Personalized Research Questions per month | 45 | 225 | 600 | 1500 | Custom",
    "Data archiving for projects, questions and outcomes | 6 Months | 6 Months | 1 Year | 3 Years | Custom",
  ],
  copy_subscription_title: "Upgrade to continue receiving the benefits of Autopilot",
  copy_subscription_panel_list: [
    "Trusted and verified AI results with data direct from FactSet",
    "Deeper insights with Multidimensional AI for better analysis and decisions",
  ],
  copy_subscription_plan: "professional",
  copy_subscription_plan_price: "99",
  copy_upgrade_modal_title: "Upgrade to analyze your next equity!",
  copy_upgrade_modal_list: [
    "Allow AI to continue to put the market and data research on Autopilot so you can spend more time analyzing",
    "Avoid the endless hours sitting in front of a prompt and get AI to do the work while you commute, meet, call or even sleep",
  ],
  copy_upgrade_modal_link: "https://portal.productboard.com/charli/1-product-portal/tabs/2-planned",
  copy_upgrade_modal_link_label: "Click here to learn about new features coming soon!",
  copy_onboarding_modal_step1_title: "Enter a ticker to get started",
  copy_onboarding_modal_step1_subtitle:
    "Enter a ticker for the equity research that you are doing today, and Charli will fetch the data and do an analysis",
  copy_onboarding_modal_step1_list: [
    "Charli will get real-time information from Nasdaq, FactSet, EDGAR, SEDAR and the internet",
    "Once the analysis is complete Charli will email you the summary report and you can dig deeper from there",
    "While Charli analyzes your first ticker you can start running multiple tickers while you grab a coffee or take a meeting",
  ],
  copy_onboarding_modal_step2_title: "Learn More About Charli",
  copy_onboarding_modal_step2_subtitle:
    "While Charli works on your first equity analysis, watch this video to learn the power behind questions",
  copy_onboarding_modal_step2_list: [
    "While Charli works on your first equity analysis, watch the video to learn the power behind questions",
    "Once the analysis is complete Charli will email you the summary report that you can review and forward",
    "You can dig deeper from there, and Charli remembers your questions for the next time you need an analysis",
  ],
  copy_login_list: [
    "SlideImage1 | Time is Money | Charli does the work and updates key ratings so you can quickly  glance at  the changes in your portfolio. | Time is Money, Quickly Glance at Changes in your Portfolio",
    "SlideImage2 | Peace of Mind with Trusted Data | Purpose built for the financial services industry to ensure your data and interactions are secure and private, and can be fully audited. | Peace of Mind with Trusted Data",
    "SlideImage3 | Look forward to Earnings Season Again | Effortlessly rerun your existing portfolio before and after earnings and get updated insights and reports according to your tailored investment thesis. | Look Forward to Earnings Season again",
  ],
  copy_maintenance_banner: "",
  copy_maintenance_banner_bg_color: "orange",
  copy_maintenance_banner_text_color: "gray.800",
  copy_promotion_code: "PRO-INTRO-PRICING",
};

export function isDefinedCopyKey(key: string): key is CopyTypedKey {
  return (CopiesKeys as readonly string[]).includes(key);
}
