import toInteger from "lodash/toInteger";
import moment from "moment";
import { formatDate, formatBytes, formatCurrency } from "screens/common/modal/formatters";
import { formatPath, getFileTypeNameByExtension } from "screens/common/modal/utils";
import type { ContentDetails } from "types/content/ContentDetails";
import type { GroupByOption, GroupBySortDirection, SortDirection, SortOption } from "types/SortingAndGroupingPreferences";

export const isValidUUID = (uuid: string): boolean => {
  const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
  return uuidRegex.test(uuid);
};

export const capitalizeWords = (text: string) => {
  return text.replace(/\b\w/g, (char) => char.toUpperCase());
};

export const getIntentLabel = (intent: string | undefined) => {
  switch (intent) {
    case "file_document":
    case "upload_new_file":
    case "new_file_upload":
    case "add_resources":
      return "Upload";
    case "find":
    case "retrieve_documents":
      return "Find";
    case "share":
    case "share_documents":
    case "share_resources":
      return "Share";
    case "accept_share":
      return "Accept Share";
    case "store_link":
    case "add_link":
      return "Link";
    case "process_receipt":
      return "Expense";
    case "prepare_expense_report":
    case "create_expense_spreadsheet":
      return "Expense Report";
    case "organize":
      return "Organize";
    case "analyze":
      return "Analyze";
    case "find_archived":
      return "Show Archive";
    case "store_collection":
      return "Create Project";
    case "add_collection_content":
    case "remove_collection_content":
    case "delete_collection_metadata":
      return "Project Updated";
    case "cancel_project":
    case "delete_collection":
      return "Delete Project";
    case "summarize":
    case "generate_summary":
      return "Summarize";
    case "highlight":
      return "Highlight";
    case "extract_highlight":
      return "Extract Highlights";
    case "create_reminder":
      return "Reminder";
    case "prepare_meeting_report":
      return "Prepare Meeting Report";
    case "create_meeting":
      return "Create Meeting";
    case "archive":
      return "Archive";
    case "restore":
      return "Restore";
    case "research":
      return "AI Research";
    case "affirm":
      return "Confirmed";
    case "deny":
      return "Rejected";
    case "publish_cap_table":
      return "Publish";
    case "help":
    case "retrieve_supported_activities":
      return "Help";
    case "how":
      return "How did you do that";
    default:
      return "Command Line";
  }
};

export const getParentCellType = (contentData: ContentDetails) => {
  if (contentData.type === "document") {
    return "file";
  } else if (contentData.urls && contentData.urls.url?.includes("/collection/")) {
    return "collection";
  } else {
    return contentData.type;
  }
};

export const formatValue = (value: string | number | Date | null, type: string, context?: string, entityName?: string) => {
  if (value === null || value === undefined) {
    return "";
  }
  const isDate = moment(value, "YYYY-MM-DD", true).isValid() || type === "date";

  if (type === "number") {
    return Number(value).toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  } else if (type === "hyperlink") {
    return formatPath(value.toString());
  } else if (type === "date") {
    return formatDate(value);
  } else if (type === "byte") {
    return formatBytes(toInteger(value));
  } else if (type === "currency") {
    return formatCurrency(Number(value), context);
  } else if (
    entityName &&
    (entityName.toLowerCase().includes("tax") || entityName.toLowerCase().includes("price")) &&
    (type === "number" || type === "string") &&
    context?.toLowerCase() !== "any"
  ) {
    const removeCurrencySymbol = value.toString().replace("$", "").replace(",", "").replace(",", "");
    if (Number(removeCurrencySymbol)) {
      return formatCurrency(Number(removeCurrencySymbol), context);
    }
    return value;
  } else if (isDate) {
    return formatDate(new Date(value), "do MMM yyyy");
  } else {
    return value;
  }
};

export const getExtension = (contentData: ContentDetails) => {
  if (contentData.type === "dynamic_data") {
    if (contentData.category?.toLowerCase() === "location") {
      return "GIS";
    } else if (contentData.category?.toLowerCase() === "land_title") {
      return "LTSA";
    } else if (contentData.category?.toLowerCase() === "company_details") {
      return "SEC";
    } else if (contentData.category?.toLowerCase() === "company_filings") {
      return "SEC";
    } else {
      return "DYNAMIC DATA";
    }
  } else if (contentData.urls && contentData.urls.url?.includes("/collection/")) {
    return "collection";
  } else {
    return contentData.extension ? contentData.extension : contentData.type;
  }
};

// this is used to get the types for sharing content
export const getItemTypeForContent = (contentType: string) => {
  switch (contentType) {
    case "document":
      return "file_id";
    case "link":
      return "link_id";
    case "expense":
      return "expense_id";
    case "collection":
      return "collection_id";
    default:
      return "content_id";
  }
};

export const getDateFromContent = (content: ContentDetails) => {
  if (content.type === "document") {
    const dateAsdate = new Date(content.timeLastModified);
    return dateAsdate.getTime();
  } else if (content.type === "expense") {
    const dateAsdate = new Date(content.timeLastModified);
    return dateAsdate.getTime();
  } else if (content.type === "link" || content.type === "collection") {
    const dateAsdate = new Date(content.timeCreated);
    return dateAsdate.getTime();
  }
  return Date.now();
};

export const getContentAgeFromContent = (content: ContentDetails) => {
  const cellDate = getDateFromContent(content);

  if (typeof cellDate === "string" || isNaN(Number(cellDate))) {
    return "Unknown";
  }

  const currentDateTime = Date.now();
  const dateTimeDifference = currentDateTime - cellDate;
  const millisecondsInDay = 1000 * 60 * 60 * 24;
  const ageOfFileInDays = Math.round(dateTimeDifference / millisecondsInDay);

  if (ageOfFileInDays <= 7) {
    return "Less than a week ago";
  }
  if (ageOfFileInDays <= 31) {
    return "Less than a month ago";
  }
  if (ageOfFileInDays <= 91) {
    return "One month to three months ago";
  }
  if (ageOfFileInDays <= 183) {
    return "Three months to six months ago";
  }
  if (ageOfFileInDays <= 365) {
    return "Six months to a year ago";
  }
  return "More than a year ago";
};

export const getFolderFromContent = (content: ContentDetails) => {
  if (content.type === "document") {
    return "Files";
  } else if (content.type === "expense") {
    return "Expenses";
  } else if (content.type === "link") {
    return "Links";
  } else if (content.type === "collection") {
    return "Collections";
  }
  return "Uncategorized";
};

export const getFileTypeExtensionFromContent = (content: ContentDetails) => {
  if (content.type === "document") {
    return content.extension;
  } else if (content.type === "expense") {
    return "exp";
  } else if (content.type === "link") {
    return "lnk";
  } else if (content.type === "collection") {
    return "col";
  }
  return "";
};

export const getFileTypeNameFromContent = (content: ContentDetails) => {
  return getFileTypeNameByExtension(getFileTypeExtensionFromContent(content));
};

export const getTitleFromContent = (content: ContentDetails) => {
  if (content.type === "document" && content.name) {
    return content.name;
  } else if (content.type === "expense") {
    return "exp";
  } else if (content.type === "link") {
    return "lnk";
  } else if (content.type === "collection") {
    return "col";
  }
  return "";
};
export const getContentSource = (content: ContentDetails) => {
  const contentDetailSection = content?.detailSections.filter((section) => section.sectionName.includes("content_details"));
  const sourceUrl =
    contentDetailSection.length && (contentDetailSection[0].data.filter((data) => data.label === "Link")[0].value as string);
  const getDomain = (url: string) => {
    const domain = url.replace("https://", "").replace("http://", "").split("/")[0];
    return domain;
  };

  if (content.type === "link") {
    return sourceUrl ? getDomain(sourceUrl) : undefined;
  }
  return "";
};

export const getCanvasContentSortedByGroup = (
  canvasCells: ContentDetails[],
  groupByType: GroupByOption,
  groupBySortDirection: GroupBySortDirection
) => {
  const sortedCells = [...canvasCells];

  if (groupByType === "Age") {
    sortedCells.sort((a, b) => {
      const left = groupBySortDirection === "ASC" ? a : b;
      const right = groupBySortDirection === "ASC" ? b : a;

      return getDateFromContent(left) - getDateFromContent(right);
    });
  }

  if (groupByType === "File Type") {
    sortedCells.sort((a, b) => {
      const left = groupBySortDirection === "ASC" ? a : b;
      const right = groupBySortDirection === "ASC" ? b : a;

      return getFileTypeNameFromContent(left).toLowerCase().localeCompare(getFileTypeNameFromContent(right).toLowerCase());
    });
  }

  return sortedCells;
};

export function getDetailFromContent(contentData: ContentDetails[], key: string) {
  return contentData.flatMap((content) => {
    if (content[key]) {
      return content[key];
    } else {
      return [];
    }
  });
}

export function getMetatDataForContent(contentData: ContentDetails) {
  const contentMetadata: {
    fileType: string;
    extension?: string;
    intent: string;
    context: string;
    ui_source_operation: string;
    itemId: { label: string; value: string };
    mediaId: string;
  }[] = [];

  if (contentData?.type === "expense") {
    contentMetadata.push({
      fileType: "expense_id",
      intent: "edit_expense",
      context: "expense",
      ui_source_operation: "STORE_EXPENSE",
      itemId: { label: "expense_id", value: contentData.mediaId },
      mediaId: contentData.mediaId,
    });
  } else if (contentData?.type === "link") {
    contentMetadata.push({
      fileType: "link_id",
      intent: "edit_link",
      context: "link",
      ui_source_operation: "SEARCH",
      itemId: { label: "link_id", value: contentData.mediaId },
      mediaId: contentData.mediaId,
    });
  } else if (contentData?.type === "document") {
    contentMetadata.push({
      fileType: "file_id",
      extension: contentData.extension,
      intent: "edit_file_metadata",
      context: "document",
      ui_source_operation: "SUMMARIZE",
      itemId: { label: "file_id", value: contentData?.mediaId },
      mediaId: contentData.mediaId,
    });
  } else if (contentData?.type === "dynamic_data") {
    contentMetadata.push({
      fileType: "dynamic_data_id",
      extension: contentData.extension,
      intent: "edit_dynamic_metadata",
      context: "dynamic_data",
      ui_source_operation: "SUMMARIZE",
      itemId: { label: "dynamic_data_id", value: contentData?.mediaId },
      mediaId: contentData.mediaId,
    });
  }
  return contentMetadata[0];
}

export const getSortedContentCells = (canvasCells: ContentDetails[], sortOption: SortOption, sortDirection: SortDirection) => {
  const sortedCells = [...canvasCells];

  if (sortOption === "Relevance") {
    if (sortDirection === "ASC") {
      sortedCells.reverse();
    } else {
      // The default sort returned in the payload is relevance descending
      return sortedCells;
    }
  }

  if (sortOption === "Title") {
    sortedCells.sort((a, b) => {
      const left = sortDirection === "ASC" ? a : b;
      const right = sortDirection === "ASC" ? b : a;

      return getTitleFromContent(left).toLowerCase().localeCompare(getTitleFromContent(right).toLowerCase());
    });
  }

  if (sortOption === "Date") {
    sortedCells.sort((a, b) => {
      const left = sortDirection === "ASC" ? a : b;
      const right = sortDirection === "ASC" ? b : a;

      return getDateFromContent(left) - getDateFromContent(right);
    });
  }

  return sortedCells;
};
