import { Stack, Image, HStack, useBreakpointValue, Box, useToast, Text } from "@chakra-ui/react";
import type { PlanName } from "api/subscription/models/PlanName";
import { useFeatureFlags, useUserProfile } from "hooks";
import React from "react";
import { useConversationContext } from "screens/thread/ConversationContext";
import verifiedShield from "screens/common/static/misc/verified_shield.svg";
import soc2logo from "screens/common/static/logos/soc2_logo.png";
import { SubscribeForm } from "./SubscribeForm";
import { ToastMessageContent } from "screens/common/components";
import { subscribeToPlan, updateCustomerDetails } from "api/subscription";
import { downloadSubscriptionDetails } from "state/subscription/operations";
import { useDispatch } from "react-redux";
import isEmpty from "lodash/isEmpty";
import { useCopyValue } from "hooks/useCopies";
import capitalize from "lodash/capitalize";

export interface Feature {
  name: string;
  trial: boolean | string;
  business: boolean | string;
}

export const SubscriptionUpgradeConfirm = ({ upgradePlan = "business" }: { upgradePlan: PlanName }) => {
  const dispatch = useDispatch();
  const toast = useToast();
  const { onSubscriptionModalClose } = useConversationContext();
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });
  const { id: userId } = useUserProfile();
  const copySubscriptionPlan = useCopyValue("copy_subscription_plan");
  const defaultPromotionCode = useCopyValue("copy_promotion_code");
  const { enable_credit_card_processing: isCreditCardProcessingEnabled } = useFeatureFlags();

  const onSubscribeFormSubmit = async (fields: {
    firstName: string;
    lastName: string;
    organization: string;
    city: string;
    address: string;
    address2: string;
    country: string;
    state: string;
    postcode: string;
    acceptTerms: boolean;
    promotionCode?: string;
    creditCardToken?: string;
    chargifyGatewayHandle?: string;
  }) => {
    const {
      firstName,
      lastName,
      address,
      address2,
      country,
      state,
      postcode,
      city,
      promotionCode,
      creditCardToken,
      chargifyGatewayHandle,
    } = fields;

    await updateCustomerDetails(String(userId), {
      firstName,
      lastName,
      address,
      address2,
      country,
      state,
      city,
      zip: postcode,
    });

    // TODO - Remove "PRO-INTRO-PRICING" once we have a proper way to handle promo codes in Billing admin
    const promoCode = isEmpty(promotionCode) ? defaultPromotionCode : promotionCode;
    const creditCardDetails = isCreditCardProcessingEnabled
      ? {
          token: creditCardToken,
          gatewayHandle: chargifyGatewayHandle,
        }
      : undefined;

    try {
      await subscribeToPlan(String(userId), copySubscriptionPlan, promoCode, creditCardDetails);
    } catch (error) {
      toast({
        title: "Upgrade failed",
        description: "We apologize, we are unable upgrade your subscription at this time. Please contact support or try again later",
        status: "error",
        duration: 10000,
        isClosable: true,
        position: "top-right",
      });
      return;
    } finally {
      dispatch(downloadSubscriptionDetails());
      onSubscriptionModalClose();
    }

    toast.closeAll();
    toast({
      render: ({ onClose }) => (
        <ToastMessageContent
          message={"Thank you for confirming the upgrade, I will send you a follow-up email shortly."}
          onClick={() => {
            onClose();
          }}
          onClose={onClose}
        />
      ),
      duration: 5000,
      isClosable: true,
      position: "top-right",
    });
  };

  return (
    <Stack justifyContent={"space-between"} height="100%" minHeight="unset" spacing="0" width="100%">
      <Stack spacing="0" height="100%" justifyContent={"space-between"}>
        <Stack height="100%" spacing="1rem" bgColor="primary.default" py="1rem" px="2rem" color="white">
          <Text fontWeight="semibold" fontSize="xl">
            Upgrade to the {capitalize(upgradePlan)} Plan
          </Text>
        </Stack>
        <Box height="100%" p={isMobile ? "0.5rem" : "2rem"} pt="2rem">
          <SubscribeForm onSubmit={onSubscribeFormSubmit} />
        </Box>
        {!isMobile && (
          <HStack position="absolute" bottom="2rem" right="2rem" align={"center"} spacing="1rem">
            <Image src={soc2logo} height="2.7rem" />
            <Image src={verifiedShield} height="2.5rem" />
          </HStack>
        )}
      </Stack>
    </Stack>
  );
};
