import React, { useMemo } from "react";
import { Image, useColorModeValue } from "@chakra-ui/react";
import type { FunctionComponent } from "react";
import { useContentFromCollectionOrContents, useThumbnail } from "hooks";
import type { Props } from "./ContentImageWrapper";

export const ContentImage: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  thumbnailWidth,
  thumbnailHeight,
  fit = "contain",
  thumbnailFit = "contain",
  thumbnailPosition = "top",
  thumbnailBorderRadius,
  onClick,
  metadataId,
  bgColor,
  maxHeight,
}) => {
  const contentData = useContentFromCollectionOrContents(metadataId);
  const { thumbnailUrlForFile, thumbnailUrlForLink } = useThumbnail();
  const roundedThumbnailWidth = thumbnailWidth && String(Math.round(Number(thumbnailWidth.replace("px", ""))));
  const roundedThumbnailHeight = thumbnailHeight && String(Math.round(Number(thumbnailHeight.replace("px", ""))));
  const bgColorDefault = useColorModeValue("transparent", "transparent");
  const imageBrightness = useColorModeValue("100%", "80%");

  const thumbnailUrl = useMemo(() => {
    if (!contentData) {
      return undefined;
    }

    if (contentData.type === "link" && contentData.urls) {
      return contentData.urls.url
        ? thumbnailUrlForLink(
            contentData.mediaId,
            contentData.urls.url,
            roundedThumbnailWidth,
            roundedThumbnailHeight,
            thumbnailFit,
            thumbnailPosition,
            contentData.id
          )
        : undefined;
    } else {
      return thumbnailUrlForFile(
        contentData?.mediaId,
        roundedThumbnailWidth,
        roundedThumbnailHeight,
        thumbnailFit,
        thumbnailPosition,
        contentData.id
      );
    }
  }, [
    contentData,
    roundedThumbnailHeight,
    roundedThumbnailWidth,
    thumbnailFit,
    thumbnailPosition,
    thumbnailUrlForFile,
    thumbnailUrlForLink,
  ]);

  return (
    <Image
      fit={fit}
      src={thumbnailUrl}
      borderRadius={thumbnailBorderRadius}
      backgroundColor={bgColor || bgColorDefault}
      border="none"
      flexShrink={0}
      width={thumbnailWidth}
      height={thumbnailHeight}
      maxHeight={maxHeight}
      filter={`brightness(${imageBrightness})`}
      onClick={(event) => {
        if (onClick) {
          onClick();
          event.stopPropagation();
        }
      }}
      loading="lazy"
      {...(onClick && { cursor: "pointer" })}
    />
  );
};
