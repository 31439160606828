import { SearchIcon } from "@chakra-ui/icons";
import { Input, InputGroup, InputLeftElement } from "@chakra-ui/react";
import { useButtonProps } from "hooks";
import React from "react";

interface IProps {
  placeholder: string;
  searchText: string;
  setSearchText: React.Dispatch<React.SetStateAction<string>>;
}

export const FilterInput = ({ placeholder, searchText, setSearchText }: IProps) => {
  const secondaryButtonProps = useButtonProps("sm", "secondary");

  return (
    <InputGroup size="xs">
      <InputLeftElement pointerEvents="none" children={<SearchIcon color="gray.300" />} />
      <Input {...secondaryButtonProps} value={searchText} placeholder={placeholder} onChange={(e) => setSearchText(e.target.value)} />
    </InputGroup>
  );
};
