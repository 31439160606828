import { createSlice, createAction } from "@reduxjs/toolkit";
import { REHYDRATE } from "redux-persist";
import { downloadWorkflows } from "state/workflow/operations";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { Workflow } from "types/workflows/workflow";
import uniq from "lodash/uniq";
import difference from "lodash/difference";
import isEqual from "lodash/isEqual";
import assign from "lodash/assign";
import omit from "lodash/omit";

const rehydrate = createAction<WorkflowState>(REHYDRATE);

function normalizeWorkflowResponse(workflow: Workflow) {
  return {
    workflow: {
      ...workflow,
    },
  };
}

function setNormalizedWorkflowState(state: WorkflowState, { workflow }: { workflow: NormalizedWorkflow }) {
  if (!state.workflowsById[workflow.id]) {
    state.workflowsIds.push(workflow.id);
  }

  if (!isEqual(state.workflowsById[workflow.id], workflow)) {
    state.workflowsById[workflow.id] = workflow;
  }
}

type NormalizedWorkflow = Workflow;

interface WorkflowState {
  isLoading: boolean;
  workflowsById: Record<string, NormalizedWorkflow>;
  workflowsIds: string[];
  isLoadingWorkflowMap: Record<string, boolean>;
  queuedWorkflowIds: string[];
}

const initialState: WorkflowState = {
  isLoading: false,
  workflowsById: {},
  workflowsIds: [],
  isLoadingWorkflowMap: {},
  queuedWorkflowIds: [],
};

export const { actions, reducer } = createSlice({
  name: "workflow",
  initialState,
  reducers: {
    flush() {
      return initialState;
    },
    removeFromQueuedWorkflowIds(state, action: PayloadAction<string[]>) {
      return {
        ...state,
        queuedWorkflowIds: difference(state.queuedWorkflowIds, action.payload),
      };
    },
    queueWorkflowIds(state, action: PayloadAction<string[]>) {
      return {
        ...state,
        queuedWorkflowIds: uniq([...state.queuedWorkflowIds, ...action.payload]),
      };
    },
    removeWorkflow(state, action: PayloadAction<string>) {
      state.workflowsIds = state.workflowsIds.filter((id) => id !== action.payload);

      delete state.isLoadingWorkflowMap[action.payload];
      delete state.workflowsById[action.payload];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(rehydrate, (_, action) => {
      if (action.payload?.workflowsById) {
        const { workflowsIds, workflowsById } = action.payload;

        return {
          ...initialState,
          workflowsIds,
          workflowsById,
          isLoadingWorkflowMap: {},
          queuedWorkflowIds: [],
        };
      }
    });
    builder.addCase(downloadWorkflows.pending, (state, action) => {
      state.isLoading = true;
      assign(
        state.isLoadingWorkflowMap,
        action.meta.arg.workflowIds.reduce((acc, workflowId) => ({ ...acc, [workflowId]: true }), {})
      );
    });
    builder.addCase(downloadWorkflows.rejected, (state, action) => {
      state.isLoading = false;
      state.isLoadingWorkflowMap = omit(state.isLoadingWorkflowMap, action.meta.arg.workflowIds);
    });
    builder.addCase(downloadWorkflows.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isLoadingWorkflowMap = omit(state.isLoadingWorkflowMap, action.meta.arg.workflowIds);

      action.payload.forEach((workflowPayload) => {
        const { workflow } = normalizeWorkflowResponse(workflowPayload);

        setNormalizedWorkflowState(state, { workflow });
      });
    });
  },
});

export default reducer;
