import {
  Box,
  Stack,
  useBreakpointValue,
  Icon,
  Tooltip,
  useDisclosure,
  Button,
  IconButton,
  useToast,
  useColorModeValue,
  keyframes,
  usePrefersReducedMotion,
  Text,
  Center,
  Flex,
  useColorMode,
  Tag,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState, useContext, useMemo } from "react";
import { CollectionsFilterMenu } from "../collections/CollectionsFilterMenu";
import { ProjectLandingTiles } from "../project/ProjectLandingTiles";
import {
  useButtonProps,
  useCollection,
  useConfigMap,
  useGetViewConfig,
  useProjectParams,
  useSidebarNavigation,
  useUserPreference,
  useEntitlementKey,
  useCollectionKey,
  useTileProps,
} from "hooks";
import { getViewConfig } from "configs/configMap";
import { DEFAULT_SIDEBAR_WIDTH, LandingTitle } from "screens/landing/components";
import { ProjectMetrics } from "../collections/ProjectMetrics";
import { AddIcon } from "@chakra-ui/icons";
import { useAddToCharliContext } from "screens/panels/addToCharli/AddToCharliWizard/AddToCharliProvider";
import { useConversationContext } from "screens/thread/ConversationContext";
import { PausedWorkflowModalProvider } from "screens/collection/views/ProjectActions/PausedWorkflowModal";
import { LuSettings2 } from "react-icons/lu";
import { HiOutlineRefresh } from "react-icons/hi";
import { useDispatch } from "react-redux";
import { sendMessage } from "state/websocket/operations";
import { v4 as uuid } from "uuid";
import type { RequestEntities } from "types/charliui";
import { ToastMessageContent } from "screens/common/components";
import { FloatingDialogModal } from "components/FloatingDialogModal";
import { CollectionsFilterContext } from "../collections";
import { useProjectsHaveWorkflowSchedules } from "hooks/useWorkflowSchedules";
import { getStatusColor, WorkflowStatusIcon } from "screens/common/components/WorkflowStatusIcon";
import { useWorkflowSchedulesByPortfolio } from "hooks/useWorkflowSchedules";
import type { TimeOfDay } from "./projectLandingTileLayouts/components/PortfolioSettingsPanel";
import { timeRangeByTimeOfDay } from "./projectLandingTileLayouts/components/PortfolioSettingsPanel";

const spin = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`;

interface Props {
  collectionType: string;
}

export const ProjectLanding: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({ collectionType }) => {
  const { projectFilter, projectId } = useProjectParams();
  const portfolioProject = useCollection(projectFilter);
  const configMap = useConfigMap();
  const { filteredCollections, selectedQuadrant, searchText, selectedRating } = useContext(CollectionsFilterContext);
  const portfolioCollectionType = getViewConfig("portfolioProjectType", portfolioProject?.collectionType || "", configMap);
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });
  const projectTitle = useGetViewConfig("title", portfolioCollectionType || projectFilter, configMap);
  const commonButtonProps = useButtonProps("sm", "primary");
  const ctaButtonProps = useButtonProps("sm", "cta");
  const { isOpen: isFilterOpen, onToggle: onFilterToggle, onClose: onFilterClose } = useDisclosure();
  const { isDialogOpen, onDialogOpen, onDialogClose } = useConversationContext();
  const { onPortfolioSettingsOpen } = useAddToCharliContext();
  const isDueDiligence = portfolioCollectionType === "due_diligence" || collectionType === "due_diligence";
  const isDueDiligenceLanding = projectFilter === "due_diligence" && !projectId;
  const sidebarWidth = (useUserPreference("ui_sidebar_width") as number) || (DEFAULT_SIDEBAR_WIDTH as number);
  const { currentSidebarType } = useSidebarNavigation();
  const dispatch = useDispatch();
  const hasRerunAllProjects = useEntitlementKey("enable_rerun_projects");
  const toast = useToast();
  const addTickerButtonRef = useRef<HTMLButtonElement>(null);
  const bgColor = useColorModeValue("white", "gray.800");
  const hasPrefersReducedMotion = usePrefersReducedMotion();
  const [isRerunning, setIsRerunning] = useState(false);
  const iconColor = useColorModeValue("200", "800");
  const textColor = useColorModeValue("primary.darkGray", "gray.400");
  const textColorOverlay = useColorModeValue("gray.400", "gray.500");
  const bgColorOverlayText = useColorModeValue("white", "gray.700");
  const bgColorHeader = useColorModeValue("gray.200", "gray.800");
  const { colorMode } = useColorMode();
  const showProjectMetrics = useEntitlementKey("ui_project_metrics");
  const shareDetails = useCollectionKey(projectFilter, "shareDetails");
  const bgColorReadOnly = useColorModeValue("orange.100", "gray.500");
  const commonTileProps = useTileProps();

  const workflowSchedule = useWorkflowSchedulesByPortfolio(projectFilter || "");
  const workflowScheduleFrequency = useMemo(() => workflowSchedule[workflowSchedule.length - 1]?.frequency, [workflowSchedule]);
  const workflowScheduleDesiredTime = useMemo(() => {
    const period = workflowSchedule[workflowSchedule.length - 1]?.desiredTime as TimeOfDay;
    return `between ${timeRangeByTimeOfDay[period]}`;
  }, [workflowSchedule]);
  const workflowScheduleDesiredDay = useMemo(() => {
    const dayMap = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const day = workflowSchedule[workflowSchedule.length - 1]?.day;
    if (!day) return "";
    if (workflowScheduleFrequency === "weekly") {
      return day >= 1 && day <= 7 ? `on ${dayMap[day]}` : "";
    } else {
      if (day < 1 || day > 31) return "";
      const suffix = ["th", "st", "nd", "rd"];
      const v = day % 100;
      const formattedDay = day + (suffix[(v - 20) % 10] || suffix[v] || suffix[0]);
      return `on the ${formattedDay}`;
    }
  }, [workflowSchedule, workflowScheduleFrequency]);

  const activeProjectsIds = useMemo(
    () => filteredCollections.flatMap((collection) => (collection.isActiveProjectInGroup ? [collection.id] : [])),
    [filteredCollections]
  );

  const doesProjectsHaveScheduledWorkflows = useProjectsHaveWorkflowSchedules(activeProjectsIds);
  const animation = hasPrefersReducedMotion ? undefined : `${spin} 1s linear infinite`;
  const showOverlay = filteredCollections.length === 0 && !selectedQuadrant && searchText === "" && !selectedRating;

  const handleRerunAllProjects = () => {
    if (!portfolioProject || isRerunning) {
      return;
    }

    const newConversationId = uuid();

    const entities: RequestEntities = [
      {
        entity: "portfolio_id",
        value: portfolioProject.id,
      },
    ];

    dispatch(
      sendMessage({
        conversationId: newConversationId,
        intent: "/rerun_portfolio",
        entities,
      })
    );

    setIsRerunning(true);

    toast.closeAll();

    toast({
      render: ({ onClose }) => (
        <ToastMessageContent
          message="All projects have been scheduled to rerun."
          onClick={() => {
            onClose();
          }}
          onClose={onClose}
        />
      ),
      duration: 5000,
      isClosable: true,
      position: "top-right",
    });
  };

  const hasRerunAllProjectsButton = !!portfolioProject && hasRerunAllProjects;

  useEffect(() => {
    onFilterClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portfolioProject]);

  useEffect(() => {
    setIsRerunning(doesProjectsHaveScheduledWorkflows);
  }, [doesProjectsHaveScheduledWorkflows]);

  return (
    <>
      <Box height="100%" width="100%" maxWidth={`calc(100vw - ${currentSidebarType === "hidden" ? 0 : sidebarWidth}px)`} overflow="hidden">
        {isDueDiligence && (
          <Box
            width="100%"
            bgColor={bgColor}
            zIndex={3}
            position={isMobile ? "relative" : "fixed"}
            top={isMobile ? "0" : "2.6rem"}
            left={currentSidebarType === "hidden" ? 0 : `${sidebarWidth}px`}
            height="auto">
            {isDueDiligenceLanding ? (
              <Flex
                bgColor={bgColorHeader}
                justifyContent="center"
                alignItems="center"
                height={"2.6rem"}
                width={`calc(100vw - ${currentSidebarType === "hidden" ? 0 : sidebarWidth}px)`}
              />
            ) : (
              <Box position="relative">
                <ProjectMetrics />
                {showProjectMetrics && showOverlay && (
                  <>
                    <Center
                      position="absolute"
                      top="50%"
                      left="0"
                      right="0"
                      transform="translateY(-50%)"
                      zIndex={4}
                      maxWidth={`calc(100vw - ${currentSidebarType === "hidden" ? 0 : sidebarWidth}px)`}>
                      <Text
                        textAlign={"center"}
                        bgColor={bgColorOverlayText}
                        px="1rem"
                        py=".5rem"
                        borderRadius={"md"}
                        fontSize="xl"
                        color={textColorOverlay}
                        fontWeight="medium">
                        Charts will populate as new tickers are added
                      </Text>
                    </Center>
                    <Flex
                      className="ch-project-landing-overlay"
                      position="absolute"
                      top={39}
                      left={0}
                      right={0}
                      bottom={0}
                      bgColor={bgColor}
                      opacity={colorMode === "light" ? 0.5 : 0.7}
                      height={isDueDiligenceLanding ? "2.6rem" : "23.6rem"}
                      width={`calc(100vw - ${currentSidebarType === "hidden" ? 0 : sidebarWidth}px)`}
                    />
                  </>
                )}
              </Box>
            )}
            <LandingTitle
              mt={isDueDiligenceLanding ? "1rem" : "0"}
              maxWidth={`calc(100vw - ${currentSidebarType === "hidden" ? 0 : sidebarWidth}px)`}
              pl="1rem"
              pr=".5rem"
              underline={!isMobile}
              text={
                isMobile
                  ? ""
                  : portfolioProject
                  ? `${portfolioProject.name}`
                  : `${projectTitle ? (projectTitle.toLowerCase().includes("projects") ? projectTitle : `${projectTitle} Projects`) : ""}`
              }
              titleMaxWidth="15rem"
              color="primary.default">
              {workflowScheduleFrequency && workflowScheduleFrequency !== "one-time" && (
                <Stack direction="row" width="100%">
                  <Center>
                    <Box
                      onClick={() => {
                        onPortfolioSettingsOpen();
                      }}
                      p="3px"
                      borderRadius={"full"}
                      backgroundColor={`${getStatusColor("scheduled")}.${iconColor}`}
                      border={"none"}
                      className="ch-workflow-step"
                      textAlign="left"
                      cursor="pointer">
                      <Center>
                        <WorkflowStatusIcon boxSize="1.6rem" status={"scheduled"} />
                      </Center>
                    </Box>
                  </Center>
                  {!isMobile && (
                    <Center>
                      <Text fontSize="sm" color={textColor} fontWeight="medium">
                        {`Scheduled to refresh ${workflowScheduleFrequency} ${workflowScheduleDesiredDay} ${workflowScheduleDesiredTime} PST`}
                      </Text>
                    </Center>
                  )}
                </Stack>
              )}
              <Stack
                justifyContent={isMobile ? "space-between" : "flex-end"}
                direction="row"
                spacing="0.5rem"
                pt={isMobile ? "1rem" : "0"}
                pr={isMobile ? "0" : ".7rem"}
                width="100%">
                <Box width="100%" maxWidth="22rem">
                  <CollectionsFilterMenu onFilterToggle={onFilterToggle} isOpen={isFilterOpen} hideStateFilter hideTagFilter />
                </Box>
                {isMobile && isDueDiligence && (
                  <Box cursor="pointer">
                    <Button
                      ref={addTickerButtonRef}
                      {...ctaButtonProps}
                      onClick={onDialogOpen}
                      className="ch-project-landing-add-ticker-button"
                      leftIcon={<AddIcon />}>
                      Add Ticker
                    </Button>
                  </Box>
                )}
                {hasRerunAllProjectsButton && (
                  <Tooltip label={isRerunning ? "Scheduling Projects..." : "Rerun All Projects"} aria-label="Rerun Projects">
                    <Box>
                      <IconButton
                        onClick={() => handleRerunAllProjects()}
                        className="ch-rerun-all-projects-button"
                        aria-label="Rerun Projects"
                        {...commonButtonProps}
                        isDisabled={filteredCollections.length === 0 || isRerunning || shareDetails?.accessMode === "read"}
                        icon={<Icon as={HiOutlineRefresh} boxSize="1.2rem" animation={isRerunning ? animation : undefined} />}
                      />
                    </Box>
                  </Tooltip>
                )}
                {portfolioProject && (
                  <Tooltip label="Portfolio Settings" aria-label="Show/Hide Settings">
                    <Box cursor="pointer" onClick={() => onPortfolioSettingsOpen()}>
                      <IconButton
                        isDisabled={shareDetails?.accessMode === "read"}
                        className="ch-open-portfolio-settings-button"
                        aria-label="Show/Hide Settings"
                        {...commonButtonProps}
                        icon={<Icon as={LuSettings2} boxSize="1.2rem" />}
                        onClick={() => {
                          onPortfolioSettingsOpen();
                        }}
                      />
                    </Box>
                  </Tooltip>
                )}
              </Stack>
            </LandingTitle>
          </Box>
        )}
        <Stack
          spacing="1rem"
          justifyContent="space-between"
          mt={
            isDueDiligenceLanding
              ? !isMobile && colorMode === "light"
                ? "6rem"
                : "6.75rem"
              : isDueDiligence && !isMobile
              ? "29rem"
              : "1rem"
          }>
          <Stack
            spacing="1rem"
            pl={isMobile ? ".5rem" : "1rem"}
            pr={isMobile ? ".4rem" : "1rem"}
            pt={colorMode === "light" ? ".75rem" : "0"}>
            {shareDetails && (
              <Stack {...commonTileProps} spacing=".75rem" py=".75rem" direction="row" width="100%">
                <Center>
                  <Tag bgColor={bgColorReadOnly}>READ ONLY</Tag>
                </Center>
                <Center>
                  <Text
                    color={textColor}
                    fontSize={"md"}>{`This portfolio was shared by ${shareDetails.ownerName} and is therefore read only. `}</Text>
                </Center>
              </Stack>
            )}
            <PausedWorkflowModalProvider>
              <Box mb={"5rem!important"}>
                <ProjectLandingTiles />
              </Box>
            </PausedWorkflowModalProvider>
          </Stack>
        </Stack>
      </Box>
      <FloatingDialogModal isOpen={isDialogOpen} onClose={onDialogClose} buttonRef={addTickerButtonRef} />
    </>
  );
};
