import React, { useContext, useEffect, useRef, useMemo, useState, useCallback } from "react";
import { Box, Center, Drawer, DrawerBody, DrawerContent, Stack, useBreakpointValue, useColorModeValue, Text } from "@chakra-ui/react";
import { SidebarNavigation } from "./components";
import { HeaderInteractionBar } from "screens/common/components";
import { useSettingContext } from "../panels/settings/SettingsProvider";
import { Outlet, useLocation } from "react-router-dom";
import { TypingIndicator } from "screens/thread/components/cells/components";
import {
  useIsMainAppLoading,
  useProjectConfigEntitlements,
  useSidebarNavigation,
  useUserPreference,
  useGetViewConfig,
  useConfigMap,
  useProjectParams,
  DeleteCollectionModal,
  useDeleteCollection,
  useCollectionKey,
  useEntitlementKey,
} from "hooks";
import { ConversationContext } from "screens/thread/ConversationContext";
import { useDebouncedCallback } from "use-debounce";
import { v4 as uuid } from "uuid";
import { useAddToCharliContext } from "screens/panels/addToCharli/AddToCharliWizard/AddToCharliProvider";
import { ProjectNewInput } from "./tabs/project/ProjectNewInput";
import { ProjectDetailDefaultViewHeader } from "screens/collection/views/ProjectDetailDefaultViewHeader";
import { EquityFeedbackModal } from "screens/thread/components/useEquityFeedback";
import { useCopyValue } from "hooks/useCopies";
import { InfoOutlineIcon } from "@chakra-ui/icons";
import { AIUpdatedModal } from "./components/popoverComponent/AIUpdatedModal";
import { useCustomScrollbar } from "hooks/useCustomScrollbar";

export const PROJECT_DETAIL_SECTION_SELECTOR = "project-detail-section";

interface NavigationState {
  currentSidebarType: string;
  setSidebarType: (type: string) => void;
}

interface ConversationContextState {
  onConversationOpen: () => void;
  onConversationClose: () => void;
  setConversationId: (id: string) => void;
  conversationId: string | null;
  isAnotherInputFocused: boolean;
  isEditing: boolean;
  isConversationOpen: boolean;
  setHideConversationButton: (hide: boolean) => void;
  isUsingDefaultConversationDialog: boolean;
  setShowCommands: (show: boolean) => void;
  initialQuestionText: string;
  onAIUpdatedModalOpen: () => void;
}

export const Landing: React.FC = () => {
  const { parentRoute, projectFilter, projectId, contentId } = useProjectParams();
  const { pathname } = useLocation();
  const { isSideNavigationOpen, setIsSideNavigationOpen } = useSettingContext();
  const bgColor = useColorModeValue("white", "gray.800");
  const isMobile = useBreakpointValue({ base: true, md: true, lg: false }, { fallback: "md", ssr: false });
  const isMainAppLoading = useIsMainAppLoading();
  const appCountainerRef = useRef<HTMLDivElement>(null);
  const { scrollbarStyle } = useCustomScrollbar(appCountainerRef, { width: "2px" });

  const conversationContext = useContext(ConversationContext) as ConversationContextState;
  const {
    onConversationOpen,
    setConversationId,
    conversationId,
    isAnotherInputFocused,
    isEditing,
    isConversationOpen,
    setShowCommands,
    initialQuestionText,
    onAIUpdatedModalOpen,
  } = conversationContext;

  const { currentSidebarType: reduxNavigationType, setSidebarType } = useSidebarNavigation() as NavigationState;
  const saveNavigationType = useUserPreference("ui_save_navigation_type");
  const userNavigationType = useUserPreference("ui_navigation_type");
  const currentSidebarType = saveNavigationType ? userNavigationType : reduxNavigationType;

  const { isAdminPanelOpen, isAddNewProjectPanelOpen, isAddToCollectionModalOpen } = useAddToCharliContext();
  const { due_diligence_project: hasDueDiligence } = useProjectConfigEntitlements();
  const configMap = useConfigMap();

  const maybePortfolioProjectCollectionType = useCollectionKey(projectFilter, "collectionType");
  const maybePortfolioProjectConversationId = useCollectionKey(projectFilter, "conversationId");
  const portfolioCollectionType = useGetViewConfig("portfolioProjectType", maybePortfolioProjectCollectionType, configMap);

  const collectionType = useMemo(() => {
    if (portfolioCollectionType && portfolioCollectionType.length > 0) return portfolioCollectionType;
    if (parentRoute === "home" && hasDueDiligence) return "due_diligence";
    return projectFilter;
  }, [portfolioCollectionType, parentRoute, hasDueDiligence, projectFilter]);

  const isDueDiligence = useMemo(
    () => portfolioCollectionType === "due_diligence" || collectionType === "due_diligence",
    [portfolioCollectionType, collectionType]
  );

  const mainContainerRef = useRef<HTMLInputElement>(null);
  const showQAinputProjectDetail = useGetViewConfig("projectDetailQAInput", collectionType, configMap);
  const { onDeleteCollection } = useDeleteCollection();
  const isEmbeddingModelVersionOutdated = useCollectionKey(projectId, "isEmbeddingModelVersionOutdated");
  const copyMaintenanceBanner = useCopyValue("copy_maintenance_banner");
  const copyBgColor = useCopyValue("copy_maintenance_banner_bg_color");
  const copyTextColor = useCopyValue("copy_maintenance_banner_text_color");
  const [currentPathname, setCurrentPathname] = useState(pathname);
  const hasCommandLineEntitlement = useEntitlementKey("ui_enable_command_suggestions");

  const scrollToTop = useCallback(() => {
    const container = appCountainerRef.current;
    if (
      container &&
      !isConversationOpen &&
      !pathname.includes("/panel") &&
      (initialQuestionText.length > 0 || projectId || currentPathname !== pathname)
    ) {
      setCurrentPathname(pathname);
      container.scroll({ top: 0, behavior: "smooth" });
    }
  }, [projectId, initialQuestionText, isConversationOpen, pathname, currentPathname]);

  useEffect(() => {
    scrollToTop();
  }, [scrollToTop]);

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (
        event.key === "/" &&
        !isConversationOpen &&
        hasCommandLineEntitlement &&
        !isAdminPanelOpen &&
        !isAddNewProjectPanelOpen &&
        !isAddToCollectionModalOpen &&
        !isAnotherInputFocused &&
        !isEditing
      ) {
        if (!conversationId) {
          setConversationId(maybePortfolioProjectConversationId || contentId || uuid());
        }
        setShowCommands(true);
        onConversationOpen();
      }
    },
    [
      maybePortfolioProjectConversationId,
      conversationId,
      isAddToCollectionModalOpen,
      isAdminPanelOpen,
      isAnotherInputFocused,
      isConversationOpen,
      isEditing,
      isAddNewProjectPanelOpen,
      onConversationOpen,
      setConversationId,
      contentId,
      setShowCommands,
      hasCommandLineEntitlement,
    ]
  );

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [handleKeyDown]);

  const debouncedSetWidth = useDebouncedCallback(() => {
    if (saveNavigationType) return;
    setSidebarType(isMobile ? "hidden" : "desktop");
  }, 30);

  useEffect(() => {
    window.addEventListener("resize", debouncedSetWidth);
    debouncedSetWidth();
    return () => window.removeEventListener("resize", debouncedSetWidth);
  }, [debouncedSetWidth]);

  const projectNewInputHeight = useMemo(() => {
    if (parentRoute === "home") return undefined;
    if (!showQAinputProjectDetail?.showInput && projectId) return "0";
    if (isMobile) return projectId && !pathname.includes("/panel") ? "9rem" : "13rem";
    return projectId && !pathname.includes("/panel") ? "9.3rem" : "16rem";
  }, [isMobile, parentRoute, pathname, projectId, showQAinputProjectDetail?.showInput]);

  const renderMaintenanceBanner = useMemo(() => {
    if (!copyMaintenanceBanner.length) return null;
    return (
      <Center width="100%" bgColor={copyBgColor}>
        <Stack px="2rem" py="1rem" direction="row" alignItems="center" justifyContent="center" spacing="1rem">
          <InfoOutlineIcon color={copyTextColor} height="1.5em" />
          <Text fontSize="md" fontWeight="semibold" color={copyTextColor}>
            {copyMaintenanceBanner}
          </Text>
        </Stack>
      </Center>
    );
  }, [copyMaintenanceBanner, copyBgColor, copyTextColor]);

  const renderAIUpdateBanner = useMemo(() => {
    if (!(projectId && collectionType === "due_diligence" && isEmbeddingModelVersionOutdated)) return null;
    return (
      <Center width="100%" bgColor="charli.primaryGreen" cursor="pointer" onClick={onAIUpdatedModalOpen}>
        <Text px="2rem" py="1rem" textAlign="center" fontSize="md" fontWeight="normal" color="white">
          Notice: The AI Models have been updated since this project and ticker symbol was analyzed. If you ask questions, Charli will
          re-analyze the source material.
        </Text>
      </Center>
    );
  }, [projectId, collectionType, isEmbeddingModelVersionOutdated, onAIUpdatedModalOpen]);

  const renderProjectHeader = useMemo(() => {
    const excludedPaths = ["/panel", "/search", "/pricing"];
    if (!(projectId && projectId.length > 0 && !excludedPaths.some((path) => pathname.includes(path)))) return null;
    return (
      <Box pt="1rem" pl={isMobile ? ".5rem" : "1rem"} pr={isMobile ? ".5rem" : "12px"}>
        <Box className={PROJECT_DETAIL_SECTION_SELECTOR} height="4rem">
          <ProjectDetailDefaultViewHeader collectionId={projectId} />
        </Box>
        <Center width="100%" height={projectNewInputHeight} px="0">
          <ProjectNewInput collectionType={collectionType} collectionId={projectId} />
        </Center>
      </Box>
    );
  }, [projectId, pathname, isMobile, projectNewInputHeight, collectionType]);

  const renderProjectInput = useMemo(() => {
    const includePaths = ["/projects"];
    if (isDueDiligence || !includePaths.some((path) => pathname.includes(path))) return null;
    return (
      <Center width="100%" height={projectNewInputHeight} px="0">
        <ProjectNewInput collectionType={collectionType} collectionId={projectId} />
      </Center>
    );
  }, [isDueDiligence, pathname, projectNewInputHeight, collectionType, projectId]);

  return (
    <Box ref={mainContainerRef} m="0!important" width="100vw" height="100vh">
      <Stack direction="row" spacing="0" height="100%" width="100%" justifyContent="space-between">
        <Box height="100%">
          {currentSidebarType === "hidden" ? (
            <Drawer isOpen={isSideNavigationOpen} placement="left" onClose={() => setIsSideNavigationOpen(false)}>
              <DrawerContent>
                <DrawerBody p="0" paddingInline="0" height="100vh" overflow="hidden" width={["100vw", "100%", "100%"]}>
                  <SidebarNavigation />
                </DrawerBody>
              </DrawerContent>
            </Drawer>
          ) : (
            <SidebarNavigation />
          )}
        </Box>
        <Stack spacing="0" width="100%" height="100%" mt="0!important">
          <HeaderInteractionBar showTickerInput={isDueDiligence && !projectId} />
          {renderMaintenanceBanner}
          {renderAIUpdateBanner}
          <Box
            className="ch-landing"
            height="100%"
            ref={appCountainerRef}
            overflowX="hidden"
            mt="0!important"
            backgroundColor={bgColor}
            overflowY="scroll"
            paddingRight="0px"
            css={scrollbarStyle}>
            {isMainAppLoading ? (
              <Center backgroundColor={bgColor} zIndex={100} width="100%" height="calc(100vh - 5rem)">
                <TypingIndicator />
              </Center>
            ) : (
              <>
                {renderProjectHeader}
                {renderProjectInput}
                <Outlet />
              </>
            )}
          </Box>
        </Stack>
      </Stack>
      <DeleteCollectionModal onDeleteCollection={onDeleteCollection} />
      <EquityFeedbackModal />
      <AIUpdatedModal />
    </Box>
  );
};
