import { Stack, Text, Image, useColorModeValue, SimpleGrid, Center, Tooltip, Flex, useBreakpointValue, Tag } from "@chakra-ui/react";
import type { FC } from "react";
import React, { useMemo, useState, useEffect } from "react";
import { useTileProps } from "hooks";
import { useSwipeable } from "react-swipeable";

export type TileSelectorProps = {
  label: string;
  entityName: string;
  tooltip?: string;
  value: string;
  image: string;
  templateName?: string;
  imageDimensions?: { width: string; height: string };
  disabled?: boolean;
  default?: boolean;
  tag?: { label: string; color: string };
};

interface Props {
  title?: string;
  layout?: "scroll" | "grid";
  tiles: TileSelectorProps[];
  onSelectedTile?: (tile: TileSelectorProps) => void;
}

export const TileSelector: FC<Props> = ({ tiles, title, layout, onSelectedTile }) => {
  const commonTileProps = useTileProps();
  const defaultTileIndex = tiles.findIndex((tile) => tile.default);
  const [isSelectedTile, setIsSelectedTile] = useState(defaultTileIndex || 0);
  const buttonColor = useColorModeValue("gray.700", "gray.400");
  const buttonBgColor = useColorModeValue("white", "gray.600");
  const buttonBgColorSelected = useColorModeValue("gray.100", "gray.800");
  const [currentIndex, setCurrentIndex] = useState(0);
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });

  const tilesPerView = 3;

  const handlers = useSwipeable({
    onSwipedLeft: () => handleSlide("next"),
    onSwipedRight: () => handleSlide("prev"),
    trackMouse: true,
  });

  useEffect(() => {
    setIsSelectedTile(defaultTileIndex !== -1 ? defaultTileIndex : 0);
    defaultTileIndex !== -1 && onSelectedTile && onSelectedTile(tiles[defaultTileIndex]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultTileIndex]);

  const handleSlide = (direction: "prev" | "next") => {
    if (direction === "prev") {
      setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
    } else {
      setCurrentIndex((prevIndex) => Math.min(prevIndex + 1, tiles.length - tilesPerView));
    }
  };

  const tilesStack = useMemo(() => {
    return tiles.map((tile, index) => {
      return (
        <Tooltip key={index} label={tile.tooltip} aria-label={tile.tooltip} textAlign="center" maxWidth={"11rem"}>
          <Stack
            className="ch-tile-selector"
            key={`slide-${index}`}
            {...commonTileProps}
            minWidth={layout === "scroll" ? "9.5rem" : "auto"}
            cursor={tile.disabled ? "not-allowed" : "pointer"}
            opacity={tile.disabled ? 0.5 : 1}
            py={"2rem"}
            onClick={() => {
              if (tile.disabled) return;
              onSelectedTile && onSelectedTile(tile);
              setIsSelectedTile(index);
            }}
            bgColor={isSelectedTile === index ? buttonBgColorSelected : buttonBgColor}>
            <Center>
              <Image
                borderRadius={"md"}
                height={tile.imageDimensions?.height ? tile.imageDimensions.height : "5.5rem"}
                width={tile.imageDimensions?.width ? tile.imageDimensions.width : "100%"}
                src={tile.image}
                alt={tile.label}
              />
              {tile.tag?.label && (
                <Tag size="sm" variant="solid" colorScheme={tile.tag?.color || "gray"} position="absolute" top="5px" right="5px">
                  {tile.tag?.label}
                </Tag>
              )}
            </Center>
            <Text textAlign={"center"} fontSize="sm" fontWeight="normal" width="100%" px=".5rem">
              {tile.label}
            </Text>
          </Stack>
        </Tooltip>
      );
    });
  }, [buttonBgColor, buttonBgColorSelected, commonTileProps, isSelectedTile, layout, onSelectedTile, tiles]);

  const shadowColor = useColorModeValue("#00000080", "#000000");

  const arrowStyles = {
    cursor: "pointer",
    pos: "absolute",
    top: "50%",
    w: "auto",
    mt: "-22px",
    mx: "-12px",
    p: "1px 12px",
    bg: "white",
    color: "gray.700",
    fontWeight: "bold",
    fontSize: "22px",
    transition: "0.6s ease",
    borderRadius: "10px",
    userSelect: "none",
    boxShadow: `0 4px 46px ${shadowColor}`,
    _hover: {
      bg: "gray.100",
    },
  } as const;

  return (
    <Stack>
      {title && (
        <Text fontWeight="semibold" isTruncated fontSize="sm" color={buttonColor}>
          {title}
        </Text>
      )}
      {layout === "scroll" ? (
        <Flex w="full" alignItems="center" justifyContent="center">
          <Flex w="full" overflow="hidden" pos="relative">
            <Stack
              className="ch-tile-stack"
              style={{
                touchAction: "pan-x",
                transform: `translateX(-${currentIndex * (100 / tilesPerView)}%)`,
                transition: "transform 0.3s ease-in-out",
                display: "flex",
                flexDirection: "row",
                width: `${(tiles.length / tilesPerView) * 110}%`,
              }}
              spacing={4}
              {...handlers}>
              {tilesStack}
            </Stack>
            {currentIndex > 0 && (
              <Text {...arrowStyles} left="0" onClick={() => handleSlide("prev")}>
                &#10094;
              </Text>
            )}
            {currentIndex < (isMobile ? 3 : 2) && (
              <Text {...arrowStyles} right="0" onClick={() => handleSlide("next")}>
                &#10095;
              </Text>
            )}
          </Flex>
        </Flex>
      ) : (
        <SimpleGrid columns={[2, 3, 3]} spacing="1rem">
          {tilesStack}
        </SimpleGrid>
      )}
    </Stack>
  );
};
