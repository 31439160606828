import { ArrowForwardIcon, Search2Icon } from "@chakra-ui/icons";
import {
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  useColorModeValue,
  Box,
  Stack,
  Popover,
  PopoverTrigger,
  PopoverContent,
  Portal,
  useOutsideClick,
} from "@chakra-ui/react";
import type { FunctionComponent } from "react";
import { useContext, memo } from "react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { sendMessage } from "state/websocket/operations";
import { v4 as uuid } from "uuid";
import { InteractionBarFindInputTagAutocomplete } from "./InteractionBarFindInputTagAutocomplete";
import type { InteractionBarFindInputTagAutocompleteRef } from "./InteractionBarFindInputTagAutocomplete";
import type { RootState } from "state/rootReducer";
import type { RequestEntities } from "types/charliui";
import { ConversationContext } from "screens/thread/ConversationContext";
import { motion, AnimatePresence } from "framer-motion";
import { Box as ChakraBox } from "@chakra-ui/react";

interface Props {
  initialText?: string;
  intent?: string;
  onClick?: (inputText: string) => void;
}

const MotionBox = motion(ChakraBox);
const MotionStack = motion(Stack);

const inputVariants = {
  open: { width: "100%", opacity: 1 },
  closed: { width: "0", opacity: 0 },
};

const stackVariants = {
  open: { width: "100%" },
  closed: { width: "1rem" },
};

const ANIMATION_DURATION = 300; // matches the duration in the variants

const InteractionBarFindInputComponent: FunctionComponent<Props> = ({ initialText = "", intent = "find", onClick }) => {
  const [findText, setFindText] = useState(initialText);
  const isConnected = useSelector((state: RootState) => state.websocket.isConnected);
  const inputRef = useRef<HTMLInputElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const autoCompleteRef = useRef<InteractionBarFindInputTagAutocompleteRef>(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isInputBarFocused, setIsInputBarFocused] = useState(false);
  const [selectedSuggestionIndex, setSelectedSuggestionIndex] = useState<number | false>(false);
  const [showSearchInput, setShowSearchInput] = useState(false);
  const [showPopoverContent, setShowPopoverContent] = useState(false);
  const inputBgColor = useColorModeValue("white", "gray.700");
  const hoverColor = useColorModeValue("primary.default", "primary.default");
  const buttonColor = useColorModeValue("gray.400", "gray.500");
  const submitColor = useColorModeValue("primary.default", "gray.500");
  const { setConversationId, setIsAnotherInputFocused } = useContext(ConversationContext);
  const textColor = useColorModeValue("primary.darkGray", "gray.300");

  const resetSearch = useCallback(() => {
    setShowSearchInput(false);
    setFindText("");
    setIsInputBarFocused(false);
    setIsAnotherInputFocused(false);
    setSelectedSuggestionIndex(false);
    setShowPopoverContent(false);
  }, [setIsAnotherInputFocused]);

  useOutsideClick({
    ref: wrapperRef,
    handler: resetSearch,
  });

  const onSubmit = useCallback(() => {
    const newConversationId = uuid();
    setConversationId(newConversationId);
    const entities: RequestEntities = findText
      .split(" ")
      .filter((word) => word.length > 0)
      .map((word) => ({
        entity: word.startsWith("#") ? "tag" : "keywords",
        value: word.startsWith("#") ? word.replace("#", "").trim() : word.trim(),
      }));

    dispatch(
      sendMessage({
        conversationId: newConversationId,
        intent: "/find",
        entities,
        message: `/find ${findText.replace("/find", "").trim()}`,
      })
    );
    navigate(`/search/${newConversationId}`);
    resetSearch();
  }, [findText, dispatch, navigate, setConversationId, resetSearch]);

  const focusAndMoveCursorToEnd = useCallback(() => {
    requestAnimationFrame(() => {
      if (inputRef.current) {
        inputRef.current.focus();
        inputRef.current.selectionStart = inputRef.current.value.length;
        inputRef.current.selectionEnd = inputRef.current.value.length;
        inputRef.current.scrollLeft = inputRef.current.scrollWidth;
      }
    });
  }, []);

  const onClickHandler = useCallback(() => {
    if (!findText.trim() || !isConnected) return;
    if (onClick) {
      onClick(findText.trim());
    } else {
      onSubmit();
    }
    resetSearch();
  }, [findText, isConnected, onClick, onSubmit, resetSearch]);

  const onSuggestionClick = useCallback(
    (tag: string) => {
      if (!tag) return;

      setFindText((prevText) => {
        const words = prevText.split(" ");
        const lastWord = words[words.length - 1] || "";
        const incompleteTag = lastWord.replace("#", "");

        if (!prevText) return `#${tag} `;

        if (tag.toLowerCase().includes(incompleteTag.toLowerCase())) {
          words.pop();
        }

        return [...words, `#${tag}`].filter(Boolean).join(" ") + " ";
      });
      onSubmit();

      focusAndMoveCursorToEnd();
    },
    [focusAndMoveCursorToEnd, onSubmit]
  );

  const onKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      switch (event.key) {
        case "Tab":
          autoCompleteRef.current?.applySelectedSuggestion();
          setSelectedSuggestionIndex(false);
          break;
        case "Enter":
          if (selectedSuggestionIndex !== false) {
            autoCompleteRef.current?.applySelectedSuggestion();
            setSelectedSuggestionIndex(false);
          } else {
            onClickHandler();
          }
          break;
        case "Escape":
          resetSearch();
          break;
        case "ArrowUp":
          autoCompleteRef.current?.setSuggestionFocus("up");
          break;
        case "ArrowDown":
          setIsInputBarFocused(true);
          autoCompleteRef.current?.setSuggestionFocus("down");
          break;
      }
    },
    [onClickHandler, selectedSuggestionIndex, resetSearch]
  );

  useEffect(() => {
    setFindText(initialText);
  }, [initialText]);

  useEffect(() => {
    if (!isInputBarFocused) {
      setSelectedSuggestionIndex(false);
      setShowPopoverContent(false);
    }
  }, [isInputBarFocused]);

  useEffect(() => {
    if (showSearchInput) {
      const timer = setTimeout(() => {
        setShowPopoverContent(true);
      }, ANIMATION_DURATION * 2);
      return () => clearTimeout(timer);
    }
    return undefined;
  }, [showSearchInput]);

  const handleSearchIconClick = useCallback(() => {
    setIsAnotherInputFocused(true);
    setShowSearchInput(true);
    setIsInputBarFocused(true);
    requestAnimationFrame(() => {
      inputRef.current?.focus();
    });
  }, [setIsAnotherInputFocused]);

  return (
    <Box ref={wrapperRef}>
      <MotionStack
        direction="row"
        align="center"
        initial="closed"
        animate={showSearchInput ? "open" : "closed"}
        variants={stackVariants}
        transition={{ duration: ANIMATION_DURATION / 1000 }}>
        <Search2Icon
          className="ch-quick-actions-open-find"
          zIndex={2}
          cursor="pointer"
          color={buttonColor}
          _hover={{ color: hoverColor }}
          boxSize="1.2rem"
          onClick={handleSearchIconClick}
        />
        <AnimatePresence>
          {showSearchInput && (
            <MotionBox
              initial="closed"
              animate="open"
              exit="closed"
              variants={inputVariants}
              transition={{ duration: ANIMATION_DURATION / 1000 }}>
              <Popover
                isOpen={isInputBarFocused && showPopoverContent}
                onClose={() => setIsInputBarFocused(false)}
                placement="bottom-end"
                autoFocus={false}
                closeOnBlur={false}
                closeOnEsc
                offset={[0, 4]}>
                <PopoverTrigger>
                  <InputGroup size="xs" backgroundColor={inputBgColor} borderRadius="md">
                    <Input
                      fontSize="sm"
                      className={`ch-quick-actions-${intent}-input`}
                      ref={inputRef}
                      onFocus={() => setIsInputBarFocused(true)}
                      placeholder="Search Charli..."
                      backgroundColor={inputBgColor}
                      color={textColor}
                      border="none"
                      spellCheck="false"
                      value={findText}
                      onKeyDown={onKeyDown}
                      onChange={(ev) => {
                        setIsInputBarFocused(true);
                        setFindText(ev.target.value);
                      }}
                    />
                    <InputRightElement
                      zIndex={1}
                      children={
                        <IconButton
                          aria-label="submit"
                          onClick={onClickHandler}
                          isDisabled={!isConnected || !findText.trim()}
                          color={submitColor}
                          backgroundColor="transparent"
                          size="sm"
                          _hover={{ color: hoverColor }}
                          _active={{}}
                          icon={<ArrowForwardIcon width="1rem" height="1rem" />}
                        />
                      }
                    />
                  </InputGroup>
                </PopoverTrigger>
                <Portal>
                  <PopoverContent width="auto" minWidth="200px" backgroundColor={inputBgColor}>
                    <InteractionBarFindInputTagAutocomplete
                      ref={autoCompleteRef}
                      findText={findText}
                      onSuggestionClick={onSuggestionClick}
                      selectedSuggestionIndex={selectedSuggestionIndex}
                      setSelectedSuggestionIndex={setSelectedSuggestionIndex}
                    />
                  </PopoverContent>
                </Portal>
              </Popover>
            </MotionBox>
          )}
        </AnimatePresence>
      </MotionStack>
    </Box>
  );
};

export const InteractionBarFindInput = memo(InteractionBarFindInputComponent);
