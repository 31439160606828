import React from "react";
import type { FunctionComponent } from "react";
import { Tooltip, Box, Circle, useColorModeValue } from "@chakra-ui/react";
import { getConfidenceColor } from "hooks/useStats";

interface Props {
  tooltip?: string;
  borderColor?: string;
  circleSize?: string;
  fontSize?: string;
  score?: number;
}

export const SmallActionConfidenceScore: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  circleSize = "20px",
  fontSize = "11px",
  tooltip,
  borderColor,
  score,
}) => {
  const iconColor = useColorModeValue("500", "700");
  const textColor = useColorModeValue("charli.mediumGray", "gray.300");

  return (
    <Tooltip aria-label="" label={tooltip} placement="left" hasArrow>
      <Box>
        <Circle
          className="ch-qa-result-score"
          fontSize={fontSize}
          size={circleSize}
          fontWeight="semibold"
          borderColor={borderColor}
          borderWidth="1px"
          bg={score ? `${getConfidenceColor(score)}.${iconColor}` : borderColor}
          color={textColor}>
          {score}
        </Circle>
      </Box>
    </Tooltip>
  );
};
