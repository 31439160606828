import React, { useContext, useMemo, useCallback, memo } from "react";
import type { BoxProps } from "@chakra-ui/react";
import { Box, Text, Circle, Stack, useBreakpointValue, useColorModeValue, Center } from "@chakra-ui/react";
import { ProjectHeaderImage } from "../../../project/projectLandingTileLayouts/components/ProjectHeaderImage";
import { TriangleDownIcon } from "@chakra-ui/icons";
import { useProjectParams } from "hooks";
import { useNavigate } from "react-router-dom";
import type { QuadrantType } from "../../CollectionsFilterContext";
import { CollectionsFilterContext } from "../../CollectionsFilterContext";
import { ProjectAnalysisPopover } from "../ProjectAnalysisPopover";

export interface QuadrantData {
  x: number;
  y: number;
  projectId?: string;
  projectName?: string;
}

interface QuadrantChartProps extends BoxProps {
  data: QuadrantData[];
  xAxisLabel: string;
  yAxisLabel: string;
  collectionId?: string;
}

// Memoized point component
const QuadrantPoint = memo(
  ({
    point,
    index,
    selectedProjectId,
    setSelectedProjectId,
    normalizeCoordinate,
    getPointOffset,
    pointBgColor,
    pointLogoBgColor,
    selectedPointBgColor,
    selectedCardBgColor,
    cardBgColor,
    cardBorderColor,
    handleNavigate,
    selectedQuadrant,
  }: {
    point: QuadrantData;
    index: number;
    selectedProjectId?: string;
    setSelectedProjectId: (id: string | undefined) => void;
    normalizeCoordinate: (value: number, max?: number, quadrant?: QuadrantType, isYAxis?: boolean) => number;
    getPointOffset: (x: number, y: number, index: number) => { offsetX: number; offsetY: number; groupCount: number };
    pointBgColor: string;
    pointLogoBgColor: string;
    selectedPointBgColor: string;
    selectedCardBgColor: string;
    cardBgColor: string;
    cardBorderColor: string;
    handleNavigate: (projectId: string) => void;
    selectedQuadrant?: QuadrantType;
  }) => {
    const { offsetX, offsetY } = getPointOffset(point.x, point.y, index);
    const adjustedX = normalizeCoordinate(point.x, 100, selectedQuadrant, false);
    const adjustedY = normalizeCoordinate(point.y, 100, selectedQuadrant, true);

    return (
      <ProjectAnalysisPopover projectId={point.projectId} openProjectButton>
        {point.projectId && (
          <Stack spacing="0" position="absolute" left={`${adjustedX}%`} bottom={`${adjustedY}%`}>
            <Circle
              zIndex={selectedProjectId && selectedProjectId === point.projectId ? 4 : 3}
              className={point.projectName}
              size={{ base: "8px", md: selectedProjectId === point.projectId ? "12px" : "8px" }}
              bg={selectedProjectId === point.projectId ? selectedPointBgColor : pointBgColor}
              transform="translate(-50%, 50%)"
              cursor="pointer"
              display="flex"
              alignItems="center"
              justifyContent="center"
            />
            <Box
              onClick={() => {
                if (point.projectId) {
                  handleNavigate(point.projectId);
                }
              }}
              minHeight={"1.2rem"}
              className="ch-quadrant-point"
              zIndex={selectedProjectId && selectedProjectId === point.projectId ? 5 : 3}
              _hover={{ zIndex: 4 }}
              boxShadow={selectedProjectId === point.projectId ? "lg" : "md"}
              opacity={selectedProjectId && selectedProjectId !== point.projectId ? 0.3 : 1}
              borderColor={cardBorderColor}
              borderWidth="1px"
              backgroundColor={selectedProjectId === point.projectId ? selectedCardBgColor : pointLogoBgColor}
              py="3px"
              px="5px"
              borderRadius="full"
              width="100%"
              transform={`translate(${offsetX}px, ${offsetY}px)`}
              transition="transform 0.2s ease-in-out"
              onMouseEnter={() => setSelectedProjectId(point.projectId)}>
              <Center>
                <ProjectHeaderImage
                  titleStyle={{ fontSize: "10px" }}
                  maxHeight=".7rem"
                  width="4rem"
                  collectionId={point.projectId}
                  logoOnly
                />
              </Center>
            </Box>
          </Stack>
        )}
      </ProjectAnalysisPopover>
    );
  }
);

QuadrantPoint.displayName = "QuadrantPoint";

const QuadrantChart: React.FC<QuadrantChartProps> = memo(({ data, xAxisLabel, yAxisLabel, collectionId, ...rest }) => {
  const { selectedProjectId, setSelectedProjectId, setSelectedQuadrant, selectedQuadrant } = useContext(CollectionsFilterContext);
  const { projectFilter, parentRoute } = useProjectParams();
  const navigate = useNavigate();

  // Define color mode values outside of callbacks
  const volatileDefaultBg = useColorModeValue("rgba(254, 215, 165, 0.5)", "rgba(254, 215, 165, 0.2)");
  const volatileSelectedBg = useColorModeValue("rgba(254, 215, 165, 0.9)", "rgba(254, 215, 165, 0.4)");
  const volatileBorder = useColorModeValue("rgba(254, 215, 165, 0.9)", "rgba(254, 215, 165, 0.6)");

  const stableDefaultBg = useColorModeValue("rgba(198, 246, 213, 0.5)", "rgba(198, 246, 213, 0.2)");
  const stableSelectedBg = useColorModeValue("rgba(198, 246, 213, 0.9)", "rgba(198, 246, 213, 0.4)");
  const stableBorder = useColorModeValue("rgba(165, 229, 185, 0.9)", "rgba(165, 229, 185, 0.6)");

  const marginalDefaultBg = useColorModeValue("rgba(254, 215, 215, 0.5)", "rgba(254, 215, 215, 0.2)");
  const marginalSelectedBg = useColorModeValue("rgba(254, 215, 215, 0.9)", "rgba(254, 215, 215, 0.4)");
  const marginalBorder = useColorModeValue("rgba(254, 215, 215, 0.9)", "rgba(254, 215, 215, 0.6)");

  const potentialDefaultBg = useColorModeValue("rgba(235, 248, 255, 0.5)", "rgba(235, 248, 255, 0.2)");
  const potentialSelectedBg = useColorModeValue("rgba(209, 237, 251, 0.9)", "rgba(209, 237, 251, 0.4)");
  const potentialBorder = useColorModeValue("rgba(190, 227, 245, 0.9)", "rgba(190, 227, 245, 0.6)");

  // Memoize color values using pre-defined color mode values
  const colors = useMemo(
    () => ({
      volatile: {
        defaultBg: volatileDefaultBg,
        selectedBg: volatileSelectedBg,
        border: volatileBorder,
      },
      stable: {
        defaultBg: stableDefaultBg,
        selectedBg: stableSelectedBg,
        border: stableBorder,
      },
      marginal: {
        defaultBg: marginalDefaultBg,
        selectedBg: marginalSelectedBg,
        border: marginalBorder,
      },
      potential: {
        defaultBg: potentialDefaultBg,
        selectedBg: potentialSelectedBg,
        border: potentialBorder,
      },
    }),
    [
      volatileDefaultBg,
      volatileSelectedBg,
      volatileBorder,
      stableDefaultBg,
      stableSelectedBg,
      stableBorder,
      marginalDefaultBg,
      marginalSelectedBg,
      marginalBorder,
      potentialDefaultBg,
      potentialSelectedBg,
      potentialBorder,
    ]
  );

  const textColor = useColorModeValue("gray.600", "gray.300");
  const pointBgColor = useColorModeValue("gray.300", "gray.400");
  const pointLogoBgColor = useColorModeValue("white", "gray.400");
  const selectedPointBgColor = useColorModeValue("gray.500", "gray.300");
  const selectedCardBgColor = useColorModeValue("white", "gray.100");
  const cardBgColor = useColorModeValue("white", "gray.600");
  const cardBorderColor = useColorModeValue("gray.300", "gray.500");
  const axisColor = useColorModeValue("charli.primaryBlue", "blue.300");
  const diagonalLineColor = useColorModeValue("orange.300", "orange.500");

  const isMobile = useBreakpointValue({ base: true, md: false, lg: false }, { fallback: "md", ssr: false });

  // Memoize quadrant labels
  const quadrantLabels = useMemo(
    () => [
      { text: "Volatile", top: "5%", left: "25%", transform: "translate(-50%, -50%)" },
      { text: "Stable", top: "5%", right: "25%", transform: "translate(50%, -50%)" },
      { text: "Marginal", bottom: "5%", left: "25%", transform: "translate(-50%, 50%)" },
      { text: "Potential", bottom: "5%", right: "25%", transform: "translate(50%, 50%)" },
    ],
    []
  );

  // Enhanced coordinate normalization for quadrant expansion
  const normalizeCoordinate = useCallback((value: number, max = 100, quadrant?: QuadrantType, isYAxis = false) => {
    if (!quadrant) return (value / max) * 100;

    const normalizedValue = (value / max) * 100;

    // Helper function to scale value from one range to another
    const scaleValue = (val: number, fromMin: number, fromMax: number, toMin: number, toMax: number) => {
      return ((val - fromMin) / (fromMax - fromMin)) * (toMax - toMin) + toMin;
    };

    if (isYAxis) {
      // Y-axis transformations
      switch (quadrant) {
        case "volatile":
        case "stable":
          // Top quadrants: y >= 50
          return normalizedValue >= 50 ? scaleValue(normalizedValue, 50, 100, 0, 100) : 0;
        case "marginal":
        case "potential":
          // Bottom quadrants: y < 50
          return normalizedValue < 50 ? scaleValue(normalizedValue, 0, 50, 0, 100) : 100;
        default:
          return normalizedValue;
      }
    } else {
      // X-axis transformations
      switch (quadrant) {
        case "volatile":
        case "marginal":
          // Left quadrants: x <= 50
          return normalizedValue <= 50 ? scaleValue(normalizedValue, 0, 50, 0, 100) : 100;
        case "stable":
        case "potential":
          // Right quadrants: x > 50
          return normalizedValue > 50 ? scaleValue(normalizedValue, 50, 100, 0, 100) : 0;
        default:
          return normalizedValue;
      }
    }
  }, []);

  // Memoize point grouping
  const groupedPoints = useMemo(() => {
    const groups: { [key: string]: { data: QuadrantData; index: number }[] } = {};
    data.forEach((point, index) => {
      const key = `${point.x}-${point.y}`;
      if (!groups[key]) groups[key] = [];
      groups[key].push({ data: point, index });
    });
    return groups;
  }, [data]);

  // Memoize point offset calculation
  const getPointOffset = useCallback(
    (x: number, y: number, index: number) => {
      const key = `${x}-${y}`;
      const group = groupedPoints[key];
      if (!group || group.length <= 1) return { offsetX: 0, offsetY: 0, groupCount: 1 };

      const position = group.findIndex((item) => item.index === index);
      return { offsetX: 0, offsetY: position * -10, groupCount: group.length };
    },
    [groupedPoints]
  );

  const getQuadrantStyle = useCallback(
    (quadrantName: string) => {
      const lowerCaseQuadrantName = quadrantName.toLowerCase() as keyof typeof colors;
      const isSelected = selectedQuadrant?.toLowerCase() === lowerCaseQuadrantName;
      const quadrantColor = colors[lowerCaseQuadrantName];

      return {
        backgroundColor: isSelected ? quadrantColor.selectedBg : quadrantColor.defaultBg,
        borderColor: isSelected ? quadrantColor.border : quadrantColor.defaultBg,
        fontWeight: isSelected ? "bold" : "normal",
        gridColumn: isSelected ? "1 / span 2" : "auto",
        gridRow: isSelected ? "1 / span 2" : "auto",
        opacity: selectedQuadrant && !isSelected ? 0 : 1,
        transition: "all 0.3s ease-in-out",
        labelPosition: isSelected
          ? {
              top: "5%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              right: "auto",
              bottom: "auto",
            }
          : null,
      };
    },
    [colors, selectedQuadrant]
  );

  const handleQuadrantClick = useCallback(
    (quadrantName: QuadrantType) => {
      setSelectedQuadrant(selectedQuadrant === quadrantName ? undefined : quadrantName);
    },
    [selectedQuadrant, setSelectedQuadrant]
  );

  const handleNavigate = useCallback(
    (projectId: string) => {
      navigate(`/${parentRoute}/${projectFilter}/${projectId}`);
    },
    [navigate, parentRoute, projectFilter]
  );

  // Filter points based on selected quadrant
  const filteredData = useMemo(() => {
    if (!selectedQuadrant) return data;

    return data.filter((point) => {
      const x = point.x;
      const y = point.y;
      switch (selectedQuadrant) {
        case "volatile":
          return x <= 50 && y >= 50;
        case "stable":
          return x > 50 && y >= 50;
        case "marginal":
          return x <= 50 && y < 50;
        case "potential":
          return x > 50 && y < 50;
        default:
          return true;
      }
    });
  }, [data, selectedQuadrant]);

  return (
    <Box
      onMouseLeave={() => setSelectedProjectId(undefined)}
      className="ch-project-landing-metric-quadrant"
      position="relative"
      height="19rem"
      width={isMobile ? "4/3" : "31rem"}
      {...rest}>
      <Box
        position="absolute"
        top={0}
        left={3}
        right={0}
        bottom={4}
        display="grid"
        gridTemplateColumns="repeat(2, 1fr)"
        gridTemplateRows="repeat(2, 1fr)">
        {quadrantLabels.map((label, index) => {
          const style = getQuadrantStyle(label.text);
          const labelPosition = style.labelPosition || label;
          return (
            <Box
              key={index}
              zIndex={2}
              bg={style.backgroundColor}
              borderColor={style.borderColor}
              border="2px hidden"
              gridColumn={style.gridColumn}
              gridRow={style.gridRow}
              opacity={style.opacity}
              transition={style.transition}
              _hover={{
                borderColor: colors[label.text.toLowerCase() as keyof typeof colors].border,
                borderStyle: "solid",
              }}
              onClick={() => handleQuadrantClick(label.text.toLowerCase() as QuadrantType)}
              cursor="pointer">
              <Text
                position="absolute"
                fontSize={{ base: "sm", md: "xs" }}
                color={textColor}
                fontWeight={style.fontWeight}
                {...labelPosition}>
                {label.text}
              </Text>
            </Box>
          );
        })}
      </Box>

      {/* Diagonal line */}
      <Box
        position="absolute"
        top={0}
        left={3}
        right={0}
        bottom={4}
        zIndex={2}
        pointerEvents="none"
        overflow="hidden"
        opacity="0.7"
        transition="opacity 0.3s ease-in-out">
        <Box
          position="absolute"
          width="141.4%"
          height="0"
          borderTop="2px dotted"
          borderColor={diagonalLineColor}
          top="50%"
          left="50%"
          transform={`translate(-50%, -50%) rotate(${isMobile ? "-39deg" : "-30.5deg"})`}
        />
      </Box>

      {/* Points */}
      {filteredData.map((point, index) => (
        <QuadrantPoint
          key={point.projectId || index}
          point={point}
          index={index}
          selectedProjectId={selectedProjectId}
          setSelectedProjectId={setSelectedProjectId}
          normalizeCoordinate={normalizeCoordinate}
          getPointOffset={getPointOffset}
          pointBgColor={pointBgColor}
          pointLogoBgColor={pointLogoBgColor}
          selectedPointBgColor={selectedPointBgColor}
          selectedCardBgColor={selectedCardBgColor}
          cardBgColor={cardBgColor}
          cardBorderColor={cardBorderColor}
          handleNavigate={handleNavigate}
          selectedQuadrant={selectedQuadrant}
        />
      ))}

      {/* Axis labels */}
      <Box position="absolute" bottom="-6px" left="25px" right={0} display="flex" justifyContent="left">
        <Stack direction="row" spacing=".5rem" height="100%">
          <Text fontSize="xs" color={axisColor} whiteSpace="nowrap">
            {xAxisLabel}
          </Text>
          <Center>
            <TriangleDownIcon color={axisColor} transform="rotate(-90deg)" boxSize="0.8rem" />
          </Center>
        </Stack>
      </Box>
      <Box position="absolute" bottom="-25px" left="49px" height="100%" display="flex">
        <Stack direction="row" spacing=".5rem" height="100%" transform="rotate(-90deg)">
          <Text fontSize="xs" color={axisColor} whiteSpace="nowrap">
            {yAxisLabel}
          </Text>
          <TriangleDownIcon color={axisColor} transform="rotate(-90deg)" boxSize="0.8rem" mt="3px" />
        </Stack>
      </Box>
    </Box>
  );
});

QuadrantChart.displayName = "QuadrantChart";

export default QuadrantChart;
