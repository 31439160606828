import React, { useContext, useMemo, useEffect, useCallback } from "react";
import { Icon, Tooltip, IconButton, useBreakpointValue } from "@chakra-ui/react";
import { useButtonProps } from "hooks";

import { ContentFilterContext } from "../body/ContentFilterContext";
import { VscClearAll } from "react-icons/vsc";

export const ContentCanvasClearFilterButton = () => {
  const { selectedExtractedContentTypes, searchText, selectedTags, resetFilter, setSearchText } = useContext(ContentFilterContext);
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });
  const secondaryButtonProps = useButtonProps(isMobile ? "xs" : "sm", "secondary");
  const primaryButtonProps = useButtonProps(isMobile ? "xs" : "sm", "primary");

  const isContentFiltered = useMemo(() => {
    return searchText !== "" || selectedExtractedContentTypes.length > 0 || selectedTags.length > 0;
  }, [searchText, selectedExtractedContentTypes, selectedTags]);

  const handleClearFilter = useCallback(() => {
    if (isContentFiltered) {
      resetFilter();
      setSearchText("");
    }
  }, [isContentFiltered, resetFilter, setSearchText]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        handleClearFilter();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleClearFilter]);

  return (
    <Tooltip label={isContentFiltered ? "Clear Filter" : ""}>
      <IconButton
        isDisabled={!isContentFiltered}
        {...(isContentFiltered ? { ...primaryButtonProps } : { ...secondaryButtonProps })}
        className="ch-clear-filter-btn"
        onClick={handleClearFilter}
        aria-label="Clear Filters"
        icon={<Icon as={VscClearAll} fontSize=".9rem" />}></IconButton>
    </Tooltip>
  );
};
