import React, { useEffect, useMemo } from "react";
import { Box, Text, Stack, useColorModeValue, Center, Icon, Tooltip, useColorMode } from "@chakra-ui/react";
import type { FunctionComponent } from "react";
import { ArrowUpIcon, ArrowDownIcon, MinusIcon } from "@chakra-ui/icons";
import { useTileProps } from "hooks";
import { TextOverflowTooltip } from "screens/landing/components/TextOverflowTooltip";
import type { StockTickerData } from "api/stockTickerData";
import { useCollectionsStocksContext } from "screens/landing/tabs/collections/CollectionsStocksProvider";
import { formatDateWithOutputFormat } from "screens/common/modal/formatters";

interface Props {
  companyStockTitle: string;
  companyTicker?: string;
  companyStockExchange?: string;
  companyStockPrice?: string;
  companyStockPercentageChange?: string;
  companyStockMovement?: string;
  companyStockValueChange?: string;
  style?: React.CSSProperties;
  miniTicker?: boolean;
  onClick?: () => void;
}

export const StockIndicator: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  companyStockTitle,
  companyTicker,
  companyStockExchange,
  companyStockPrice,
  companyStockPercentageChange,
  companyStockValueChange,
  companyStockMovement,
  style,
  onClick,
  miniTicker = false,
}) => {
  const { fetchStockData, getStockData } = useCollectionsStocksContext();
  const subTitlecolor = useColorModeValue("charli.mediumGray", "gray.400");
  const commonTileProps = useTileProps();
  const iconBgColor = useColorModeValue("100", "800");
  const iconColor = useColorModeValue("400", "300");
  const { colorMode } = useColorMode();

  const areTickerAndExchangePresent = companyTicker && companyStockExchange;

  const stockData: StockTickerData | undefined = useMemo(() => {
    if (!areTickerAndExchangePresent) return;

    return getStockData(companyTicker, companyStockExchange);
  }, [companyStockExchange, companyTicker, areTickerAndExchangePresent, getStockData]);

  useEffect(() => {
    if (!areTickerAndExchangePresent) return;

    fetchStockData(companyTicker, companyStockExchange);
  }, [companyStockExchange, companyTicker, areTickerAndExchangePresent, fetchStockData]);

  const currentPrice = Number(stockData?.ticker_quote?.price ?? companyStockPrice ?? 0);
  const previousClose = Number(stockData?.ticker_quote?.previous_close ?? 0);
  const difference = stockData?.ticker_quote?.price
    ? (Number(stockData.ticker_quote.price) - previousClose).toFixed(2)
    : companyStockValueChange
    ? Number(companyStockValueChange).toFixed(2)
    : "0.00";
  const percentChange = stockData?.ticker_quote?.percent_change;
  const percentageChange = percentChange
    ? `${Number(percentChange.replace("%", "")).toFixed(2)}%`
    : companyStockPercentageChange
    ? `${Number(companyStockPercentageChange.replace("%", "")).toFixed(2)}%`
    : "--";
  const asOfDate = stockData?.ticker_quote.timestamp
    ? formatDateWithOutputFormat(new Date(stockData?.ticker_quote.timestamp), "do MMM yyyy hh:mm")
    : undefined;

  return (
    <Tooltip label={asOfDate ? `As of ${asOfDate}` : ""} placement="top" aria-label="stock-indicator-tooltip">
      <Stack
        {...commonTileProps}
        style={style}
        bgColor={"transparent"}
        _hover={{}}
        direction="row"
        fontSize="xs"
        width={["10rem", "12.5rem", "12.5rem"]}
        p="5px"
        justifyContent={"space-between"}
        onClick={onClick && onClick}
        height="2.5rem">
        <Box
          width={"2rem"}
          borderRadius="md"
          opacity={colorMode === "dark" ? 0.5 : 1}
          bgColor={
            colorMode === "dark"
              ? "gray.600"
              : percentageChange.includes("--")
              ? `gray.${iconBgColor}`
              : percentageChange.includes("-")
              ? `red.${iconBgColor}`
              : `green.${iconBgColor}`
          }>
          <Center height="100%">
            <Icon
              boxSize={"1rem"}
              as={percentageChange.includes("--") ? MinusIcon : percentageChange.includes("-") ? ArrowDownIcon : ArrowUpIcon}
              color={
                percentageChange.includes("--")
                  ? `gray.${iconColor}`
                  : percentageChange.includes("-")
                  ? `red.${iconColor}`
                  : `green.${iconColor}`
              }
            />
          </Center>
        </Box>
        <Stack direction="row" spacing=".5rem" width={"100%"} justifyContent={"space-between"}>
          <Stack spacing="5px">
            <TextOverflowTooltip
              breakWord={false}
              className="ch-project-stock-indicator-company-name"
              label={companyStockTitle}
              hideTooltip
              fontSize="xs"
              style={{ fontWeight: "bold", lineHeight: "12px" }}
            />
            <Text className="ch-project-stock-indicator-current-price" lineHeight={"12px"} color={subTitlecolor} fontWeight="normal">
              {currentPrice === 0 ? "--" : currentPrice}
            </Text>
          </Stack>
          <Stack justifyContent={"end"} spacing="0">
            <Text
              className="ch-project-stock-indicator-percentage"
              textAlign={"right"}
              fontWeight="semibold"
              color={percentageChange.includes("-") ? `red.${iconColor}` : `green.${iconColor}`}>
              {percentageChange === "NaN%" ? "--" : percentageChange}
            </Text>
            <Text
              className="ch-project-stock-indicator-difference"
              textAlign={"right"}
              lineHeight={"12px"}
              color={difference.includes("-") ? `red.${iconColor}` : `green.${iconColor}`}>
              {difference === "0.00" ? "--" : difference}
            </Text>
          </Stack>
        </Stack>
      </Stack>
    </Tooltip>
  );
};
