import { Box, Center, Image, useColorModeValue } from "@chakra-ui/react";
import type { FunctionComponent, HTMLAttributes } from "react";
import React from "react";
import CharliLogoSmall from "screens/common/static/logos/charli-logo-small.png";
import { useConversationContext } from "./ConversationContext";

interface Props extends HTMLAttributes<HTMLDivElement> {
  onClick?: () => void;
  className?: string;
}

export const CharliLogoCircle: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  onClick,
  className = "ch-quick-actions-command-line-button",
  ...props
}) => {
  const chatButtonBackground = useColorModeValue("chatButton.background", "gray.900");
  const { isConversationOpen, onConversationClose, onConversationOpen } = useConversationContext();
  const boxShadowColor = useColorModeValue("204,204,204", "74,85,104");

  const onHandleConversation = () => {
    if (isConversationOpen) {
      onConversationClose();
    } else {
      onConversationOpen();
    }
  };

  return (
    <Box
      className={className}
      onClick={onClick || onHandleConversation}
      p="0.5rem"
      background={chatButtonBackground}
      _hover={
        !isConversationOpen
          ? {
              boxShadow: `0 0 0 6px rgba(${boxShadowColor}, 0.8)`,
              transition: "box-shadow 0.3s ease-in-out",
            }
          : {}
      }
      cursor="pointer"
      height="3rem"
      width="3rem"
      borderRadius="full"
      boxShadow={`0 0 0 4px rgba(${boxShadowColor}, 1)`}
      transition="box-shadow 0.3s ease-in-out"
      {...props}>
      <Center width="100%" height="100%">
        <Image src={CharliLogoSmall} alt="Charli Logo" />
      </Center>
    </Box>
  );
};
