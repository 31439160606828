import { Box, Stack, Tooltip, Icon, useColorModeValue, Center } from "@chakra-ui/react";
import type { FunctionComponent } from "react";
import React from "react";
import { IoChevronForwardCircle, IoChevronBackCircle } from "react-icons/io5";
import { RiNewsLine } from "react-icons/ri";
import { PiChatsFill } from "react-icons/pi";

interface Props {
  showActivities: boolean;
  toggleActivities: () => void;
  showMetrics: boolean;
  toggleMetrics: () => void;
  setCurrentSlide: (slide: number) => void;
}

export const HomeRightPanelMenu: FunctionComponent<Props> = ({
  showActivities,
  toggleActivities,
  showMetrics,
  toggleMetrics,
  setCurrentSlide,
}) => {
  const bgColor = useColorModeValue("gray.200", "gray.800");

  return (
    <Box bgColor={bgColor} width="3rem" minHeight="50vh">
      <Stack spacing="1rem" pt="1rem">
        <Tooltip label={`${showActivities ? "Hide" : "Show"} Sidebar`} aria-label="Show/Hide Sidebar">
          <Center cursor="pointer">
            <Icon
              className={`ch-home-show-activities-${showActivities ? "hide" : "show"}`}
              aria-label="Show/Hide Sidebar"
              as={showActivities ? IoChevronForwardCircle : IoChevronBackCircle}
              boxSize={"1.4rem"}
              color={"primary.default"}
              onClick={() => {
                toggleActivities();
              }}
            />
          </Center>
        </Tooltip>
        <Tooltip label={`Show News`} aria-label="Show/Hide Metrics">
          <Center marginInline={"0!important"} cursor="pointer" onClick={() => setCurrentSlide(0)}>
            <Icon
              className={`ch-home-show-news`}
              aria-label="News"
              as={RiNewsLine}
              boxSize={"1.4rem"}
              color={"primary.default"}
              onClick={() => {
                setCurrentSlide(0);
              }}
            />
          </Center>
        </Tooltip>
        <Tooltip label={`Show Activities`} aria-label="Activities">
          <Center marginInline={"0!important"} cursor="pointer" onClick={() => setCurrentSlide(1)}>
            <Icon
              className={`ch-home-show-activities`}
              aria-label="Show/Hide Metrics"
              as={PiChatsFill}
              boxSize={"1.4rem"}
              color={"primary.default"}
              onClick={() => {
                setCurrentSlide(1);
              }}
            />
          </Center>
        </Tooltip>
      </Stack>
    </Box>
  );
};
